import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from "react-redux";
import { getPaginationAction } from '../../redux/action/paginationAction';

function PaginateCustom(props) {

    // category and sub category pagination
    let newParam = props?.pageInfo ? props?.pageInfo : {};
    // console.log(" newParam TEST",newParam);
    if (newParam !== null || newParam !== undefined) {
        delete newParam.offset
        delete newParam.limit

    }
    if (props?.paginateInfo?.params !== undefined || props?.paginateInfo?.params !== null) {
        newParam = { ...newParam, ...props?.paginateInfo?.params }
    }
    // category and sub category pagination


    const dispatch = useDispatch();

    const [getNewOffset, setNewOffset] = useState('');

    const { paginationGetData } = useSelector(state => state.getPaginationReducer);

    const initialDispatch = props.initialDispatch === false ? false : true;

    useEffect(() => {
        if (initialDispatch) {
            dispatch(getPaginationAction(props.paginateInfo.path, props.paginateInfo.limit, props.paginateInfo.offset, props.paginateInfo.params));
        }
    }, [dispatch]);

    useEffect(() => {
        props.onPaginateData(paginationGetData, getNewOffset);
    }, [paginationGetData]);

    const handlePageClick = (data) => {
        const paginateNewOffset = props.paginateInfo.limit * data.selected ?? data.selected + 1;
        setNewOffset(paginateNewOffset)
        const getMoreData = (paginateNewOffset) => {
            dispatch(getPaginationAction(props.paginateInfo.path, props.paginateInfo.limit, paginateNewOffset, newParam && newParam));
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
        getMoreData(paginateNewOffset)
    };

    // useEffect(() => {
    //     props.onCountChange(paginationGetData?.count);
    // }, [paginationGetData?.count]);

    return (
        <>
            {paginationGetData && paginationGetData?.count > props.paginateInfo.limit ?
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >>"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={paginationGetData?.total_number_of_pages}
                    previousLabel="<< Previous"
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                : ''}
        </>
    );
}

export default PaginateCustom;