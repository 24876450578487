import axios from "axios";
import { useEffect, useState } from "react";
import { isInvalid } from "../Utilities/Utilities";
import Skeleton from "react-loading-skeleton";

export const BannerAdCategoryList = (props) => {
  const [adData, setAdData] = useState({
    src: "",
    link: "",
    loading: true,
  });

  const categoryName = props.categoryName;

  useEffect(() => {
    fetchBannerAds();

    // Set a separate interval for fetching new ads
    const interval = setInterval(fetchBannerAds, 100);

    imageChange();

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    // Fetch banner ads and categories only when the component mounts
    fetchBannerAds();

    // Set a separate interval for fetching new ads
    const fetchInterval = setInterval(fetchBannerAds, 5000);

    // Set an interval for changing the image
    const imageChangeInterval = setInterval(imageChange, 5000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(fetchInterval);
      clearInterval(imageChangeInterval);
    };
  }, []);

  const fetchBannerAds = () => {
    const bannerAdsLocal = localStorage.getItem(categoryName);
    const loading = localStorage.getItem("banner_ad_loading");

    if (
      isInvalid(bannerAdsLocal) &&
      (loading === null || loading === undefined || !JSON.parse(loading))
    ) {
      load();
    }
  };

  const load = () => {
    localStorage.setItem("banner_ad_loading", true);
    const requests = [
      axios.get("adbanner/?limit=100"),
      axios.get("adbanner/category/"),
    ];

    Promise.all(requests)
      .then((responses) => {
        const allBannerAds = responses[0].data.results;
        const allBannerAdCategories = responses[1].data;

        if (!categoryName || !allBannerAdCategories || !allBannerAds) {
          return;
        }

        const categoryDataMap = {
          "ca4bcf49-900f-41e1-a9f7-f222b67a5483": "Top",
          "a316f529-00cc-4c5e-8aa4-aa8c676e2c93": "Left",
          "7ea3fa68-0420-4e77-975f-d7e9489752af": "Right",
          "65d67469-3086-49f4-8403-59129a6f0d03": "Bottom",
        };

        const filteredAds = allBannerAds.filter((ad) =>
          categoryDataMap.hasOwnProperty(ad.category)
        );

        filteredAds.forEach((ad) => {
          const storageKey = categoryDataMap[ad.category];
          let existingData = localStorage.getItem(storageKey);
          let existingArray = JSON.parse(existingData) || [];

          existingArray.push(ad);
          localStorage.setItem(storageKey, JSON.stringify(existingArray));
        });

        // After fetching ads, trigger the image change logic
        imageChange();
      })
      .catch((error) => {
        console.error("Error loading banner ads:", error);
      })
      .finally(() => localStorage.setItem("banner_ad_loading", false));
  };

  const imageChange = () => {
    setAdData((prevAdData) => ({ ...prevAdData, loading: true }));

    const bannerAdsLocalArray = JSON.parse(localStorage.getItem(categoryName));

    if (!bannerAdsLocalArray || bannerAdsLocalArray.length === 0) {
      // If no local ads, fetch them (optional)
      fetchBannerAds();
      return;
    }

    const random = Math.floor(Math.random() * bannerAdsLocalArray.length);
    const image = bannerAdsLocalArray[random]?.image;
    const link = bannerAdsLocalArray[random]?.link;

    setAdData({ src: image, link, loading: false });
    bannerAdsLocalArray.splice(random, 1);

    if (bannerAdsLocalArray.length > 0) {
      localStorage.setItem(categoryName, JSON.stringify(bannerAdsLocalArray));
    } else {
      localStorage.removeItem(categoryName);

      fetchBannerAds();
    }
  };

  return (
    <>
      {adData.loading ? (
        <Skeleton height={250} count={1} containerClassName={"loadingSkeletonWith"} />
      ) : (
        <a href={adData.link} target="_blank" rel="noopener noreferrer">
          <img
            src={adData.src}
            key={adData.src}
            alt=""
            width={props?.widthSize}
            height={props?.heightSize}
            loading="lazy"
          />
        </a>
      )}
    </>
  );
};
