import React, { useEffect, useState } from 'react';
import './shaky-button.css';
import axios from 'axios';

function AddPromotion({ ad_id, shareDataCallback }) {
  const [checkValue, setCheckValue] = useState(false);
  const [resData, setData] = useState([]);

  const getPromotionCategoryRequest = async () => {
    try {
      const response = (await axios.get('pricing/promotion-package')).data;
      setData(response?.promotion_packages);
      return response;
    } catch (error) {
      console.log("API ERROR", error);
    }
  };

  const handleCheckboxChange = (item) => {
    if (item != null) {
      const data = { id: item.id, price: item.price, title: item.title };
      const selectedPromotion = data;
      setCheckValue(selectedPromotion.id);
      shareDataCallback(selectedPromotion);
    } else {
      shareDataCallback(null);
    }
  };

  const removePromotion = () => {
    handleCheckboxChange(null);
    setCheckValue(false);
  };

  useEffect(() => {
    getPromotionCategoryRequest();
  }, []);

  return (
    <>
      {resData.length > 0 && (
        <div className='promotion-css'>
          <p style={{ borderLeft: '3px solid #f58b8b', paddingLeft: '5px' }}>Make your ad stand out!</p>
          <span>Get up to 20 times more responsive by applying Ad Promotion.</span>
          <span>Select one of them</span>

          {resData &&
            resData.map((item) => (
              <div key={item.id} className='promotion-item'>
                <div className='form-check'>
                  <input
                    id='checkbox_style'
                    checked={checkValue === item.id}
                    onChange={() => handleCheckboxChange(item)}
                    type='checkbox'
                    name={item.id}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {/* {item.id} */}
                  <img width='50px' height='30px' style={{ marginRight: "2px" }} src={item?.logo} alt={item.id} />
                  <p style={{ fontSize: "12px" }}>{item?.promotion_type?.replaceAll('_', ' ')?.toUpperCase()}</p>
                </div>
                <div>
                  <p style={{ lineHeight: "1" }}>{item?.price} TK</p>
                  <p style={{ color: "#eb5e5ede", fontSize: "10px" }}>Validity: {item?.validity} days</p>
                </div>
              </div>
            ))}
          <div className='button_div'>
            <button style={{ background: "red" }} className='round__' onClick={removePromotion}>
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default AddPromotion;
