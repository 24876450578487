import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import facebook from "../../asset/frontend/assets/images/social_media_icon/facebook.png";
import instagram from "../../asset/frontend/assets/images/social_media_icon/instagram.png";
import linkedin from "../../asset/frontend/assets/images/social_media_icon/linkedin.png";
import pinterest from "../../asset/frontend/assets/images/social_media_icon/pinterest.png";
import twiter from "../../asset/frontend/assets/images/social_media_icon/twiter.png";
import whatsapp from "../../asset/frontend/assets/images/social_media_icon/whatsapp.png";
import youtube from "../../asset/frontend/assets/images/social_media_icon/youtube.png";
import sslBanner from "../../asset/frontend/assets/images/ssl-banner2.jpg";
import appStore from "../../asset/frontend/assets/images/app-store1.png";
import appStore2 from "../../asset/frontend/assets/images/app-store2.png";
// import basis from "../../asset/frontend/assets/images/StaticImage/BASIS.png";
import basis from "../../asset/frontend/assets/images/StaticImage/basis.svg";
import bkash from "../../asset/frontend/assets/images/StaticImage/bkash_accept.png";
import nagad from "../../asset/frontend/assets/images/StaticImage/nagad_accept.png";
import dbid from "../../asset/frontend/assets/images/logo/logo-bid.png";
import { allLocalStorageRemove, languageCheck } from "../../helpers/Helpers";
import moment from "moment";

// import eCabLogo from '';

const FooterJobForEm = ({ title, description }) => {
  const [rotate, setRotate] = useState(false);
  const sideBoxManage = (e) => {
    setRotate(!rotate);
  };
  const [showMore, setShowMore] = useState(false);
  const [substring, setSubString] = useState("");
  const token = localStorage.getItem("token");

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    allLocalStorageRemove();
  }, []);

  const getSubString = () => {
    const substring = description.substring(0, 300); // Extract first 300 characters
    const lastDotIndex = substring.lastIndexOf("."); // Find the index of the last dot
    const result = substring.substring(0, lastDotIndex + 1); // Extract substring till the last dot (including dot)
    return result ?? "";
  };

  return (
    <div
      style={{
        padding: "30px",
        background: "#d9d9d9",
        minHeight: "100px",
      }}
    >
      <div
        style={{
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <h2 style={{ textAlign: "center" }}>
              Find the Perfect Job Opportunities | PublicMarket.com.bd
            </h2>
          </div>
          {!showMore && (
            <p>
              <span
                style={{
                  fontSize: "11pt",
                }}
              >
                Are you tired of feeling stuck in a dead-end job? Do you dream of a career that ignites your passion and fuels your ambition? Look no further than Public Market's extensive job listings. Our platform is a treasure trove of opportunities, connecting you with top employers and exciting roles that will take your career to the next level.
              </span>
            </p>
          )}
          {showMore && (
            <>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  Are you tired of feeling stuck in a dead-end job? Do you dream of a career that ignites your passion and fuels your ambition? Look no further than Public Market's extensive job listings. Our platform is a treasure trove of opportunities, connecting you with top employers and exciting roles that will take your career to the next level.
                </span>
              </p>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  From entry-level positions to executive roles, our job listings cater to a diverse range of skills and experience levels. Whether you're a fresh graduate looking to kickstart your career or a seasoned professional seeking a new challenge, we've got you covered.                 </span>
              </p>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  Our user-friendly interface makes it easy to browse through hundreds of job openings, filter by location, industry, and job type, and apply for your dream job with just a few clicks. Our commitment to providing a personalized job search experience sets us apart. Our team of experts curates job listings to ensure they are relevant, up-to-date, and tailored to your needs.
                </span>
              </p>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  We believe that everyone deserves a career that brings them joy, fulfillment, and financial stability. So why wait? Explore our job listings today and take the first step towards a brighter, more prosperous future.                </span>
              </p>
            </>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <button
              onClick={toggleShowMore}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {showMore &&
                (languageCheck() === "bn" ? "কম দেখান" : "Show less")}
              {!showMore && (
                <>{languageCheck() === "bn" ? "আরও দেখান" : "Show More"}</>
              )}
              <span
                style={{
                  marginLeft: "4px",
                  marginTop: "4px",
                }}
              >
                {showMore ? (
                  <i className="fal fa-angle-up footericon fa-2x"></i>
                ) : (
                  <i className="fal fa-angle-down footericon fa-2x"></i>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterJobForEm;
