import Key from "./key";

const bn = {};

bn[Key.Keep_it_short] = "সংক্ষিপ্ত রাখুন!";
bn[Key.link] = "লিঙ্ক";
bn[Key.Fill_in_the_details] = "বিস্তারিত তথ্য দিন";
bn[Key.About_the_TVC] = "টিভিসি সম্পর্কে";
bn[Key.Ad_Title] = "বিজ্ঞাপন শিরোনাম";
bn[Key.Ad_Title_Example] = "উদাহরণঃ  ঢাকা তে ড্রাইভার এর চাকরি";
bn[Key.Company_Name] = "কোম্পানি নাম";
bn[Key.Description] = "বিবরণ";
bn[Key.Description_Job] = "চাকরি বিবরণ";
bn[Key.Describe_the_TVC_in_detail] = "টিভিসি বিজ্ঞাপন বর্ণনা করুন";
bn[Key.Video] = "ভিডিও";
bn[Key.Submit] = "জমা দিন";
bn[Key.Edit] = "এডিট করুন";
bn[Key.You_must_fill_out_this_field] =
  "আপনাকে অবশ্যই এই ফিল্ডটি  পূরণ করতে হবে";
bn[Key.The_information_entered_is_too_short] = "প্রবেশ করা তথ্য খুব ছোট";
bn[Key.The_information_entered_is_too_long] = "প্রবেশ করা তথ্য খুব দীর্ঘ";
bn[Key.All_Categories] = "সব ক্যাটাগরি";
bn[Key.work_experience_long] = "50-এর বেশি নয়";
bn[Key.work_experience_Short] = "অবশ্যই কমপক্ষে 0 টি হতে হবে";
bn[Key.Must_Be_Use_Number] = "শুধুমাত্র সংখ্যা ব্যবহার করুন";

// auth leng
bn[Key.old_password_leng] = "বর্তমান পাসওয়ার্ড";
bn[Key.confirm_password_leng] = "নিশ্চিত করুন নতুন পাসওয়ার্ড";
bn[Key.new_password_leng] = "নতুন পাসওয়ার্ড";

// bn["Living Room Furniture Type"] = "bangla"

// all sub category
bn["Mobile"] = "মোবাইল";
bn["Mobile Phones"] = "মোবাইল ফোন";
bn["Brand"] = "ব্র্যান্ড";
bn["Select your brand name"] = "আপনার ব্র্যান্ডের নাম নির্বাচন করুন";
bn["Apple"] = "অ্যাপল";
bn["Samsung"] = "স্যামসাং";
bn["Nokia"] = "নকিয়া";
bn["Sony"] = "সনি";
bn["OPPO"] = "অপ্পো";
bn["Huawei"] = "হুয়াওয়ে";
bn["Xiaomi"] = "শাওমি";
bn["Vivo"] = "ভিভো";
bn["Lenovo"] = "লেনোভো";
bn["Motorola"] = "মটোরোলা";
bn["OnePlus"] = "ওয়ানপ্লাস";
bn["Asus"] = "আসুস";
bn["Alcatel"] = "অ্যালকাটেল";
bn["Realme"] = "রিয়েলমি";
bn["Honor"] = "অনার";
bn["Infinix"] = "ইনফিনিক্স";
bn["HTC"] = "এইচটিসি";
bn["Gione"] = "জিওয়ান";
bn["BlackBerry"] = "ব্লাকবেরি";
bn["Coolpad"] = "কুলপ্যাড";
bn["Meizu"] = "মেইজু";
bn["ZTE"] = "জেডটিই";
bn["LG"] = "এলজি";
bn["Micromax Informatics"] = "মাইক্রোম্যাক্স ইনফরমেটিক্স";
bn["Walton"] = "ওয়ালটন";
bn["Others"] = "অন্যান্য";
bn["Item Type"] = "আইটেমের ধরণ";
bn["Configuration / Layout"] = "কনফিগারেশন / বিন্যাস";
bn["Mobile Description"] = "মোবাইলের বিবরণ";
bn["Authenticity"] = "বিশ্বাসযোগ্যতা";
bn["Original"] = "আসল";
bn["Refurbished"] = "সংস্কার করা";
bn["Mobile Phone Accessories"] = "মোবাইল ফোন এক্সেসরিজ";
bn["Mobile Phone Accessories Type"] = "মোবাইল ফোন এক্সেসরিজের ধরণ";
bn["Select your accessories item type"] =
  "আপনার এক্সেসরিজ পণ্যের ধরণ নির্বাচন করুন";
bn["Screen Protector"] = "স্ক্রিন প্রটেক্টর";
bn["Headphone"] = "হেডফোন";
bn["Selfie Stick"] = "সেলফি স্টিক";
bn["Power Bank"] = "পাওয়ার ব্যাংক";
bn["PopSockets"] = "পপ সকেট";
bn["OtterBox"] = "অটারবক্স";
bn["Wireless Chargers"] = "ওয়্যারলেস চার্জার";
bn["Handset"] = "হ্যান্ডসেট";
bn["Chargers"] = "চার্জার";
bn["Stylus Pen"] = "স্ট্রাইলাস পেন";
bn["Cables"] = "ক্যাবল";
bn["Bluetooth Earbuds"] = "ব্লুটুথ ইয়ারবাড";
bn["Holders & Stands"] = "হোল্ডার এবং স্ট্যান্ড";
bn["Car Chargers"] = "কার চার্জার";
bn["Bags & Cases"] = "ব্যাগ এবং কেস";
bn["USB On-The-Go"] = "ইউএসবি অন-দ্যা-গো";
bn["VRBoxes"] = "ভিআর বক্স";
bn["Mobile Screen Enlarger"] = "মোবাইল স্ক্রীন ইনলার্জার";
bn["iPhone Wallet Case"] = "আইফোন ওয়ালেট কেস";
bn["Samsung Wallet Case"] = "স্যামসাং ওয়ালেট কেস";
bn["Accessories Description"] = "এক্সেসরিজের বিবরণ";
bn["SIM Cards"] = "সিম কার্ড";
bn["SIM Type"] = "সিমের ধরণ";
bn["Pre-paid"] = "প্রি-পেইড";
bn["Post-paid"] = "পোষ্ট-পেইড";
bn["Bandwidth"] = "ব্যান্ডউইথ";
bn["2G"] = "২জি";
bn["3G"] = "৩জি";
bn["4G"] = "৪জি";
bn["5G"] = "৫জি";
bn["Brand / Operator"] = "ব্র্যান্ড / অপারেটরঃ";
bn["Grameenphone"] = "গ্রামীণফোন";
bn["Airtel"] = "এয়ারটেল";
bn["Banglalink"] = "বাংলালিংক";
bn["Robi"] = "রবি";
bn["Teletalk"] = "টেলিটক";
bn["SIM Card Description"] = "সিম কার্ডের বিবরণ";
bn["Mobile Phone Services"] = "মোবাইল ফোন সার্ভিস";
bn["Service Type"] = "সার্ভিসের ধরণ";
bn["Service Description"] = "সার্ভিসের বিবরণ";
bn["Mobile Internet"] = "মোবাইল ইন্টারনেট";
bn["Internet Type"] = "ইন্টারনেটের ধরণ";
bn["Internet Description"] = "ইন্টারনেটের বিবরণ";
bn["Wearable Technology"] = "ওয়ারেবল প্রযুক্তি";
bn["Wearable Technology Type"] = "ওয়ারেবল প্রযুক্তির ধরণ";
bn["Smart Watches"] = "স্মার্ট ঘড়ি";
bn["Fitness Bands"] = "ফিটনেস ব্যান্ড";
bn["Product Description"] = "পণ্যের বিবরণ";
bn["Desktop Computers"] = "ডেস্কটপ কম্পিউটার";
bn["Brand Name"] = "ব্র্যান্ডের নাম";
bn["Acer"] = "এসার";
bn["Dell"] = "ডেল";
bn["Lenovo"] = "লেনোভো";
bn["Alienware"] = "এলিয়েনওয়্যার";
bn["Intel"] = "ইন্টেল";
bn["IBM"] = "আইবিএম";
bn["HP Desktop"] = "এইচপি ডেস্কটপ";
bn["Cyber PowerPC"] = "সাইবার পাওয়ার পিসি";
bn["Gateway 2000"] = "গেটওয়ে ২০০০";
bn["Dell Inspiron"] = "ডেল ইন্সপিরন";
bn["Dell Vostro"] = "ডেল ভোস্ট্রো";
bn["Configuration/ Layout"] = "কনফিগারেশন / বিন্যাস";
bn["Laptops"] = "ল্যাপটপ";
bn["Microsoft"] = "মাইক্রোসফট";
bn["MSI"] = "এমএসআই";
bn["Razer"] = "রেজার";
bn["Toshiba"] = "তোশিবা";
bn["Samsung Group"] = "স্যামসাং গ্রুপ";
bn["Hewlett –Packard"] = "হিউলেট প্যাকার্ড";
bn["ThinkPad"] = "থিঙ্কপ্যাড";
bn["Huawei"] = "হুয়াওয়ে";
bn["MacBook"] = "ম্যাকবুক";
bn["Apple MacBook Pro"] = "অ্যাপল ম্যাকবুক প্রো";
bn["VAIO"] = "ভাইও";
bn["Fujitsu"] = "ফুজিতসু";
bn["GIGABYTE"] = "গিগাবাইট";
bn["Apple MacBook Air"] = "অ্যাপল ম্যাকবুক এয়ার";
bn["Panasonic"] = "প্যানাসনিক";
bn["Desktop Description"] = "ডেস্কটপরের বিবরণ";
bn["Laptop & Computer Accessories"] = "ল্যাপটপ এবং কম্পিউটার এক্সেসরিজ";
bn["Laptop & computer accessories type"] =
  "ল্যাপটপ এবং কম্পিউটার এক্সেসরিজের ধরণ";
bn["Select your laptop & computer accessories type"] =
  "আপনার ল্যাপটপ এবং কম্পিউটার এক্সেসরিজের ধরণ সিলেক্ট করুন";
bn["Graphics Card"] = "গ্রাফিক্স কার্ড";
bn["Motherboard"] = "মাদারবোর্ড";
bn["Hard Drive"] = "হার্ড ড্রাইভ";
bn["SSD Card"] = "এসএসডি কার্ড";
bn["Pen Drive"] = "পেন ড্রাইভ";
bn["Software"] = "সফটওয়্যার";
bn["Mouse"] = "মাউস";
bn["Processor"] = "প্রসেসর";
bn["Power Supply"] = "পাওয়ার সাপ্লাই";
bn["RAM"] = "র‍্যাম";
bn["IPS"] = "আইপিএস";
bn["UPS"] = "ইউপিএস";
bn["Laptop Battery"] = "ল্যাপটপের ব্যাটারি";
bn["Modem"] = "মডেম";
bn["Router"] = "রাউটার";
bn["Printer"] = "প্রিন্টার";
bn["Scanner"] = "স্ক্যানার";
bn["Monitor"] = "মনিটর";
bn["Tablet Battery"] = "ট্যাবলেট ব্যাটারি";
bn["Description"] = "বিবরণ";
bn["TVs"] = "টেলিভিশন";
bn["TCL"] = "টিসিএল";
bn["Hisense"] = "হিসেন্স";
bn["VIZIO"] = "ভিজিও";
bn["Philips"] = "ফিলিপস";
bn["Haier"] = "হায়ার";
bn["Sharp Corporation"] = "শার্প কর্পোরেশন";
bn["Onida Electronics"] = "অনিডা ইলেকট্রনিক্স";
bn["OnePlus"] = "ওয়ানপ্লাস";
bn["Huawei"] = "হুয়াওয়ে";
bn["Sanyo"] = "সানিও";
bn["LG Electronics"] = "এলজি ইলেকট্রনিক্স";
bn["THOMSON"] = "থমসন";
bn["JVC"] = "জেভিসি";
bn["RCA"] = "আরসিএ";
bn["Insignia"] = "ইনসিগনিয়া";
bn["Sansui"] = "সানসুই";
bn["Intex"] = "ইন্টেক্স";
bn["Kodak"] = "কোডাক";
bn["TV Description"] = "টিভির বিবরণ";
bn["Audio & Sound Systems"] = "অডিও এবং সাউন্ড সিস্টেম";
bn["JBL"] = "জে বি এল";
bn["Bose"] = "বোস";
bn["KEF"] = "কে ই এফ";
bn["Dynaudio"] = "ডাইনাউডিও";
bn["Bowers & Wilkins"] = "বোয়ার্স এবং উইলকিন্স";
bn["Harman Kardon"] = "হারমান কার্ডন";
bn["Definitive Technology"] = "নির্দিষ্ট প্রযুক্তি";
bn["Klipsch"] = "ক্লিপসছ";
bn["Cerwin –Vega"] = "সারউইন-ভেগা";
bn["Altec Lansing"] = "আলটেক ল্যান্সিং";
bn["Arcam"] = "আরকাম";
bn["Denon"] = "ডেনন";
bn["Audio – Technica"] = "অডিও – টেকনিকা";
bn["Alesis"] = "অ্যালেসিস";
bn["Tannoy"] = "তনয়";
bn["Acoustic Research"] = "শাব্দ গবেষণা";
bn["D&D Audiotechnic"] = "ডি অ্যান্ড ডি অডিওটেকনিক";
bn["AMX"] = "এ এম এক্স";
bn["ADAM Audio"] = "অ্যাডাম অডিও";
bn["Accurate Audio"] = "সঠিক অডিও";
bn["Sweet Audio"] = "মিষ্টি অডিও";
bn["Micro Audio"] = "মাইক্রো অডিও";
bn["Banging Audio"] = "ব্যাঙ্গিং অডিও";
bn["Sound Sense"] = "সাউন্ড সেন্স";
bn["Cameras, Camcorders & Accessories"] =
  "ক্যামেরা, ক্যামকর্ডার এবং আনুষাঙ্গিক";
bn["Canon"] = "ক্যানন";
bn["Nikon"] = "নিকন";
bn["Fujifilm"] = "ফুজিফিল্ম";
bn["Olympus"] = "অলিম্পাস";
bn["Leica"] = "লেইকা";
bn["Pentax"] = "পেন্টাক্স";
bn["Hasselblad"] = "হ্যাসেলব্লাড";
bn["Kodak"] = "কোডাক";
bn["JVC"] = "জেভিসি";
bn["Polaroid Corporation"] = "পোলারয়েড কর্পোরেশন";
bn["Canon EOS R5"] = "ক্যানন ইওএস আর৫";
bn["Canon XA50"] = "ক্যানন এক্সএ ৫০";
bn["Canon XA30"] = "ক্যানন এক্সএ ৩০";
bn["Vivitar"] = "ভিভিটার";
bn["Canon XC10"] = "ক্যানন এক্সএ ১০";
bn["Canon Camcorder"] = "ক্যানন ক্যামকর্ডার";
bn["Blackmagic Design"] = "ব্ল্যাকম্যাজিক ডিজাইন";
bn["Rollei"] = "রোলেই";
bn["Canon EOS R3"] = "ক্যানন ইওএস আর৩";
bn["Canon VIXIA"] = "ক্যানন ভিক্সিয়া";
bn["Sony Handycam"] = "সনি হ্যান্ডিক্যাম";
bn["TV & Video Accessories"] = "টিভি এবং ভিডিও এক্সেসরিজ";
bn["TV Boxes & Card"] = "টিভি বক্সেস এবং কার্ড";
bn["Projector"] = "প্রজেক্টর";
bn["Video Player"] = "ভিডিও প্লেয়ার";
bn["Accessories Description"] = "এক্সেসরিজের বিবরণ";
bn["Tablets & Accessories"] = "ট্যাবলেট ও এক্সেওসরিজ";
bn["Tablets"] = "ট্যাবলেট";
bn["Accessories"] = "এক্সেসরিজ";
bn["Huawei"] = "হুয়াওয়ে";
bn["Microsoft"] = "মাইক্রোসফট";
bn["Karbonn"] = "কার্বন";
bn["Teclast"] = "টেক্লাস্ট";
bn["Video Game Consoles & Accessories"] = "ভিডিও গেম কনসোল ও এক্সেসরিজ";
bn["Device Type"] = "ডিভাইসের ধরণ";
bn["Virtual Video Games"] = "ভার্চুয়াল ভিডিও গেম";
bn["Virtual Video Game Consoles"] = "ভার্চুয়াল ভিডিও গেম কনসোল";
bn["Virtual Video Games Accessories"] = "ভার্চুয়াল ভিডিও গেম এক্সেসরিজ";
bn["Photocopiers"] = "ফটোকপিয়ার";
bn["Ricoh"] = "রিকোহ";
bn["Sharp Corporation"] = "শার্প কর্পোরেশন";
bn["Konica Minolta"] = "কোনিকা মিনোল্টা";
bn["Kyocera"] = "কিয়োসেরা";
bn["Lexmark"] = "লেক্সমার্ক";
bn["Epson"] = "এপসন";
bn["Olivetti"] = "অলিভেটি";
bn["Xerox 914"] = "জেরক্স ৯১৪";
bn["Photocopiers Description"] = "ফটোকপিয়ারের বিবরণ";
bn["Generator"] = "জেনারেটর";
bn["Anadea"] = "আনাদেয়া";
bn["Atlas Copco"] = "এটলাস কপকো";
bn["Cummins"] = "কামিন্স";
bn["Generac"] = "জেনারাক";
bn["Champion"] = "চ্যাম্পিয়ন";
bn["DuroMax"] = "ডুরোম্যাক্স";
bn["Briggs & Stratton"] = "ব্রিগস ও স্ট্র্যাটন";
bn["Generator Description"] = "জেনারেটরের বিবরণ";
bn["IPS & Battery"] = "আইপিএস এবং ব্যাটারি";
bn["Select your item type"] = "আপনার আইটেমের ধরণ নির্বাচন করুন";
bn["IPS"] = "আইপিএস";
bn["Battery"] = "ব্যাটারি";
bn["Grameen IPS"] = "গ্রামীণ আইপিএস";
bn["Smarten IPS -500W"] = "স্মার্টেন আইপিএস -৫০০ওয়াট";
bn["Luminous IPS"] = "উজ্জ্বল  আইপিএস";
bn["Janani IPS"] = "জননী আইপিএস";
bn["Duracell"] = "ডুরসেল";
bn["Energizer"] = "এনার্জীয়ার";
bn["Acdelco"] = "একডেলকু";
bn["Rayovac"] = "রায়োভ্যাক";
bn["Eveready Battery Company"] = "এভারেডি ব্যাটারি কোম্পানি";
bn["Byd"] = "বাইড";
bn["Sanyo"] = "সানিও";
bn["Batteries Plus"] = "ব্যাটারি প্লাস";
bn["City Battery"] = "সিটি ব্যাটারি";
bn["Revolution Batteries"] = "রিভলুশন ব্যাটারি";
bn["Batteries 123"] = "ব্যাটারি ১২৩";
bn["Batteries Unlimited"] = "ব্যাটারি আনলিমিটেড";
bn["National Battery"] = "ন্যাশনাল ব্যাটারি";
bn["Powermetz Battery"] = "পাওয়ারমেটজ ব্যাটারি";
bn["Propex Battery"] = "প্রোপেক্স ব্যাটারি";
bn["Smithex"] = "স্মিথেক্স";
bn["Zeddex Battery"] = "জেডডেক্স ব্যাটারি";
bn["Ultralife Batteries, Inc"] = "আল্ট্রালাইফ ব্যাটারি, ইনক";
bn["Nippo Batteries"] = "নিপ্পো ব্যাটারি";
bn["Lighting"] = "লাইটিং";
bn["Security & Access Control"] = "নিরাপত্তা এবং অ্যাক্সেস নিয়ন্ত্রণ";
bn["Vivint"] = "ভিভিন্ট";
bn["Frontpoint"] = "ফ্রন্টপয়েন্ট";
bn["SimpliSafe"] = "সিম্পলিসেফ";
bn["Cove"] = "কভ";
bn["ADT"] = "এডিটি";
bn["Abode"] = "অ্যাবোডি";
bn["Ring Alarm"] = "রিং অ্যালার্ম";
bn["Blue by ADT"] = "ব্লু বাই এডিটি";
bn["Wyze"] = "ওয়াইজ";
bn["Brinks"] = "ব্রিংস";
bn["Ring"] = "রিং";
bn["Model Name"] = "মডেলের নাম";
bn["Printer"] = "প্রিন্টার";
bn["Scanner"] = "স্ক্যানার";
bn["Brother"] = "ব্রাদার";
bn["Epson"] = "এপসন";
bn["Lexmark"] = "লেক্সমার্ক";
bn["Xerox"] = "জেরক্স";
bn["Ricoh"] = "রিকোহ";
bn["Konica Minolta"] = "কোনিকা মিনোল্টা";
bn["Eastman Kodak"] = "ইস্টম্যান কোডাক";
bn["Brother Industries"] = "ব্রাদার ইন্ডাস্ট্রিজ";
bn["Dell"] = "ডেল";
bn["HP Printer"] = "এইচপি প্রিন্টার";
bn["HP Printers"] = "এইচপি প্রিন্টার্স";
bn["Honey Well"] = "হানিওয়েল";
bn["Zebra"] = "জেব্রা";
bn["Visioneer"] = "ভিশনীয়ার";
bn["Plustek"] = "প্লাসটেক";
bn["Tao Tronics"] = "টাওট্রনিক্স";
bn["iCODIS"] = "আইকোডিস";
bn["Panasonic Corporation"] = "প্যানাসনিক কর্পোরেশন";
bn["UMAX"] = "ইউম্যাক্স";
bn["Canon Inc"] = "ক্যানন ইনক";
bn["Keyscan"] = "কীস্ক্যান";
bn["Microtek"] = "মাইক্রোটেক";
bn["Other Electronics"] = "অন্যান্য ইলেকট্রনিক্স";
bn["ACs & Home Electronics"] = "এসি এবং হোম ইলেকট্রনিক্স";
bn["AC"] = "এসি";
bn["Solar System"] = "সোলার সিস্টেম";
bn["Cooler"] = "কুলার";
bn["Lighting"] = "লাইটিং";
bn["Fan"] = "ফ্যান";
bn["Air Cooler"] = "এয়ার কুলার";
bn["Freeze"] = "ফ্রিজ";
bn["Micro Oven"] = "মাইক্রো ওভেন";
bn["Toaster"] = "টোস্টার";
bn["Rice Cooker"] = "রাইস কুকার";
bn["Heater"] = "হিটার";
bn["IPS"] = "আইপিএস";
bn["Daikin"] = "ডাইকিন";
bn["Voltas"] = "ভুল্টাস";
bn["Carrier Global"] = "ক্যারিয়ার গ্লোবাল";
bn["Hitachi"] = "হিটাচি";
bn["Blue Star"] = "ব্লু স্টার";
bn["Whirlpool"] = "রোয়েলপুল";
bn["Haier"] = "হায়ার";
bn["O General"] = "ও  জেনারেল";
bn["Lloyd"] = "লয়েড";
bn["Godrej"] = "গোদরেজ";
bn["Trane"] = "ট্রেন";
bn["Videocon"] = "ভিডিওকন";
bn["Midea Group"] = "মিডিয়া গ্রুপ";
bn["Rheem"] = "রিম";
bn["Mitsubishi Electric"] = "মিতসুবিশি ইলেকট্রিক";
bn["Gree Electric"] = "গ্রী ইলেকট্রিক";
bn["Goodman"] = "গুড ম্যান";
bn["Heil"] = "হেইল";
bn["Mitashi"] = "মিতাশি";
bn["Kelvinator"] = "কেলভিনেটর";
bn["Bosch"] = "বোশ";
bn["Heil Air Conditioners"] = "হেইল এয়ার কন্ডিশনার";
bn["Havells"] = "হ্যাভেলস";
bn["Usha"] = "উষা";
bn["Orient Electric"] = "ওরিয়েন্ট ইলেকট্রিক";
bn["Bajaj"] = "বাজাজ";
bn["Khaitan"] = "খৈতান";
bn["Atomberg"] = "অ্যাটমবার্গ";
bn["Craftmade"] = "ক্র্যাফট মেইড";
bn["SUPERFAN"] = "সুপারফ্যান";
bn["Activa"] = "অ্যাক্টিভা";
bn["Finolex Cables"] = "ফিনোলেক্স ক্যাবলস";
bn["Home Appliances"] = "হোম অ্যাপ্লায়েন্সেস";
bn["Blender"] = "ব্লেন্ডার";
bn["Juicer"] = "জুসার";
bn["Grinder"] = "গ্রিন্ডার";
bn["Ovens"] = "ওভেন";
bn["Kitchen Hood"] = "কিচেন হুড";
bn["Maker"] = "মেকার";
bn["Cleaning Appliance"] = "ক্লিনিং অ্যাপ্লায়েন্স";
bn["Coffee Maker"] = "কফি মেকার";
bn["Microwave"] = "মাইক্রোওয়েভ";
bn["Cooker"] = "কুকার";
bn["Stove"] = "স্টোভ";
bn["Water Purifier"] = "পানি পরিশোধক";
bn["Iron"] = "আয়রন";
bn["Refrigerator"] = "রেফ্রিজারেটর";
bn["Freezer"] = "ফ্রিজার";
bn["Washing Machine"] = "ওয়াশিং মেশিন";
bn["Dryer"] = "ড্রায়ার";
bn["Bedroom Furniture"] = "বেডরুম ফার্নিচার";
bn["Bedroom Furniture type"] = "বেডরুম ফার্নিচারের ধরণ";
bn["Select your bedroom furniture type"] =
  "আপনার বেডরুম  ফার্নিচারের ধরণ নির্বাচন করুন";
bn["Bed"] = "বেড";
bn["Mattress"] = "ম্যাট্রেস";
bn["Wardrobes"] = "ওয়ার্ড্রোব ";
bn["Bedside Table"] = "বেডসাইড টেবিল";
bn["Bedside Lamp"] = "বেড সাইড লাইট";
bn["Chair"] = "চেয়ার";
bn["Dressing Table"] = "ড্রেসিং টেবিল";
bn["Almirah"] = "আলমারি";
bn["Locker"] = "লকার";
bn["Furniture Description"] = "ফার্নিচারের বিবরণ";
bn["Living Room Furniture"] = "লিভিং রুম ফার্নিচার";
bn["Living Room Furniture Type"] = "লিভিং রুম ফার্নিচারের ধরণ";
bn["Select your bedroom furniture type"] =
  "আপনার লিভিং রুম ফার্নিচারের ধরণ নির্বাচন করুন";
bn["Sofas"] = "সোফা";
bn["Sectionals"] = "সেকশনাল";
bn["Sleeper Sofas"] = "স্লিপার সোফা";
bn["Accent Chair"] = "অ্যাকসেন্ট চেয়ার";
bn["Divan"] = "ডিভান";
bn["Showcases"] = "শোকেস";
bn["Loveseats"] = "লাভসীট";
bn["Shoe Rack"] = "জুতার তাক";
bn["Chaises"] = "চেইস";
bn["Center Table"] = "সেন্টার টেবিল";
bn["Side Table"] = "সাইড টেবিল";
bn["Chairs –and –a –half"] = "চেয়ার -এবং –এ-হাফ";
bn["Bookcase"] = "বইয়ের আলমারি";
bn["Recliners"] = "রিক্লিনারস";
bn["Shelves"] = "শেল্ভস";
bn["Table"] = "টেবিল";
bn["Kitchen & Dining Furniture"] = "কিচেন এবং ডাইনিং ফার্নিচার";
bn["Furniture Type"] = "ফার্নিচারের ধরণ";
bn["Select your furniture type"] = "আপনার ফার্নিচারের ধরণ নির্বাচন করুন";
bn["Kitchen Cabinet"] = "কিচেন কাবিনেট";
bn["Stool"] = "স্টোল";
bn["Dining Table"] = "ডাইনিং টেবিল";
bn["Chairs"] = "চেয়ার";
bn["Bar Stool"] = "বার স্টোল";
bn["Coffee Table"] = "কফি টেবিল";
bn["Dining Benches"] = "ডাইনিং বেঞ্চ";
bn["Drop –Leaf Table"] = "ড্রপ-লিফ টেবিল";
bn["Wine Rack"] = "ওয়াইন রাখার তাক";
bn["Gateleg Table"] = "গেটলেগ টেবিল";
bn["Round Dining Table"] = "গোল ডাইনিং টেবিল";
bn["Folding Dining Table"] = "ফোল্ডিং ডাইনিং টেবিল";
bn["Wooden Dining Table"] = "কাঠের ডাইনিং টেবিল";
bn["Refectory Table"] = "রিফেক্টরি টেবিল";
bn["Rack"] = "র‍্যাক";
bn["Trolley"] = "ট্রলি";
bn["Sideboard"] = "সাইডবোর্ড";
bn["Bench"] = "বেঞ্চ";
bn["Kettle"] = "কেটলি";
bn["Kitchen Island"] = "কিচেন আইল্যান্ড";
bn["Home Textiles & Decoration"] = "হোম টেক্সটাইল এবং ডেকুরেশন";
bn["Painting"] = "পেইন্টিং";
bn["Home Decor"] = "হোম ডেকর";
bn["Mattresses"] = "ম্যাট্রেসে";
bn["Antique"] = "প্রাচীন জিনিসপত্র";
bn["Carpet"] = "কার্পেট";
bn["Handicraft"] = "হস্তশিল্প";
bn["Bedding"] = "বিছানাপত্র";
bn["Chenille"] = "চেনিল";
bn["Chiffon"] = "শিফন";
bn["Velvet"] = "মখমল";
bn["Muslin"] = "মসলিন";
bn["Crepe"] = "ক্রেপ";
bn["Batik"] = "বাটিক";
bn["Brocade"] = "ব্রোকেড";
bn["Georgette"] = "জর্জেট";
bn["Cover"] = "কভার";
bn["Curtain"] = "পর্দা";
bn["Organza"] = "জর্জেট শাড়ী";
bn["Damask"] = "দামেস্ক";
bn["Calico"] = "ক্যালিকো";
bn["Cushions"] = "কুশন";
bn["Chintz"] = "চিন্টজ";
bn["Gingham"] = "গিংহাম";
bn["Buckram"] = "বকরাম";
bn["Bunting"] = "বান্টিং";
bn["Burlap"] = "বার্লাপ";
bn["Cheesecloth"] = "চিজক্লথ";
bn["Organdy"] = "অর্গান্ডি";
bn["Cotton"] = "কটন";
bn["Broadcloth"] = "ব্রডক্লথ";
bn["Flannel"] = "ফ্ল্যানেল";
bn["Poplin"] = "পপলিন";
bn["Velveteen"] = "ভেলভেটিন";
bn["Office & Shop Furniture"] = "অফিস এবং শপ ফার্নিচার";
bn["Desk"] = "ডেস্ক";
bn["Display Rack"] = "ডিসপ্লে র‍্যাক";
bn["Filing Cabinet"] = "ফাইলিং কেবিনেট";
bn["Conference Table"] = "কনফারেন্স টেবিল";
bn["Clock"] = "ঘড়ি";
bn["Picture Frame"] = "পিকচার ফ্রেম";
bn["Parlor Furniture"] = "পার্লর ফার্নিচার";
bn["Work Station"] = "ওয়ার্ক স্টেশন";
bn["Reception Table"] = "রিসিপশন টেবিল";
bn["Computer Table"] = "কম্পিউটার টেবিল";
bn["Book Shelves"] = "বুক শেল্ভস";
bn["Shoes Rack"] = "জুতার র‍্যাক";
bn["Tea & Coffee Table"] = "চা এবং কফি টেবিল";
bn["Dressers"] = "ড্রেসার্স";
bn["Cupboards"] = "আলমারি";
bn["Racks"] = "র‍্যাক";
bn["Household Items"] = "হাউসহোল্ড আইটেম";
bn["Household Item Types"] = "হাউসহোল্ড  ধরনের আইটম";
bn["Vacuum Cleaner"] = "ভ্যাকুয়াম ক্লিনার";
bn["Toilet"] = "টয়লেট";
bn["Waste container"] = "বর্জ্য ফেলার কন্টেইনার";
bn["Kitchen & Dining"] = "কিচেন এবং ডাইনিং";
bn["Mop"] = "মপ";
bn["Tools & machineries"] = "সরঞ্জাম ও যন্ত্রপাতি";
bn["Toothpaste"] = "ট্রুথ পেস্ট";
bn["Bin Bag"] = "বিন ব্যাগ";
bn["Air Conditioning"] = "এয়ার কন্ডিশনিং";
bn["Microwaves"] = "মাইক্রোওয়েভ";
bn["Towel"] = "তোয়ালে";
bn["Hair Dryer"] = "চুল শুকানোর যন্ত্র";
bn["Broom"] = "ঝাড়ু";
bn["Bookcase"] = "বইয়ের আলমারি";
bn["Paper Towel"] = "পেপার তোয়ালে";
bn["Knives"] = "ছুরি";
bn["Brush"] = "ব্রাশ";
bn["Bathtub"] = "বাথটাব";
bn["Sewing Machines"] = "সেলাই মেশিন";
bn["Dyer"] = "ডায়ার";
bn["Sponge"] = "স্পঞ্জ";
bn["Ladle"] = "মই";
bn["Vase"] = "ফুলদানি";
bn["Alarm Clock"] = "অ্যালার্মঘড়ি";
bn["Laundry Detergent"] = "লন্ড্রি ডিটারজেন্ট";
bn["Children's Furniture"] = "শিশুদের ফার্নিচার";
bn["Bunk Bed"] = "বাঙ্ক বিছানা";
bn["Kid Reading Table"] = "বাচ্চাদের পড়ার টেবিল";
bn["Baby Cot"] = "শিশুর খাট";
bn["Bassinet"] = "বেসিনেট";
bn["Kid Bed"] = "বাচ্চাদের বিছানা";
bn["Rocking Chair"] = "দোলান  চেয়ার";
bn["Booster Seat"] = "বুস্টার সিট";
bn["Changing Table"] = "চেইঞ্জিং টেবিল";
bn["Toddler Bed"] = "টডলার বিছানা";
bn["Swing"] = "দোলনা";
bn["High Chair"] = "হাই চেয়ার";
bn["Playpen"] = "প্লেপেন";
bn["Freeze"] = "ফ্রিজ";
bn["Freeze Type"] = "ফ্রিজের ধরণঃ";
bn["Select your freeze type"] = "আপনার ফ্রিজের ধরণ নির্বাচন করুন";
bn["Side by Side"] = "সাইড বাই সাইড";
bn["French Door"] = "ফ্রেন্স ডোর";
bn["Bottom Freezer"] = "বটম ফ্রিজার";
bn["Top Freezer"] = "টপ ফ্রিজার";
bn["Counter Depth"] = "কাউন্টার ডেপথ ফ্রিজ";
bn["Normal Freeze"] = "নরমাল ফ্রিজ";
bn["Deep Freeze"] = "ডিপ ফ্রিজ";
bn["LG"] = "এলজি";
bn["Singer"] = "সিঙ্গার";
bn["Haier"] = "হায়ার";
bn["Electrolux"] = "ইলেক্ট্রোলাক্স";
bn["Videocon"] = "ভিডিওকন";
bn["Minister"] = "মিনিস্টার";
bn["Sharp"] = "শার্প";
bn["Siemens"] = "সিমেন্স";
bn["Freeze Description"] = "ফ্রিজের বিবরণঃ";
bn["Fan & Washing Machine"] = "ফ্যান এবং ওয়াশিং মেশিন";
bn["Fan"] = "ফ্যান";
bn["Washing Machine"] = "ওয়াশিং মেশিন";
bn["Crockeries Items"] = "ক্রোকারিজ আইটেম";
bn["Dishwasher"] = "ডিসওয়াশার";
bn["Pitcher"] = "কলস";
bn["Blender"] = "ব্লেন্ডার";
bn["Cars"] = "গাড়ি";
bn["Tata"] = "টাটা";
bn["Chevrolet"] = "শেভ্রোলেট";
bn["Toyota"] = "টয়োটা";
bn["Akij"] = "আকিজ";
bn["Alpa Romeo"] = "আলফা রোমিও";
bn["Daihatsu"] = "দাইহাটসু";
bn["Proton"] = "প্রোটন";
bn["Maruti"] = "মারুতি";
bn["Daewoo"] = "ডেওউ";
bn["Changan"] = "চাঙ্গান";
bn["Honda"] = "হোন্ডা";
bn["BMW"] = "বিএমডব্লিউ";
bn["BAIC"] = "বিএআইসি";
bn["Audi"] = "অডি";
bn["Subaru"] = "সুবারু";
bn["Buick"] = "বুইক";
bn["Chery"] = "চেরি";
bn["Nissan"] = "নিসান";
bn["Land Rover"] = "ল্যান্ড রোভার";
bn["Hyundai"] = "হুন্দাই";
bn["Chrysler"] = "ক্রাইসলার";
bn["Lexus"] = "লেক্সাস";
bn["Mazda"] = "মাজদা";
bn["Mercedes-Benz"] = "মার্সিডিজ বেঞ্জ";
bn["Ford"] = "ফোর্ড";
bn["Volkswagen"] = "ভক্সওয়াগেন";
bn["Jeep"] = "জীপ";
bn["Cadillac"] = "ক্যাডিলাক";
bn["Geely"] = "গিলি";
bn["Year of manufacture"] = "উৎপাদনের বছর";
bn["Country of origin"] = "উৎপত্তি দেশ";
bn["India"] = "ভারত";
bn["Japan"] = "জাপান";
bn["China"] = "চীন";
bn["Germany"] = "জার্মানি";
bn["Korea"] = "কোরিয়া";
bn["America"] = "আমেরিকা";
bn["Others Country"] = "অন্যান্য দেশ";
bn["Transmission"] = "ট্রান্সমিশন";
bn["Automatic Transmission"] = "অটোমেটিক ট্রান্সমিশন";
bn["Manual Transmission"] = "ম্যানুয়াল ট্রান্সমিশন";
bn["Others Transmission"] = "অন্যান্য ট্রান্সমিশন";
bn["Registration Year"] = "রেজিস্ট্রেশনের বছর";
bn["Fuel Type"] = "জ্বালানীর ধরণ";
bn["Select your fuel type"] = "আপনার ফুয়েল ধরণ নির্বাচন করুন";
bn["Diesel"] = "ডিজেল";
bn["Petrol"] = "পেট্রল";
bn["CNG"] = "সিএনজি";
bn["Hybrid"] = "হাইব্রিড";
bn["Electric"] = "ইলেকট্রিক";
bn["Octane"] = "অকটেন";
bn["LPG"] = "এলপিজি";
bn["Others fuel type"] = "অন্যান্য ফুয়েল ধরণ";
bn["Select your body type"] = "আপনার বডি ধরণ নির্বাচন করুন";
bn["Coupe / Sports"] = "কূপ/স্পোর্টস";
bn["Saloon"] = "সেলুন";
bn["SUV / 4X4"] = "এসইউভি/ ৪x৪";
bn["Hatchback"] = "হ্যাচব্যাক";
bn["MPV"] = "এমপিভি";
bn["Estate"] = "এস্টেট";
bn["Motorbikes & Scooters"] = "মোটরবাইক ও স্কুটার";
bn["Motorbikes"] = "মোটরবাইক";
bn["Scooters"] = "স্কুটার";
bn["Electronic Motorbikes"] = "ইলেকট্রনিক মোটরবাইক";
bn["Honda"] = "হোন্ডা";
bn["Kawasaki"] = "কাওয়াসাকি";
bn["Suzuki"] = "সুজুকি";
bn["Yamaha"] = "ইয়ামাহা";
bn["Moriwak"] = "মরিওয়াক";
bn["Evoke Motorcycles"] = "ইভোক মোটরসাইকেল";
bn["Jincheng Suzuki"] = "জিনচেং সুজুকি";
bn["Keeway"] = "কিওয়ে";
bn["Lifan"] = "লিফান";
bn["Loncin"] = "লনসিন";
bn["Niu Technologies"] = "নিইউ টেকনোলজিস";
bn["Qianjiang"] = "কিউ জিয়াং";
bn["Qingqi"] = "কিংকিই";
bn["Thumpstar"] = "থাম্পস্টার";
bn["Yinxiang Motorcycle"] = "ইয়েনজিয়াং মোটরসাইকেল";
bn["Znen"] = "জিনেন";
bn["Zongshen"] = "জংশেন";
bn["Bajaj"] = "বাজাজ";
bn["Hero"] = "হিরো";
bn["Mahindra"] = "মাহিন্দ্রা";
bn["Peugeot"] = "পিউগেট";
bn["BSA"] = "বিএসএ";
bn["Jawa"] = "জাওয়া";
bn["Yezdi"] = "ইয়েজদি";
bn["Royal"] = "রয়্যাল";
bn["TVS"] = "টিভিএস";
bn["Norton"] = "নর্টন";
bn["Daelim"] = "ডেইলিম";
bn["Hyosung"] = "হায়োসাং";
bn["KR Motors"] = "কেআর মোটরস";
bn["Bangladesh"] = "বাংলাদেশ";
bn["Body type"] = "বডি ধরণ";
bn["Bicycles"] = "সাইকেল";
bn["Adder"] = "অ্যাডার";
bn["Atlas"] = "এটলাস";
bn["Bianchi"] = "বিয়াঞ্চি";
bn["Camp"] = "ক্যাম্প";
bn["Claud Butler"] = "ক্লড বাটলার";
bn["Combat"] = "কমব্যাট";
bn["Core"] = "কোর";
bn["Coyote"] = "কোয়োট";
bn["DiamondBack"] = "ডায়মন্ডব্যাক";
bn["Duranta"] = "দুরন্ত";
bn["Falcon"] = "ফ্যালকন";
bn["Format"] = "ফরমেট";
bn["Formula"] = "ফর্মুলা";
bn["Foxter"] = "ফক্সটার";
bn["Ghost"] = "গোস্ট";
bn["Giant"] = "জিয়ান্ট";
bn["Haolaixi"] = "হাওলাইক্সই";
bn["Hero"] = "হিরো";
bn["Indigo"] = "ইন্ডিগো";
bn["Kaimarte"] = "কাইমার্তে";
bn["Kellys"] = "কেলিস";
bn["Landao"] = "ল্যান্ডেও";
bn["Laux"] = "লাক্স";
bn["Marine"] = "মেরিন";
bn["MBM"] = "এমবিএম";
bn["Merida"] = "মেরিডা";
bn["Mustang"] = "মুস্তাং";
bn["Nekro"] = "নেক্রো";
bn["Phoenix"] = "ফনিক্স";
bn["Python"] = "পাইথন";
bn["Precious"] = "প্রেসিয়াস";
bn["Prince"] = "প্রিন্স";
bn["Raleigh"] = "রেলি";
bn["Riddick"] = "রিদ্দিক";
bn["Rock Machine"] = "রক মেশিন";
bn["SafeWay"] = "সেফওয়ে";
bn["Saracen"] = "সারাসেন";
bn["Trek"] = "ট্রেক";
bn["Trinx"] = "ট্রিনক্স";
bn["Twitter"] = "টুইটার";
bn["Unifox"] = "ইউনিফক্স";
bn["Upland"] = "আপল্যান্ড";
bn["Veloce"] = "ভেলোস";
bn["Vertigo"] = "ভার্টিগো";
bn["Viking"] = "ভাইকিং";
bn["Auto Parts & Accessories"] = "অটো যন্ত্রাংশ ও এক্সেসরিজ";
bn["Select your country of origin"] = "আপনার উৎপত্তি দেশ নির্বাচন করুন";
bn["Parts & Accessories Type"] = "পার্টস এবং এক্সেসরিজের ধরণ";
bn["Rentals"] = "ভাড়া";
bn["Rental Type"] = "ভাড়ার ধরণ";
bn["Trucks Three Wheelers"] = "ট্রাক ও থ্রি হুইলার";
bn["Isuzu"] = "ইসুজু";
bn["Ashok Leyland"] = "অশোক লেল্যান্ড";
bn["Foton"] = "ফোটন";
bn["JAC"] = "জেএসি";
bn["Others Brand"] = "অন্যান্য ব্র্যান্ড";
bn["Tractor & Heavy Duty"] = "ট্রাক্টর এবং ভারী শুল্ক";
bn["Heavy Duty"] = "হেবি ডিউটি";
bn["Tractor"] = "ট্রাক্টর";
bn["Boats & Water Transport"] = "নৌকা ও জল পরিবহন";
bn["Transport Type"] = "ট্রান্সপোর্টের ধরণ";
bn["Sea Boat"] = "সি বোট";
bn["Speed Boat"] = "স্পিড বোট";
bn["Trallers"] = "ট্রলার";
bn["Ship"] = "জাহাজ";
bn["Birdship"] = "বার্ডশিপ";
bn["Feri"] = "ফেরি";
bn["Vans"] = "ভ্যান";
bn["Toyata"] = "টয়োটা";
bn["Mitsubishi"] = "মিটসুবিশি";
bn["Emma"] = "এম্মা";
bn["Ford"] = "ফোর্ড";
bn["JMC"] = "জে এম সি";
bn["Daihatsu"] = "দাইহাতসু";
bn["Foton"] = "ফোটন";
bn["Edition/ Stylish"] = "সংস্করণ / স্টাইলিশ";
bn["Kilometers Run"] = "কিলোমিটার চলেছে (কিমি)";
bn["Auto Services"] = "অটো সার্ভিস";
bn["Auto Services Type"] = "অটো সার্ভিস ধরণ";
bn["Services Description"] = "সার্ভিসের বিবরণ";
bn["Buses"] = "বাস";
bn["Nitol TATA"] = "নিটোল টাটা";
bn["Hino"] = "হিনো";
bn["Isuzu"] = "ইসুজু";
bn["Eicher"] = "এইচার";
bn["MAN"] = "ম্যান";
bn["Nissan"] = "নিশান";
bn["Scania"] = "স্কানিয়া";
bn["Volvo"] = "ভলভো";
bn["Mercedes Benz"] = "মার্সিডিজ বেঞ্জ";
bn["Engine Capacity"] = "ইঞ্জিনের ক্ষমতা (সিসি)";
bn["Construction Equipments"] = "নির্মাণ সরঞ্জাম";
bn["Concrete Mixer"] = "কংক্রিট মিশ্রন";
bn["Tower Crane"] = "টাওয়ার কপিকল";
bn["Breaker"] = "ব্রেকার";
bn["Excavator"] = "খননকারী";
bn["Grader"] = "গ্রেডার";
bn["Bobcat"] = "ববক্যাট";
bn["Bulldozer"] = "বুলডোজার";
bn["Road Roller"] = "রোড রোলার";
bn["Backhoe"] = "ব্যাকহো";
bn["Forklift"] = "ফর্কলিফ্ট";
bn["Grocery"] = "মুদিখানা";
bn["Grocery Item Type"] = "মুদিখানা আইটেমের ধরণ";
bn["Select your grocery item type"] =
  "আপনার মুদিখানা আইটেমের ধরণ নির্বাচন করুন";
bn["Rice"] = "চাল";
bn["Pulse"] = "ডাল";
bn["Biscuits"] = "বিস্কুট";
bn["Snacks"] = "জলখাবার";
bn["Cold Drinks"] = "কোল্ড ডিংকস";
bn["Fruit Juice"] = "ফলের রস";
bn["Chocolate"] = "চকোলেট";
bn["Bakery & Baking"] = "বেকারি এবং বেকিং";
bn["Healthcare"] = "স্বাস্থ্যসেবা";
bn["Healthcare Type"] = "স্বাস্থ্যসেবার ধরণ";
bn["Select your healthcare type"] = "আপনার স্বাস্থ্যসেবার ধরণ নির্বাচন করুন";
bn["Skin Care"] = "স্কিন কেয়ার";
bn["Hair Care"] = "হেয়ার কেয়ার";
bn["Mouth Care"] = "মাউথ কেয়ার";
bn["Face Care"] = "ফেইস কেয়ার";
bn["Body Care"] = "বডি কেয়ার";
bn["Hand Care"] = "হ্যান্ড কেয়ার";
bn["Other Health Care"] = "অন্যান্য স্বাস্থ্যসেবা";
bn["Fruits, Vegetables & Mushroom"] = "ফল, শাক-সবজি  এবং  মাশরুম";
bn["Guava"] = "পেয়ারা";
bn["Pineapple"] = "আনারস";
bn["Watermelon"] = "তরমুজ";
bn["Strawberry"] = "স্ট্রবেরি";
bn["Orange"] = "কমলা";
bn["Grape"] = "আঙ্গুর";
bn["Apple"] = "আপেল";
bn["Papaya"] = "পেঁপে";
bn["Banana"] = "কলা";
bn["Mango"] = "আম";
bn["Jackfruit"] = "কাঁঠাল";
bn["Dates"] = "খেজুর";
bn["Cherry"] = "চেরি";
bn["Amaranth"] = "আমলা";
bn["Apple Gourd"] = "ডি কুমড়া";
bn["Ash Gourd"] = "চাল কুমড়া";
bn["Beetroot"] = "বীটরুট";
bn["Bitter Gourd"] = "করলা";
bn["Black-eyed pea"] = "বরবটি";
bn["Bottle Gourd"] = "লাউ";
bn["Broad Beans"] = "মটশুটি";
bn["Brussels sprout"] = "ব্রাসেলস স্প্রাউট";
bn["Cabbage"] = "বাঁধাকপি";
bn["Carrot"] = "গাজর";
bn["Cauliflower"] = "ফুলকপি";
bn["Celery"] = "সেলারি";
bn["Ceylon spinach"] = "সিলন পালন শাক";
bn["Chickpea"] = "ছোলা";
bn["Cucumber"] = "শসা";
bn["Drumstick"] = "সাজনা";
bn["Brinjal"] = "বেগুন";
bn["Elephant Foot yam"] = "ওল কচু";
bn["French Beans"] = "ফরাসি মটরশুটি";
bn["Garbanzo"] = "গার্বেনজো";
bn["Gooseberry"] = "গুজবেরি";
bn["Indian pea"] = "ভারতীয় মটর";
bn["Ivy Gourd"] = "পটল";
bn["Kidney bean"] = "কিডনি বীন";
bn["Lentil"] = "মসুর ডাল";
bn["Lotus root"] = "পদ্ম মূল";
bn["Malabar Spinach"] = "পুঁইশাঁক";
bn["Mint"] = "পুদিনা";
bn["Moth bean"] = "মথ ডাল";
bn["Mustard"] = "সরিষা";
bn["Okra"] = "ঢেঁড়স";
bn["Onion"] = "পেঁয়াজ";
bn["Pea"] = "মটর";
bn["Pigeon pea"] = "মটরশুঁটি";
bn["Pointed Gourd"] = "পটল";
bn["Potato"] = "আলু";
bn["Pumpkin"] = "কুমড়া";
bn["Radish"] = "মূলা";
bn["Red Amaranth"] = "লাল শাঁক";
bn["Red Lentil"] = "লাল মসুর ডাল";
bn["Ridge Gourd"] = "ঝিঙ্গে";
bn["Snake Gourd"] = "চিচিঙ্গা";
bn["Spinach"] = "পালং শাঁক";
bn["Spring Onion"] = "পেয়াজ পাতা";
bn["String Beans"] = "স্ট্রিং মরটশুটি";
bn["Sweet Corn"] = "মিষ্টি ভুট্টা";
bn["Sweet Potato or Kumara"] = "মিষ্টি আলু অথবা কুমার";
bn["Taro"] = "কচুর ছড়ি";
bn["Teasle Gourd"] = "কাঁকরোল";
bn["Tomato"] = "টমেটো";
bn["Turnip"] = "শালগম";
bn["Turnip greens"] = "শালগম শাক";
bn["Water spinach"] = "কলমি শাঁক";
bn["Winter melon"] = "জালি কুমড়া";
bn["Rum"] = "রাম";
bn["Household"] = "হাউসহোল্ড";
bn["Dish Soap"] = "থালা বাসন ধোয়ার সাবান";
bn["Glass Cleaner"] = "গ্লাস ক্লিনার";
bn["Tissues"] = "টিস্যু";
bn["Toilet Paper"] = "টয়লেট পেপার";
bn["Paper Towels"] = "কাগজের গামছা";
bn["Napkins"] = "ন্যাপকিন";
bn["Baby Wipes"] = "বেবি ওয়াইপ্স";
bn["Disposables Item"] = "নিষ্পত্তিযোগ্য আইটেম";
bn["Air Freshener"] = "এয়ার ফ্রেশনার";
bn["Light/Bulbs"] = "লাইট / বাল্ব";
bn["Pen"] = "কলম";
bn["Meat & Seafood"] = "মাংস এবং সামুদ্রিক খাবার";
bn["Meat & Seafood Type"] = "মাংস এবং সামুদ্রিক খাবারের ধরণ";
bn["Select your meat & seafood type"] =
  "আপনার মাংস এবং সামুদ্রিক খাবারের ধরণ নির্বাচন করুন";
bn["Deshi Fish"] = "দেশি মাছ";
bn["Beef"] = "গরুর মাংস";
bn["Mutton"] = "খাষির মাংস";
bn["Chicken"] = "মুরগির মাংস";
bn["Sea Fish"] = "সামুদ্রিক মাছ";
bn["Kids Products"] = "বাচ্চাদের পণ্য";
bn["Product Type"] = "পণ্যের ধরণ";
bn["Select your product type"] = "আপনার পণ্যের ধরণ নির্বাচন করুন";
bn["Electronic Repair Tool Kit"] = "ইলেকট্রনিক মেরামত টুল কিট";
bn["Mobile Repairing Tool Kit"] = "মোবাইল রিপেয়ারিং টুল কিট";
bn["General Purpose Tool Kit"] = "জেনারেল পারপাস টুল কিট";
bn["Multipurpose Tool Kit"] = "মাল্টিপারপাস টুল কিট";
bn["Champering Tool Kit"] = "চ্যাম্পারিং টুল কিট";
bn["Padlock Shape Tool Kit"] = "প্যাডলক টুল কিট";
bn["Socket Combination Tool kit"] = "সকেট কম্বিনেশন টুল কিট";
bn["Screwdriver Set"] = "স্ক্রুড্রাইভার সেট";
bn["Screwdriver Bits"] = "স্ক্রুড্রাইবার বিটস";
bn["Commercial Tool Kit"] = "কমার্শিয়াল টুল কিট";
bn["Hand Tool"] = "হ্যান্ড টুল";
bn["Bread"] = "রুটি";
bn["Beverage"] = "পানীয়";
bn["Bread"] = "রুটি";
bn["Baverge"] = "পানীয়";
bn["Other Essential Commodities"] = "অন্যন্য প্রয়োজনীয় পণ্য";
bn["Watches"] = "ঘড়ি";
bn["Watches Item Type"] = "ঘড়ির আইটেমের ধরণ";
bn["Item Type"] = "আইটেমের ধরণ";
bn["Select your watches item type"] = "আপনার ঘড়ির আইটেমের ধরণ নির্বাচন করুন";
bn["Digital"] = "ডিজিটাল";
bn["Analog"] = "এনালগ";
bn["Chronograph"] = "ক্রোনোগ্রাফ";
bn["Beauty Products"] = "সৌন্দর্য্যের পণ্য";
bn["Makeup"] = "মেকআপ";
bn["Lipstick"] = "লিপস্টিক";
bn["Eyeshadow"] = "আইশ্যাডো";
bn["Eyeliner"] = "আইলাইনার";
bn["Nail Polish"] = "নখ পালিশ";
bn["Night Cream"] = "নাইট ক্রিম";
bn["Face Wash"] = "ফেইস ওয়াশ";
bn["Jewelry"] = "জুয়েলারী";
bn["Jewelry Type"] = "জুয়েলারীর ধরণ";
bn["Select your jewelry type"] = "আপনার জুয়েলারীর ধরণ নির্বাচন করুন";
bn["Necklace"] = "নেকলেস";
bn["Gold Chain"] = "গোল্ড চেইন";
bn["Ear Ring"] = "কানের রিং";
bn["Bracelet"] = "ব্রেসলেট";
bn["Finger Ring (Gold)"] = "আঙ্গুলের আংটি (সোনার)";
bn["Finger Ring (Diamond)"] = "আঙ্গুলের আংটি (হীরার)";
bn["Nose Pin (Gold)"] = "নাকের পিন (সোনার)";
bn["Nose Pin (Diamond)"] = "নাকের পিন (হীরার)";
bn["Bags"] = "ব্যাগ";
bn["Vanity Bag"] = "ভ্যানিটি ব্যাগ";
bn["Travel Bag"] = "ট্রাভেল ব্যাগ";
bn["School Bag"] = "স্কুল ব্যাগ";
bn["Kids Bag"] = "বাচ্চাদের ব্যাগ";
bn["Luggage"] = "লাগেজ";
bn["Trolley Bag"] = "ট্রলি ব্যাগ";
bn["Rider Backpack"] = "রাইডার ব্যাকপ্যাক";
bn["Small Ladies Purse"] = "ছোট লেডিস পার্স";
bn["Laptop Bag"] = "ল্যাপটপ ব্যাগ";
bn["Optical Items"] = "অপটিক্যাল আইটেম";
bn["Optical Item Type"] = "অপটিক্যাল আইটেমের ধরণ";
bn["Select your optical item type"] =
  "আপনার অপটিক্যাল আইটেমের ধরণ নির্বাচন করুন";
bn["Mens Optical Frame"] = "পুরুষদের অপটিক্যাল ফ্রেম";
bn["Ladies Optical Frame"] = "মহিলাদের অপটিক্যাল ফ্রেম";
bn["Optical Lens"] = "অপটিক্যাল লেন্স";
bn["Optical Box"] = "অপটিক্যাল বক্স";
bn["Sunglass"] = "সানগ্লাস";
bn["Fashion Accessories"] = "ফ্যাশন এক্সেসরিজ";
bn["Fashion Accessories Type"] = "ফ্যাশন এক্সেসরিজের ধরণ";
bn["Select your fashion accessories type"] =
  "আপনার ফ্যাশন এক্সেসরিজের ধরণ নির্বাচন করুন";
bn["Tie"] = "টাই";
bn["Bow Tie"] = "বো টাই";
bn["Belt"] = "বেল্ট";
bn["Cap"] = "টুপি";
bn["Hat"] = "টুপি";
bn["Hair Band"] = "হেয়ার ব্যান্ড";
bn["Earmuffs"] = "কানের কাপড়";
bn["Scarf"] = "ওড়না";
bn["Hairpin"] = "চুলের পিন";
bn["Mittens"] = "বাচ্চাদের মোজা";
bn["Gloves"] = "হাতমোজা";
bn["Hospital & Clinic"] = "হাসপাতাল এবং ক্লিনিক";
bn["Hospital & Clinic Type"] = "হাসপাতাল এবং ক্লিনিকের ধরণ";
bn["Select your hospital & clinic type"] =
  "আপনার হাসপাতাল এবং ক্লিনিকের ধরণ নির্বাচন করুন";
bn["General Hospital"] = "জেনারেল হাসপাতাল";
bn["Eye Hospital"] = "চক্ষু হাসপাতাল";
bn["Cardiac Hospital"] = "কার্ডিয়াক হাসপাতাল";
bn["Cancer Hospital"] = "ক্যান্সার হাসপাতাল";
bn["Diabetes Hospital"] = "ডায়াবেটিস হাসপাতাল";
bn["Neuro Hospital"] = "নিউরো হাসপাতাল";
bn["Mental Hospital"] = "মেন্টাল হাসপাতাল";
bn["Mother & Children Hospital"] = "মা ও শিশুদের হাসপাতাল";
bn["Ambulance"] = "অ্যাম্বুলেন্স";
bn["Supplements, Nutrition & Medicine"] = "সাপ্লিমেন্ট, নিউট্রিশন এবং মেডিসিন";
bn["Herbal Products"] = "হার্বাল পণ্য";
bn["Herbal Cosmetics"] = "হার্বাল কসমেটিকস";
bn["Herbal Food Supplements"] = "হার্বাল ফুড সাপ্লিমেন্টস";
bn["Herbal Tea"] = "হার্বাল চা";
bn["Herbal Medicine"] = "হার্বাল ঔষধ";
bn["Fragrances-Ator-Perfumes"] = "সুবাস-আতর-পারফিউম";
bn["Perfume Type"] = "পারফিউমের ধরণ";
bn["Select your perfume type"] = "আপনার পারফিউমের ধরণ নির্বাচন করুন";
bn["Fragrances"] = "সুবাস";
bn["Body Spray"] = "শরীরের স্প্রে";
bn["Deodorant"] = "ডিওডোরেন্ট";
bn["Ator"] = "আতর";
bn["Agar Bati"] = "আগরবাতি";
bn["Perfumes"] = "পারফিউম";
bn["Other Items"] = "অন্যান্য আইটেম";
bn["Women's Clothing & Accessories"] = "মহিলাদের পোশাক এবং এক্সেসরিজ";
bn["Women’s Wear Item Type"] = "মহিলাদের পোশাক আইটেমের ধরণ";
bn["Sarees"] = "শাড়ি";
bn["Shalwar, Kameez & Kurtis"] = "সালোয়ার, কামিজ এবং কুর্তি";
bn["Unstitched Fabric"] = "আনস্টীচ্‌ড ফেব্রিক";
bn["Hijab & Abaya"] = "হিজাব ও আবায়া";
bn["Women’s Tops & T-shirt"] = "মহিলাদের টপস এবং টি-শার্ট";
bn["Gown"] = "গাউন";
bn["Skirt"] = "স্কার্ট";
bn["Swimwear"] = "সাঁতারের পোশাক";
bn["Bra"] = "ব্রা";
bn["Briefs"] = "জাঙ্গিয়া";
bn["Sleepwear"] = "ঘুমের পোশাক";
bn["Shape wear"] = "শেপ ওয়ার";
bn["Women’s Sweater & Cardigans"] = "মহিলাদের সোয়েটার এবং কার্ডিগান";
bn["Women’s Jacket"] = "মহিলাদের জ্যাকেট";
bn["Sandal"] = "স্যান্ডেল";
bn["Formal Shoes"] = "ফর্মাল জুতা";
bn["Flip Flops"] = "ফ্লিপ ফ্লপ";
bn["Men's Clothing & Accessories"] = "পুরুষদের পোশাক এবং এক্সেসরিজ";
bn["Men’s Wear Item Type"] = "পুরুষদের পোশাকের আইটেম ধরণ";
bn["T-Shirt"] = "টি-শার্ট";
bn["Jeans Pant"] = "জিন্স প্যান্ট";
bn["Polo Shirt"] = "পোলো শার্ট";
bn["Panjabi and Sherwani"] = "পাঞ্জাবি আর শেরওয়ানি";
bn["Suits and Blazers"] = "স্যুট এবং ব্লেজার";
bn["Jacket"] = "জ্যাকেট";
bn["Hoodies"] = "হুডিস্‌";
bn["Rain Coats and Trenches"] = "রেইন কোট এবং ট্রেঞ্চ";
bn["Sweater"] = "সোয়েটার";
bn["Longi and Fotua"] = "লুঙ্গি ও ফতুয়া";
bn["Sandal"] = "স্যান্ডেল";
bn["Formal Shoes"] = "ফর্মাল জুতা";
bn["Caps and Hat"] = "ক্যাপ এবং টুপি";
bn["Underwear"] = "জাঙ্গিয়া";
bn["Children's Clothing & Accessories"] = "বাচ্চাদের পোশাক এবং এক্সেসরিজ";
bn["Item Of Baby Boys Fashion"] = "বাচ্চা ছেলেদের ফ্যাশন আইটেম";
bn["Pants"] = "প্যান্টস্‌";
bn["Shirt"] = "শার্ট";
bn["Panjabi"] = "পাঞ্জাবি";
bn["Leans Pants"] = "জিন্স প্যান্টস্‌";
bn["Sweater"] = "সোয়েটার";
bn["Jacket"] = "জ্যাকেট";
bn["Shoes"] = "জুতা";
bn["Blazers"] = "ব্লেজার";
bn["Fotua"] = "ফতুয়া";
bn["Wholesale - Bulk"] = "পাইকারি-বাল্ক";
bn["Shoes & Footware"] = "জুতা ও পাদুকা";
bn["Men Shoes"] = "পুরুষদের জুতা";
bn["Women’s Shoes, Sandals and Heel"] = "মহিলাদের জুতা, স্যান্ডেল এবং হিল";
bn["Baby Boys Shoes & Sandals"] = "বাচ্চা ছেলেদের জুতা এবং স্যান্ডেল";
bn["Other Shoes"] = "অন্যান্য জুতা";
bn["Leather Products"] = "চামড়ার পণ্য";
bn["Leather Product Type"] = "চামড়ার পণ্যের ধরণ";
bn["Select your leather product type"] =
  "আপনার চামড়ার পণ্যের ধরণ নির্বাচন করুন";
bn["Belt"] = "বেল্ট";
bn["Money Bag"] = "মানি ব্যাগ";
bn["Leather Jacket"] = "চামড়ার জ্যাকেট";
bn["Ladies Leather Bag"] = "লেডিস লেদার ব্যাগ";
bn["Office Leather bag"] = "অফিসের চামড়ার ব্যাগ";
bn["School Bag"] = "স্কুল ব্যাগ";
bn["Musical Instruments"] = "বাদ্যযন্ত্র";
bn["Musical Instrument Type"] = "বাদ্যযন্ত্রের ধরন";
bn["Select your musical instrument type"] =
  "আপনার বাদ্যযন্ত্রের ধরন নির্বাচন করুন";
bn["Accordion"] = "অ্যাকর্ডিয়ন";
bn["Acoustic Guitar"] = "অ্যাকোস্টিক গিটার";
bn["Bagpipes"] = "ব্যাগপাইপস";
bn["Banjo"] = "ব্যাঞ্জো";
bn["Bass Guitar"] = "বেস গিটার";
bn["Bongo Drums"] = "বঙ্গো ড্রামস";
bn["Bugle"] = "বিগল";
bn["Cello"] = "সেলো";
bn["Clarinet"] = "ক্লারিনেট";
bn["Cymbals"] = "করতাল";
bn["Drums"] = "ড্রামস";
bn["Electric Guitar"] = "বৈদ্যুতিক গিটার";
bn["Flute"] = "বাঁশি";
bn["French horn"] = "ফরাসি শিঙা";
bn["Harmonica"] = "হারমোনিকা";
bn["Harp"] = "বীণা";
bn["Keyboard"] = "কীবোর্ড";
bn["Maracas"] = "মারাকাস";
bn["Organ"] = "অর্গান";
bn["Pan flute (pan pipes)"] = "প্যান বাঁশি (প্যান পাইপ)";
bn["Piano"] = "পিয়ানো";
bn["Recorder"] = "রেকর্ডার";
bn["Saxophone"] = "স্যাক্সোফোন";
bn["Sitar"] = "সেতার";
bn["Tambourine"] = "খঞ্জনী";
bn["Triangle"] = "ত্রিভুজ";
bn["Trombone"] = "ট্রম্বোন";
bn["Trumpet"] = "ট্রাম্পেট";
bn["Tuba"] = "তুবা";
bn["Ukulele"] = "ইউকুলেল";
bn["Violin"] = "বেহালা";
bn["Xylophone"] = "জাইলোফোন";
bn["Bassoon"] = "বসুন";
bn["Castanets"] = "কাস্টেনেটস";
bn["Didgeridoo"] = "ডিজেরিডু";
bn["Double Bass"] = "ডাবল বাস";
bn["Gong"] = "গং";
bn["Harpsichord"] = "হার্পসিকর্ড";
bn["Lute"] = "লুট";
bn["Mandolin"] = "ম্যান্ডোলিন";
bn["Oboe"] = "ওবো";
bn["Piccolo"] = "পিকোলো";
bn["Viola"] = "ভায়োলা";
bn["Children's Items"] = "শিশুদের আইটেম";
bn["Fitness & Gym"] = "ফিটনেস এবং জিম";
bn["Sports Item Type"] = "খেলাধুলার আইটেমের ধরণ";
bn["Select your sports item type"] =
  "আপনার খেলাধুলার আইটেমের ধরণ নির্বাচন করুন";
bn["Bellitron"] = "বেলিট্রন";
bn["Rower"] = "রোয়ার";
bn["VITL"] = "ভিআইটিএল";
bn["Slidez"] = "স্লাইডজ";
bn["Dumbbells"] = "ডাম্বেল";
bn["Hypervolt Go"] = "হাইপারভোল্ট গো";
bn["Battle Rope"] = "ব্যাটল রোপ";
bn["Kettle Gryp"] = "কেটল গ্রিপ";
bn["Sandbags"] = "বালির ব্যাগ";
bn["Power Tower"] = "পাওয়ার টাওয়ার";
bn["Bow"] = "ধনুক";
bn["Pro 50 Adjustable Dumbbells"] = "প্রো ফিফটি অ্যাডজাস্টেবল ডাম্বেল";
bn["10 to 40 Lb Adjustable Kettlebell"] =
  "টেন টু ফোর্টি পাউন্ড সামঞ্জস্যযোগ্য কেটলবেল";
bn["Adjustable Dumbbell Set"] = "সামঞ্জস্যযোগ্য ডাম্বেল সেট";
bn["3-in-1 Soft Plyo Boxes"] = "থ্রি-ইন-ওয়ান নরম প্লাইও বক্স";
bn["The Reversible (Un) Mat"] = "বিপরীতমুখী (ইউএন) মাদুর";
bn["Suspension Training Kit"] = "সাসপেনশন ট্রেনিং কিট";
bn["AD Pro Airdyne Bike"] = "এডি প্রো এয়ারডাইন বাইক";
bn["Soft Kettlebell with Handle"] = "হ্যান্ডেল সহ নরম কেটলবেল";
bn["XD Kevlar Weight Vest"] = "এক্সডি কেভলার ওয়েট ভেস্ট";
bn["Ab Wheel Roller"] = "এবি হুইল রোলার";
bn["Fan Bike"] = "ফ্যান বাইক";
bn["Crossrope"] = "ক্রসরোপ";
bn["Black Concept 2 RowErg Rower"] = "ব্ল্যাক কনসেপ্ট ২ রোইআরজি রোয়ার";
bn["SelectTech 4.1S Stowable Bench"] = "সিলেক্টটেক ৪.১ এস স্টোয়েবল বেঞ্চ";
bn["Adjustable Bench"] = "সামঞ্জস্যযোগ্য বেঞ্চ";
bn["Primal Kettlebells"] = "প্রাইমাল কেটলবেলস";
bn["Wood Gymnastics Rings"] = "কাঠ জিমন্যাস্টিকস রিং";
bn["Weight Training Slam Ball"] = "ওজন প্রশিক্ষণ স্ল্যাম বল";
bn["Bluetooth Rower Rowing Machine"] = "ব্লুটুথ রোয়ার রোয়িং মেশিন";
bn["Runfast Pro Weighted Vest"] = "রানফাস্ট প্রো ওয়েটেড ভেস্ট";
bn["Platform"] = "প্ল্যাটফর্ম";
bn["Flat Utility Bench"] = "ফ্ল্যাট ইউটিলিটি বেঞ্চ";
bn["Performance Foam Roller"] = "কর্মক্ষমতা ফেনা রোলার";
bn["Smart Spin Bike"] = "স্মার্ট স্পিন বাইক";
bn["Skipping Rope"] = "স্কিপিং করার দড়ি";
bn["VeloCore Bike"] = "ভেলোকোর বাইক";
bn["Indoor Cycling Exercise Bike"] = "ইনডোর সাইক্লিং এক্সারসাইজ বাইক";
bn["Multi-Function Power Tower8"] = "মাল্টি-ফাংশন পাওয়ার টাওয়ার ৮";
bn["10XLT Super Max Power Cage"] = "১০ এক্সএলটি সুপার ম্যাক্স পাওয়ার কেজ";
bn["Braided Xertube"] = "ব্রেইডেড এক্সারটিউব";
bn["Adjustable Dumbbells"] = "সামঞ্জস্যযোগ্য ডাম্বেল";
bn["FIT 55in Trampoline Hexagon"] = "ফিট ৫৫ ইন ট্রামপোলিন ষড়ভুজ";
bn["Fight Camp"] = "ফাইট ক্যাম্প";
bn["Heavy Bag"] = "ভারী ব্যাগ";
bn["Lab Series Exercise and Fitness Medicine Balls"] =
  "ল্যাব সিরিজ ব্যায়াম এবং ফিটনেস মেডিসিন বল";
bn["Cerakote Cast Iron Kettlebell (in Pounds)"] =
  "সিরাকোট কাস্ট আয়রন কেটলবেল (পাউন্ড)";
bn["Interlocking Gym Floor Tiles"] = "ইন্টারলকিং জিম ফ্লোর টাইলস";
bn["Smart Treadmill"] = "স্মার্ট ট্রেডমিল";
bn["Stationary Bike"] = "স্টেশনারী বাইক";
bn["Multi-Grip Pull Up Bar"] = "মাল্টি-গ্রিপ পুল আপ বার";
bn["M3 Max Trainer"] = "এমপি থ্রি ম্যাক্স ট্রেইনার";
bn["PT-R Weight Kit 50LB"] = "পিট-আর ওয়েট কিট ৫০ এল বি";
bn["Resistance Bands"] = "রেজিস্ট্যান্স ব্যান্ড";
bn["Bluetooth Cardio Stair Stepper"] = "ব্লুটুথ কার্ডিও স্টেয়ার স্টেপার";
bn["Pull Up Bands"] = "পুল আপ ব্যান্ড";
bn["Recovery System"] = "রিকভারি সিস্টেম";
bn["Mirror"] = "আয়না";
bn["Wave Duo"] = "ওয়েভ ডুও";
bn["Sports"] = "খেলাধুলা";
bn["Crickets Bats"] = "ক্রিকেট ব্যাটস";
bn["Volleyballs"] = "ভলিবল";
bn["Footballs"] = "ফুটবল";
bn["Cycle"] = "সাইকেল";
bn["Basketballs"] = "বাস্কেটবল";
bn["Tennis Balls"] = "টেনিস বল";
bn["Boxing Gloves"] = "বক্সিং গ্লাভস";
bn["Hockey Sticks"] = "হকি স্টিকস";
bn["Badminton Racquet"] = "ব্যাডমিন্টন র‍্যাকেট";
bn["Football Helmet"] = "ফুটবল হেলমেট";
bn["Jock Strap"] = "জক স্ট্র্যাপ";
bn["Mouth Guards"] = "মাউথ গার্ডস";
bn["Shin Pads"] = "শিন প্যাড";
bn["Ski suits"] = "স্কাই স্যুট";
bn["Elbow Pads"] = "এলবো প্যাড";
bn["Shoulder Pads"] = "শোল্ডার প্যাড";
bn["Bicycle Helmet"] = "বাইসাইকেল হেলমেট";
bn["Other Hobby, Sport & Kids items"] =
  "অন্যান্য শখ, খেলাধুলা এবং শিশুদের আইটেম";
bn["Music, Books & Movies"] = "সঙ্গীত, বই এবং চলচ্চিত্র";
bn["Handicraft & Decoration"] = "হস্তশিল্প ও সজ্জা";
bn["Jute Bags"] = "পাটের ব্যাগ";
bn["Nakshi Katha"] = "নকশী কাথাঁ";
bn["Hand Made Bed Sheet"] = "হাতে তৈরি বিছানার চাদর";
bn["Hand Made Babies Troyes"] = "হাতে তৈরি বাচ্চাদের খেলনা";
bn["Handicrafts Decoratable Products"] = "হস্তশিল্প শোভাকর পণ্য";
bn["Leather"] = "চামড়া";
bn["Pottery"] = "মৃৎপাত্র";
bn["Woodwork"] = "কাঠের কাজ";
bn["Pashmina Shawls"] = "পশমিনা শাল";
bn["Shell"] = "শেল";
bn["Brass Handicrafts"] = "পিতলের হস্তশিল্প";
bn["Bamboo Handicrafts"] = "বাঁশের হস্তশিল্প";
bn["Phulkaris"] = "ফুলকারিস্";
bn["Zardozi"] = "জারদোজি";
bn["Sarees And Silk"] = "শাড়ি এবং সিল্ক";
bn["Carpet Weaving"] = "কার্পেট বিণ";
bn["Hand Made Door Hangings"] = "হ্যান্ড মেড ডোর হাঙ্গিংস্";
bn["Metal Craft Items"] = "মেটাল ক্রাফট আইটেম";
bn["Crops, Rice-Potato-Wheat-Corn"] = "শস্য-ধান-আলু-গম-ভুট্টা";
bn["Rice"] = "চাল বা ধান";
bn["Potato"] = "আলু";
bn["Wheat"] = "গম";
bn["Corn"] = "ভুট্টা";
bn["Barley"] = "যব";
bn["Green Pea"] = "সবুজ মটর";
bn["Soyabean"] = "সয়াবিন";
bn["Mustard"] = "সরিষা";
bn["Farming Tools & Machinery"] = "কৃষি সরঞ্জাম ও যন্ত্রপাতি";
bn["Tractor"] = "ট্রাক্টর";
bn["Combine or Harvester"] = "কম্বাইন অর হারভেস্টার";
bn["Plows"] = "লাঙ্গল";
bn["Harrows"] = "হ্যারোস";
bn["Fertilizer Spreaders"] = "ফার্টিলাইজার স্প্রেডার্স";
bn["Seeders"] = "সিডার";
bn["Balers"] = "বেলার্স";
bn["Rippers"] = "রিপারস";
bn["Crop Sprayer"] = "ক্রপ স্প্রেয়ার";
bn["Graders"] = "গ্রেডার্স";
bn["Poultry & Dairy Farm"] = "পোল্ট্রি ও ডেইরি ফার্ম";
bn["Egg Incubator"] = "ডিম ইনকিউবেটর";
bn["Poultry Cage"] = "মুরগির খাঁচা";
bn["Poultry Feeder"] = "পোল্ট্রি ফিডার";
bn["Broiler Drinker"] = "ব্রয়লার ড্রিংকার";
bn["Plastic Fogger"] = "প্লাস্টিক ফগার";
bn["Poultry Sheds"] = "পোল্ট্রি শেড";
bn["Nipple Drinker"] = "নিপল ড্রিংকার";
bn["Poultry Exhaust Fan"] = "পোল্ট্রি এক্সজস্ট ফ্যান";
bn["Mini Egg Incubator"] = "মিনি ডিম ইনকিউবেটর";
bn["Poultry Chick Feeder Plate"] = "পোল্ট্রি চিক ফিডার প্লেট";
bn["Poultry Vaccinator"] = "পোল্ট্রি ভ্যাক্সিনেটর";
bn["Poultry Bislery Drinker"] = "পোল্ট্রি বিসলারি ড্রিংকার";
bn["Chaff Cutter"] = "তুষ কাটার";
bn["Fodder Grinder"] = "ফডার গ্রাইন্ডার";
bn["Milking Machine"] = "মিল্কিং মেশিন";
bn["Dal-Onion -Garlic-Ginger-Chili"] = "ডাল-পেঁয়াজ-রসুন-আদা-মরিচ";
bn["Deshi Lentils"] = "দেশি মসুর ডাল";
bn["Indian Lentils"] = "ইন্ডিয়ান মসুর ডাল";
bn["Chickpea (Sola Butt)"] = "ছোলা";
bn["Khesari"] = "খেসারি";
bn["Anchor"] = "অ্যাংকর";
bn["Maskolai"] = "মাসকলাই";
bn["Deshi Onion"] = "দেশি পেঁয়াজ";
bn["Indian Onion"] = "ইন্ডিয়ান পেঁয়াজ";
bn["Deshi Garlic"] = "দেশি রসুন";
bn["Indian Garlic"] = "ইন্ডিয়ান রসুন";
bn["Deshi Ginger"] = "দেশি আদা";
bn["Indian Ginger"] = "ইন্ডিয়ান আদা";
bn["Green Chili"] = "সবুজ মরিচ";
bn["Red Chili"] = "লাল মরিচ";
bn["Fish-Shrimp"] = "মাছ-চিংড়ি";
bn["Hilsa Fish"] = "ইলিশ মাছ";
bn["Tuna Fish"] = "টুনা মাছ";
bn["Salmon Fish"] = "সালমন মাছ";
bn["Carp Fish"] = "রুই মাছ";
bn["Moon Fish"] = "রূপচাঁদা মাছ";
bn["Climbing fish"] = "ক্লাইম্বিং মাছ";
bn["Lobster"] = "গলদা চিংড়ি";
bn["Shrimp"] = "চিংড়ি";
bn["Feed & Seed"] = "খাদ্য ও বীজ";
bn["Broiler Feed"] = "ব্রয়লার ফিড";
bn["Layer Feed"] = "লেয়ার ফিড";
bn["Fish Feed"] = "ফিশ ফিড";
bn["Cattle Feed"] = "গবাদি পশুর খাদ্য";
bn["Chia Seed"] = "চিয়া বীজ";
bn["FlaxSeed"] = "তিশি বীজ";
bn["Rajgira Seed"] = "রাজগিরা বীজ";
bn["Sunflower Seed"] = "সূর্যমুখী বীজ";
bn["Pumpkin Seed"] = "কুমড়া বীজ";
bn["Basil Seed"] = "তুলসীর বীজ";
bn["Hemp Seed"] = "শণ বীজ";
bn["Pomegranate Seed"] = "ডালিম বীজ";
bn["Sesame Seed"] = "তিল বীজ";
bn["Grape Seed"] = "আঙ্গুর বীজ";
bn["Caraway Seed"] = "কেওড়া বীজ";
bn["Cattle"] = "গবাদি পশু";
bn["Other Agriculture Goods"] = "অন্যান্য কৃষি পণ্য";
bn["Medical Equipment & Supplies"] = "চিকিৎসা সরঞ্জাম ও সরবরাহ";
bn["X-Ray Machine"] = "এক্স-রে মেশিন";
bn["City Scan Machine"] = "সিটি স্ক্যান মেশিন";
bn["Colonoscopy Machine"] = "ক্লোনোস্কোপি মেশিন";
bn["O.T Materials"] = "ও.টি. মেটেরিয়্যালস্‌";
bn["Oxygen Cylinder"] = "অক্সিজেন সিলিন্ডার";
bn["Wheelchair"] = "হুইলচেয়ার";
bn["Stretcher"] = "স্ট্রেচার";
bn["Stethoscope"] = "স্টেথোস্কোপ";
bn["Surgery Knife"] = "সার্জারি ছুরি";
bn["Crutches"] = "ক্রাচ";
bn["Test Tube"] = "টেস্ট টিউব";
bn["Lab Beaker"] = "ল্যাব বিকার";
bn["Bandage Scissors"] = "ব্যান্ডেজ কাঁচি";
bn["Microscope"] = "মাইক্রোস্কোপ";
bn["Surgical Mask"] = "সার্জিক্যাল মাস্ক";
bn["Surgical Gloves"] = "সার্জিক্যাল গ্লাভস";
bn["Thermometer"] = "থার্মোমিটার";
bn["Blood Pressure Kit"] = "ব্ল্যাড প্রেসার কিট";
bn["Industry Machinery & Tools"] = "শিল্প যন্ত্রপাতি ও সরঞ্জাম";
bn["Office Supplies & Stationary"] = "অফিস সাপ্লাইস এবং স্টেশনারী";
bn["Licenses, Titles & Tenders"] = "লাইসেন্স, শিরোনাম এবং দরপত্র";
bn["Raw Materials & Industrial Supplies"] = "কাঁচামাল ও শিল্প সরবরাহ";
bn["Safety & Security"] = "নিরাপত্তা ও সিকিউরিটি";
bn["Jute, Plastic & Rubber Products"] = "পাট, প্লাস্টিক ও রাবার পণ্য";
bn["Jute Bag"] = "জুট ব্যাগ";
bn["Jute Carpet"] = "জুট কার্পেট";
bn["Jute Handicraft"] = "জুট হস্তশিল্প";
bn["Baby Plastic Toys"] = "বাচ্চাদের প্লাস্টিক খেলনা";
bn["Household Plastic Products"] = "হাউসহোল্ড প্লাস্টিক পণ্য";
bn["Latex house paint"] = "ল্যাটেক্স হাউস পেইন্ট";
bn["Cooking spatulas"] = "কুকিং স্প্যাটুলা";
bn["Carpet pads"] = "কার্পেট প্যাডস্";
bn["Shower mats"] = "শাওয়ার ম্যাটস্‌";
bn["Dishwashing gloves"] = "ডিশ ওয়াশিং গ্লাভস";
bn["Jar seals"] = "জার সিল";
bn["Floor mats"] = "ফ্লোর ম্যাটস্‌";
bn["Rubberized fabrics"] = "রাব্বারাইজড ফ্রেবিকস্‌";
bn["Other Business & Factory Items"] = "অন্যান্য ব্যবসা এবং কারখানার আইটেম";
bn["Tuition"] = "টিউশন";
bn["Textbooks"] = "পাঠ্যপুস্তক";
bn["Textbooks Type"] = "টেক্সটবুকের ধরণ";
bn["Select your textbook type"] = "আপনার টেক্সটবুকের ধরণ নির্বাচন করুন";
bn["School"] = "স্কুল";
bn["College"] = "কলেজ";
bn["University"] = "বিশ্ববিদ্যালয়";
bn["English Medium School"] = "ইংলিশ মিডিয়াম স্কুল";
bn["Technical Board"] = "টেকনিকাল বোর্ড";
bn["Madrasha Board"] = "মাদ্রাসা বোর্ড";
bn["Courses"] = "কোর্স";
bn["Course Type"] = "কোর্সের ধরণ";
bn["Select your course type"] = "আপনার কোর্সের ধরণ নির্বাচন করুন";
bn["Spoken English Course"] = "ইংরেজি স্পোকেন কোর্স";
bn["Computer Training"] = "কম্পিউটার প্রশিক্ষণ";
bn["Motivational Training"] = "অনুপ্রেরণামূলক প্রশিক্ষন";
bn["Entrepreneur Development Training"] = "উদ্যোক্তা উন্নয়ন প্রশিক্ষণ";
bn["Chef Training"] = "শেফ প্রশিক্ষণ";
bn["Hotel Management Training"] = "হোটেল ম্যানেজমেন্ট ট্রেনিং";
bn["Other Education"] = "অন্যান্য শিক্ষা";
bn["Study Abroad"] = "বিদেশে শিক্ষা";
bn["Language"] = "ভাষা";
bn["IT Training"] = "আইটি প্রশিক্ষণ";
bn["IT Training Type Training Fee (Instead of Price)"] =
  "আইটি ট্রেনিং টাইপ ট্রেনিং ফি (মূল্যের পরিবর্তে)";
bn["Type Of Admission Fee (Instead of Price)"] =
  "ভর্তি ফি প্রকার (মূল্যের পরিবর্তে)";
bn["IT Training Type"] = "আইটি প্রশিক্ষণের ধরণ";
bn["Select your IT training type"] = "আপনার আইটি প্রশিক্ষণের ধরণ নির্বাচন করুন";
bn["Fundamental Computer Training"] = "মৌলিক কম্পিউটার প্রশিক্ষণ";
bn["Ms Office (Word, Excel, PowerPoint)"] =
  "এমএস অফিস (ওয়ার্ড, এক্সেল, পাওয়ার পয়েন্ট)";
bn["Graphics Design (Adobe Photoshop, Illustrator)"] =
  "গ্রাফিক্স ডিজাইন (অ্যাডোব ফটোশপ, ইলাস্ট্রেটর)";
bn["SEO"] = "এসইও";
bn["Digital Marketing"] = "ডিজিটাল মার্কেটিং";
bn["Social Media Marketing"] = "সোশ্যাল মিডিয়া মার্কেটিং";
bn["Affiliate Marketing"] = "অ্যাফিলিয়েট মার্কেটিং";
bn["Online Freelancing"] = "অনলাইন ফ্রিল্যান্সিং";
bn["Others IT Course"] = "অন্যান্য আইটি কোর্স";
bn["School, College & University Admission Advertisement"] =
  "স্কুল, কলেজ ও বিশ্ববিদ্যালয়ে ভর্তির বিজ্ঞাপন";
bn["Type of Admission"] = "ভর্তির ধরণ";
bn["Select your admission type"] = "আপনার ভর্তির ধরণ নির্বাচন করুন";
bn["Technical Education Institute"] = "কারিগরি শিক্ষা প্রতিষ্ঠান";
bn["Vocational Institute"] = "ভোকেশনাল প্রতিষ্ঠান";
bn["Polytechnic Institute"] = "পলিটেকনিক প্রতিষ্ঠান";
bn["LP Gas & Cylinder"] = "এলপি গ্যাস ও সিলিন্ডার";
bn["LP Gas Brand Name"] = "এলপি গ্যাস ব্র্যান্ডের নাম";
bn["Select your LP Gas brand name"] =
  "আপনার এলপি গ্যাস ব্র্যান্ডের নাম নির্বাচন করুন";
bn["Bashundhara LPGas"] = "বসুন্ধরা এলপি গ্যাস";
bn["Omera LPGas"] = "ওমেরা এলপি গ্যাস";
bn["Jamuna LPGas"] = "যমুনা এলপি গ্যাস";
bn["Green LP Gas"] = "গ্রীন এলপি গ্যাস";
bn["BM LPGas"] = "বিএম এলপি গ্যাস";
bn["Petromax LPG"] = "পেট্রোম্যাক্স এলপিজি";
bn["Total LPGas"] = "টোটাল এলপি গ্যাস";
bn["LAUGFS LPGas"] = "লাফস এলপি গ্যাস";
bn["G-Gas"] = "জি-গ্যাস";
bn["Navana LPGas"] = "নাভানা এলপি গ্যাস";
bn["Orion LPGas"] = "ওরিয়ন এলপি গ্যাস";
bn["Sena LPGas"] = "সেনা এলপি গ্যাস";
bn["BEXIMCO LPGas"] = "বেক্সিমকো এলপি গ্যাস";
bn["JMI LPG"] = "জেএমআই এলপিজি";
bn["Promita LPGas"] = "প্রমিতা এলপি গ্যাস";
bn["Fresh LPGas"] = "ফ্রেশ এলপি গ্যাস";
bn["Uni LPGas"] = "ইউনি এলপি গ্যাস";
bn["Universal LPGas"] = "ইউনিভার্সাল এলপি গ্যাস";
bn["Euro LPGas"] = "ইউরো এলপি গ্যাস";
bn["Delta LPGas"] = "ডেল্টা এলপি গ্যাস";
bn["Bengal LPGas"] = "বেঙ্গল এলপি গ্যাস";
bn["Five Ring LP Gas (Dubai Bangla LPG)"] =
  "ফাইভ রিং এলপি গ্যাস (দুবাই বাংলা এলপিজি)";
bn["United LPG"] = "ইউনাইটেড এলপিজি";
bn["LP Gas Type"] = "এলপি গ্যাসের ধরণ";
bn["Select your LP Gas type"] = "আপনার এলপি গ্যাসের ধরণ নির্বাচন করুন";
bn["Package Cylinder"] = "প্যাকেজ সিলিন্ডার";
bn["Only Refill Cylinder"] = "শুধুমাত্র রিফিল সিলিন্ডার";
bn["Lubricants"] = "লুব্রিকেন্ট";
bn["Lubricant Brand Name"] = "লুব্রিকেন্ট ব্র্যান্ডের নাম";
bn["Select your lubricant brand name"] =
  "আপনার লুব্রিকেন্ট ব্র্যান্ডের নাম নির্বাচন করুন";
bn["Total"] = "টোটাল";
bn["Gulf"] = "গুলফ";
bn["BP-Super V"] = "বিপি-সুপার ভি";
bn["Castrol"] = "ক্যাস্ট্রল";
bn["Fuchs"] = "ফকশ";
bn["Chevron"] = "শেভ্রন";
bn["Mobil"] = "মবিল";
bn["BNO"] = "বিএনও";
bn["ENOC"] = "ইএনওসি";
bn["SERVO"] = "সার্ভো";
bn["MANNOL"] = "ম্যানোল";
bn["Omera"] = "ওমেরা";
bn["Kixx"] = "কিক্স";
bn["PTT"] = "পিটিটি";
bn["Gas Stove"] = "গ্যাসের চুলা";
bn["LPG Hose Pipe"] = "এলপিজি হোস পাইপ";
bn["LPG Regulator"] = "এলপিজি রেগুলেটর";
bn["Petrol & CNG Pump"] = "পেট্রোল এবং সিএনজি পাম্প";
bn["LPG Auto Gas Station"] = "এলপিজি অটো গ্যাস স্টেশন";
bn["Oxygen Gas & Cylinder"] = "অক্সিজেন গ্যাস ও সিলিন্ডার";
bn["Argon Gas & Cylinder"] = "আর্গন গ্যাস ও সিলিন্ডার";
bn["Plot & Land"] = "প্লট এবং জমি";
bn["Land Type"] = "জমির ধরণ";
bn["Select your land type"] = "আপনার জমির ধরণ নির্বাচন করুন";
bn["Residential"] = "আবাসিক";
bn["Commercial"] = "ব্যবসায়িক";
bn["Agricultural"] = "কৃষি";
bn["Land/ Plot size in square feet"] = "জমি/প্লটের আকার বর্গফুটে";
bn["Plot/ land Description"] = "প্লট/জমির বিবরণ";
bn["Select your unit type"] = "আপনার ইউনিট ধরণ নির্বাচন করুন";
bn["Unit Price"] = "ইউনিটের মূল্য";
bn["Apartments & Flats For Sale"] = "অ্যাপার্টমেন্ট এবং ফ্ল্যাট বিক্রয়ের জন্য";
bn["Bathrooms"] = "বাথরুম";
bn["Apartment Description"] = "অ্যাপার্টমেন্টের বিবরণ";
bn["Apartment Size in square feet"] = "অ্যাপার্টমেন্টের আকার বর্গফুটে";
bn["Agreement Ration on percentage"] = "শতাংশের অনুপাতের উপর চুক্তি";
bn["Houses For Sale"] = "বাড়ি বিক্রয়ের জন্য";
bn["Bedrooms"] = "বেডরুম";
bn["House Description"] = "হাউসের বিবরণ";
bn["House Size in square feet"] = "হাউসের আকার বর্গফুটে";
bn["Unit"] = "ইউনিট";
bn["Select your unit type"] = "আপনার ইউনিট ধরণ নির্বাচন করুন";
bn["SQFT"] = "বর্গফুট";
bn["Shotok"] = "শতক";
bn["Decimal"] = "ডেসিমাল";
bn["Katha"] = "কাঠা";
bn["Bigha"] = "বিঘা";
bn["Acres"] = "একর";
bn["Commercial Properties For Sale"] = "বিক্রয়ের জন্য বাণিজ্যিক সম্পত্তি";
bn["Property Type"] = "সম্পত্তির ধরণ";
bn["Size in Square feet"] = "আকার বর্গফুটে";
bn["Property Tools & Services"] = "সম্পত্তির সরঞ্জাম এবং সার্ভিস";
bn["Services Description"] = "সার্ভিসের বিবরণ";
bn["Property Tools & Services Type"] = "প্রপার্টি টুলস্‌ ও সার্ভিসের ধরণ";
bn["Select your services type"] = "আপনার সার্ভিসের ধরণ নির্বাচন করুন";
bn["Building Materials"] = "বিল্ডিং ম্যাটেরিয়ালস";
bn["Utility Services"] = "ইউটিলিটি সার্ভিস";
bn["Construction Services"] = "নির্মাণ সেবা";
bn["Packers and Movers"] = "প্যাকার এবং মুভার্স";
bn["Interior Design and Decoration"] = "ইন্টেরিয়র ডিজাইন এবং ডেকোরেশন";
bn["Pest Control & Cleaning"] = "পেস্ট কন্ট্রোল ও ক্লিনিং";
bn["Property Documentation"] = "প্রপার্টি ডকুমেন্টেশন";
bn["Building & Land Developers"] = "বিল্ডিং এবং ল্যান্ড ডেভেলপার";
bn["Land Description"] = "জমির বিবরণ";
bn["Pets"] = "পোষা প্রাণী";
bn["Type Of Animals"] = "পোষা প্রাণীর ধরণ";
bn["Select your animal type"] = "আপনার পোষা প্রাণীর ধরণ নির্বাচন করুন";
bn["Albatrosses"] = "অ্যালবাট্রস";
bn["Mouse"] = "ইঁদুর";
bn["Chickens"] = "মুরগি";
bn["Reptile"] = "সরীসৃপ";
bn["Hummingbirds"] = "হামিংবার্ড";
bn["Falcons"] = "বাজপাখি";
bn["Flamingoes"] = "ফ্লেমিঙ্গো";
bn["Ostriches"] = "উটপাখি";
bn["Owls"] = "পেঁচা";
bn["Parrots"] = "টিয়া পাখি";
bn["Cats"] = "বিড়াল";
bn["Guinea Pig"] = "গিনিপিগ";
bn["Dog"] = "কুকুর";
bn["Penguins"] = "পেঙ্গুইন";
bn["Pigeons"] = "পায়রা";
bn["Birds"] = "পাখি";
bn["Mammals"] = "স্তন্যপায়ী প্রাণী";
bn["Bats"] = "বাদুড়";
bn["Hamsters"] = "হ্যামস্টার";
bn["Whales"] = "তিমি";
bn["Aardvarks"] = "আরডভার্কস";
bn["Rabbits"] = "খরগোশ";
bn["Sharks"] = "হাঙ্গর";
bn["Seahorses"] = "সমুদ্রের ঘোড়া";
bn["Hagfish"] = "হ্যাগফিশ";
bn["Eels"] = "ঈল মাছ";
bn["Salmon"] = "স্যালমন মাছ";
bn["Rays"] = "রশ্মি";
bn["Lampreys"] = "ল্যাম্প্রেস";
bn["Farm Animals"] = "খামারের প্রাণী";
bn["Poultry"] = "পোল্ট্রি";
bn["Livestock"] = "পশুসম্পত্তি";
bn["Pet & Animal Accessories"] = "পোষা প্রাণী ও জীবজন্তুর এক্সেসরিজ";
bn["Accessories type"] = "এক্সেসরিজের ধরণ";
bn["Select your accessories type"] = "আপনার এক্সেসরিজের ধরণ নির্বাচন করুন";
bn["Toothbrush"] = "টুথব্রাশ";
bn["Nail Trimmer"] = "নখ ট্রিমার";
bn["Flea Preventative"] = "মাছি প্রতিরোধক";
bn["Stain and Odor Remover"] = "দাগ এবং গন্ধ অপসারণকারক";
bn["Food for a New Dog or Cat"] = "নতুন কুকুর এবং বিড়ালের জন্য খাবার";
bn["Water and Food Bowls"] = "জল এবং খাবার বাটি";
bn["Leash and Collar"] = "জামা এবং কলার";
bn["Pet Identification Tag and Microchip"] =
  "পোষা প্রাণী সনাক্তকরণ ট্যাগ এবং মাইক্রোচিপ";
bn["Pet First Aid Kit"] = "পোষা প্রাণীর প্রাথমিক চিকিৎসা কিট";
bn["Pet Bed"] = "পোষা প্রাণীর বিছানা";
bn["Treats and Toys"] = "ট্রিট এবং খেলনা";
bn["Pet Grooming Brush"] = "পোষা প্রাণীর গ্রুমিং ব্রাশ";
bn["Poop Disposal"] = "মলত্যাগ";
bn["Sterile gauze pads"] = "জীবাণুমুক্ত গজ প্যাড";
bn["Bandages"] = "ব্যান্ডেজ";
bn["Adhesive tap"] = "আঠালো টেপ";
bn["Antiseptic wipes"] = "এন্টিসেপটিক ওয়াইপস";
bn["Antibiotic ointment"] = "অ্যান্টিবায়োটিক মলম";
bn["Cotton balls"] = "সুতোর বল";
bn["Hydrogen peroxide"] = "হাইড্রোজেন পারঅক্সাইড";
bn["Tweezers"] = "ট্যুইজার";
bn["Digital thermometer"] = "ডিজিটাল থার্মোমিটার";
bn["Optionally a muzzle"] = "অপশনালি একটি মুখবন্ধ";
bn["Other Pets & Animals"] = "অন্যান্য পোষা প্রাণী ও জীবজন্তু";
bn["American Food"] = "আমেরিকান খাবার";
bn["Type Of Food"] = "খাবারের ধরণ";
bn["Select your food type"] = "আপনার খাবারের ধরণ নির্বাচন করুন";
bn["Key Lime Pie"] = "কী লাইম পাই";
bn["Tater Tots"] = "টেটার টুটস্";
bn["San Francisco Sourdough Bread"] = "সান ফ্রান্সিসকো সাওয়ারডো রুটি";
bn["Cobb Salad"] = "কোব সালাদ";
bn["Pot Roast"] = "পট রোস্ট";
bn["Twinkies"] = "টুইঙ্কিজ";
bn["Jerky"] = "জার্কি";
bn["Fajitas"] = "ফজিটাস";
bn["Banana Split"] = "কলার স্প্লিট";
bn["Cornbread"] = "কর্নব্রেড";
bn["GORP"] = "গর্প";
bn["Jambalaya"] = "জাম্বলায়";
bn["Biscuits ‘n’ Gravy"] = "বিস্কুট 'এন' গ্রেভি";
bn["Smithfield Ham"] = "স্মিথফিল্ড হ্যাম";
bn["Chicken Fried Steak"] = "চিকেন ফ্রাইড স্টেক";
bn["Wild Alaska Salmon"] = "ওয়াইল্ড আলাস্কা সালমন";
bn["California Roll"] = "ক্যালিফোর্নিয়া রোল";
bn["Meatloaf"] = "মিটলোফ";
bn["Grits"] = "গ্রিটস";
bn["Macaroni and Cheese"] = "ম্যাকারনি এবং পনির";
bn["Maryland Crab cakes"] = "মেরিল্যান্ড ক্র্যাব কেক";
bn["Potato Chips"] = "আলুর চিপস";
bn["Cioppino"] = "সিওপিনো";
bn["Fortune Cookies"] = "ফরচুন কুকিজ";
bn["Peanut Butter Sandwich"] = "পিনাট বাটার স্যান্ডউইচ";
bn["Baked Beans"] = "শিম সেদ্ধ";
bn["Popcorn"] = "ভুট্টার খই";
bn["Fried Chicken and Waffles"] = "ফ্রাইড চিকেন এন্ড ওফলেস";
bn["New England clam chowder"] = "নিউ ইংল্যান্ড ক্ল্যাম চাউডার";
bn["New Mexican Flat Enchiladas"] = "নিউ মেক্সিকান ফ্ল্যাট এনচিলাডাস";
bn["S’mores"] = "স’মোরেস";
bn["Lobster Rolls"] = "লবস্টার রোলস";
bn["Buffalo wings"] = "বাফালো উইংস";
bn["Barbecue Ribs"] = "বারবিকিউ রিব্স";
bn["BLT"] = "বিএলটি";
bn["Apple Pie"] = "আপেল পাই";
bn["Frito Pie"] = "ফ্রিটো পাই";
bn["Po’boy"] = "পো'বয়";
bn["Green Chile Stew"] = "গ্রীন চিলি স্টিউ";
bn["Chocolate-chip cookies"] = "চকোলেট চিপ কুকি";
bn["Blueberry cobber"] = "ব্লুবেরি মুচি";
bn["Delmonico’s steak"] = "ডেলমোনিকোর স্টেক";
bn["Chicago-style Pizza"] = "শিকাগো-স্টাইলের পিৎজা";
bn["Nachos"] = "নাচোস";
bn["Philly cheese steak"] = "ফিলি পনির স্টেক";
bn["Hot Dogs"] = "হট ডগস";
bn["Reuben Sandwich"] = "রুবেন স্যান্ডউইচ";
bn["Cheeseburger"] = "চিজবার্গার";
bn["Thanksgiving dinner"] = "থ্যাংক্সগিভিং ডিনার";
bn["American Food Description"] = "আমেরিকান খাবারের বিবরণ";
bn["Bakery, Sweets & Cake"] = "বেকারি, মিষ্টি এবং কেক";
bn["Type Of Food Item"] = "খাবারের আইটেমের ধরণ";
bn["Select your food type"] = "আপনার খাবারের আইটেমের ধরণ নির্বাচন করুন";
bn["Bakery"] = "বেকারি";
bn["Sweets"] = "মিষ্টি";
bn["Cake"] = "কেক";
bn["Food Item Name"] = "খাবারের আইটেমের নাম";
bn["Select your food item"] = "আপনার খাবারের ধরণ নির্বাচন করুন";
bn["Bars"] = "বার";
bn["Breads"] = "রুটি";
bn["Bagels"] = "ব্যাগেলস";
bn["Buns"] = "বানস";
bn["Rolls"] = "রোলস";
bn["Biscuits"] = "বিস্কুট";
bn["Loaf Breads"] = "লোফ ব্রেডস";
bn["Cookies"] = "কুকিজ";
bn["Muffins"] = "মফিন";
bn["Doughnuts"] = "ডোনাটস";
bn["Danish"] = "ড্যানিশ";
bn["Sweet Rolls"] = "মিষ্টি রোলস";
bn["Cinnamon Rolls"] = "দারুচিনি রোলস";
bn["Tortillas"] = "টর্টিলাস";
bn["Rose Cupcakes"] = "রোজ কাপকেক";
bn["Orange-almond cake"] = "অ়রেঞ্জ-আমন্ড কেক";
bn["Braided Easter bread"] = "ব্রাইডেড ইস্টার ব্রেড";
bn["Irish coffee macarons"] = "আইরিশ কফি ম্যাকারন";
bn["Flourless peanut butter chocolate chip cookies"] =
  "ময়দাবিহীন চিনাবাদাম মাখন চকোলেট চিপ কুকিজ";
bn["Quick apple cake"] = "কুইক আপেল কেক";
bn["Simple lemon poppy seed muffins"] = "সিম্পল লেমন পপি সীড মফিন";
bn["Classic German cheesecake"] = "ক্লাসিক জার্মান চিজকেক";
bn["Flourless chocolate cake"] = "ময়দাবিহীন চকোলেট কেক";
bn["Apricot clafoutis"] = "এপ্রিকট ক্লাফাউটিস";
bn["Marbled coffee cake"] = "মার্বেল কফি কেক";
bn["Apple crumble in a glass"] = "আপেল ত্রুমবলে ইন আ গ্লাস";
bn["Carrot cake with cream cheese frosting"] =
  "ক্রিম পনির ফ্রস্টিং সঙ্গে গাজর কেক";
bn["Cinnamon-apple muffins with streusel"] = "স্ট্রুসেল সহ দারুচিনি-আপেল মাফিন";
bn["Raspberry molten chocolate cupcakes"] = "রাস্পবেরি গলিত চকোলেট কাপকেক";
bn["Blueberry muffins"] = "ব্লুবেরি মাফিন";
bn["Fudgy Brownies"] = "ফাজি ব্রাউনিজ";
bn["Chewy chocolate chip cookies"] = "চিবানো চকোলেট চিপ কুকিজ";
bn["Fluffy plum streusel cake"] = "ফ্লফি প্লাম স্ট্রুসেল কেক";
bn["Butter Cake"] = "বাটার কেক";
bn["Pound Cake"] = "পাউন্ড কেক";
bn["Sponge Cake"] = "স্পঞ্জ কেক";
bn["Genoise Cake"] = "জেনোইস কেক";
bn["Biscuit Cake"] = "বিস্কুট কেক";
bn["Angel Food Cake"] = "অ্যাঞ্জেল ফুড কেক";
bn["Chiffon Cake"] = "শিফন কেক";
bn["Baked Flourless Cake"] = "বেকড ময়দাবিহীন কেক";
bn["Unbaked Flourless Cake"] = "আনবেকড ময়দাবিহীন কেক";
bn["Carrot Cake"] = "গাজর কেক";
bn["Red Velvet Cake"] = "রেড ভেলভেট কেক";
bn["Yellow Butter Cake"] = "ইয়েলো বাটার কেক";
bn["Upside-Down Cake"] = "আপসাইড-ডাউন কেক";
bn["Devil’s Food Cake"] = "ডেভিলস ফুড কেক";
bn["Hummingbird Cake"] = "হামিংবার্ড কেক";
bn["Opera Cake"] = "অপেরা কেক";
bn["Lady Baltimore Cake"] = "লেডি বাল্টিমোর কেক";
bn["Fruit Cake"] = "ফ্রুট কেক";
bn["Banana Cake"] = "কলা কেক";
bn["Lemon Cake"] = "লেমন কেক";
bn["Citrus Coconut Cake"] = "সাইট্রাস নারকেল কেক";
bn["Vanilla Cake"] = "ভ্যানিলা কেক";
bn["Amriti"] = "অমৃতি";
bn["Bundiya"] = "বুন্দিয়া";
bn["Chanar Goja"] = "ছানার গজা";
bn["Chanar Jilapi"] = "ছানার জিলাপি";
bn["Chanar Kheer"] = "ছানার খির";
bn["Chandramukhi"] = "চন্দ্রমুখী";
bn["Chanar Payesh"] = "ছানার পায়েশ";
bn["Chomchom"] = "চমচম";
bn["Kalojam"] = "কালোজাম";
bn["Kheersagar"] = "খীরসাগর";
bn["Lyangcha"] = "লিয়াংচা";
bn["Malpua"] = "মালপুয়া";
bn["Rajbhog"] = "রাজভোগ";
bn["Rasabali"] = "রসবালি";
bn["Roshogolla"] = "রসগোল্লা";
bn["Sandesh"] = "সন্দেশ";
bn["Shahi Jilapi"] = "শাহী জিলাপি";
bn["Tusha Shinni"] = "তুষা শিন্নি";
bn["Sitabhog"] = "সীতাভোগ";
bn["Shor Bhaja"] = "শর ভাজা";
bn["Pantua"] = "পান্তুয়া";
bn["Kacha Golla"] = "কাঁচা গোল্লা";
bn["Bakery, Sweets & Cake Description"] = "বেকারি, মিষ্টি এবং কেকের বিবরণ";
bn["Burger"] = "বার্গার";
bn["Type Of Burger"] = "বার্গারের ধরণ";
bn["Select your food type"] = "আপনার বার্গারের ধরণ নির্বাচন করুন";
bn["Beef Burgers"] = "বীফ বার্গার";
bn["Elk Burgers"] = "এলক বার্গার";
bn["Portobello Mushroom Burgers"] = "পোর্টোবেলো মাশরুম বার্গার";
bn["Turkey Burgers"] = "টার্কি বার্গার";
bn["Veggie Burgers"] = "ভেজি বার্গার";
bn["Bison Burgers"] = "বাইসন বার্গার";
bn["Wild Salmon Burgers"] = "ওয়াইল্ড স্যালমন বার্গার";
bn["Black Bean Burgers"] = "ব্ল্যাক বিন বার্গার";
bn["Chilli Burger with Pepper Relish"] = "মরিচের স্বাদের সাথে মরিচ বার্গার";
bn["Perfect Lamb Satay Burger"] = "পারফেক্ট ল্যাম্ব সাটে বার্গার";
bn["Lamb and Tomato Stuffed Burger"] = "ল্যাম্ব এবং টমেটো স্টাফড বার্গার";
bn["Crunchy Chicken and Fish Burger"] = "ক্রাঞ্চি চিকেন এবং ফিশ বার্গার";
bn["Chicken Feta Cheese Burger with Potato Salad"] =
  "আলু সালাদ সহ চিকেন ফেটা চিজ বার্গার";
bn["Lentil and Mushroom Burger"] = "মসুর ডাল এবং মাশরুম বার্গার";
bn["Stuffed Bean Burger"] = "স্টাফড বিন বার্গার";
bn["Lamb Burger with Radish Slaw"] = "রেডিশ স্ল উইথ ল্যাম্ব বার্গার";
bn["Potato Corn Burger"] = "আলু কর্ন বার্গার";
bn["Supreme Veggie Burger"] = "সুপ্রিম ভেজি বার্গার";
bn["Butter Chicken twin Burgers"] = "বাটার চিকেন টুইন বার্গার";
bn["Rajma Patty Burger"] = "রাজমা প্যাটি বার্গার";
bn["Pizza Burger"] = "পিৎজা বার্গার";
bn["Millionaire Burger"] = "মিলিয়নেয়ার বার্গার";
bn["Smash Burger"] = "স্ম্যাশ বার্গার";
bn["Classic Cheese Burger"] = "ক্লাসিক পনির বার্গার";
bn["Shroom Burger"] = "শ্রুম বার্গার";
bn["Beef Cheese Burger"] = "বিফ চিজ বার্গার";
bn["Burger Description"] = "বার্গারের বিবরণ";
bn["Chinese Food"] = "চাইনিজ খাবার";
bn["Type Of Chinese Food"] = "চাইনিজ খাবারের ধরণ";
bn["Select your chinese food type"] = "আপনার চাইনিজ খাবারের ধরণ নির্বাচন করুন";
bn["Peking Roasted Duck"] = "পিকিং রোস্টেড ডাক";
bn["Kung Pao Chicken"] = "কুং পাও মুরগি";
bn["Sweet and Sour Pork"] = "মিষ্টি এবং টক শুকরের মাংস";
bn["Dim Sum"] = "ডিম সাম";
bn["Chinese Dumplings"] = "চাইনিজ ডাম্পলিংস";
bn["Ma Po Tofu"] = "মা পো তোফু";
bn["Char Siu"] = "চর সিউ";
bn["Chow Mein"] = "চাউ মেইন";
bn["Fried Rice"] = "ফ্রাইড রাইস";
bn["Twice-Cooked Pork Slices"] = "দুইবার রান্না করা পোর্ক স্লাইস";
bn["Sichuan Pork"] = "সিচুয়ান পোর্ক";
bn["Xiaolongbao"] = "সিয়াওলংবাও";
bn["Zhajiangmian"] = "জহাজিয়াংমিং";
bn["Wonton Soup"] = "অন্থন স্যুপ";
bn["Spring Rolls"] = "স্প্রিং রোলস";
bn["Yangzhou Fried Rice"] = "ইয়াংজু ফ্রাইড রাইস";
bn["Cantonese Fried Rice"] = "ক্যান্টোনিজ ফ্রাইড রাইস";
bn["Fujian Fried Rice"] = "ফুজিয়ান ফ্রাইড রাইস";
bn["Hot Pot"] = "হট পট";
bn["Stinky Tofu"] = "স্টিনকি টোফু";
bn["Congee"] = "কনজি";
bn["Chinese Hamburger"] = "চাইনিজ হ্যামবার্গার";
bn["Scallion Pancakes"] = "স্ক্যালিয়ন প্যানকেকস";
bn["Kung Pao Chicken"] = "কুং পাও মুরগি";
bn["Baozi"] = "বাওজি";
bn["Soup Dumplings"] = "স্যুপ ডাম্পলিংস";
bn["Chinese Sticky Rice"] = "চাইনিজ স্টিকি রাইস";
bn["Hainanese Chicken Rice"] = "হাইনানিজ চিকেন রাইস";
bn["Seasoned Steamed Eggplant"] = "সিজন্ড স্টিমড বেগুন";
bn["Jiaozi"] = "জিয়াওজি";
bn["Chinese Food Description"] = "চাইনিজ খাবারের বিবরণ";
bn["Dessert"] = "মিষ্টান্ন";
bn["Type Of Dessert"] = "মিষ্টান্নের ধরণ";
bn["Select your dessert food type"] =
  "আপনার মিষ্টান্ন খাবারের ধরণ নির্বাচন করুন";
bn["Cream Brulee"] = "ক্রিম ব্রুলি";
bn["Mochi"] = "মোচি";
bn["Apple Pie"] = "আপেল পাই";
bn["Nanaimo Bar"] = "নানাইমো বার";
bn["Gulab Jamun"] = "গুলাব জামুন";
bn["Pakhlava"] = "পাখলাভা";
bn["Kardinalschnitten"] = "কার্ডিনালস্নিটেন";
bn["Dadar Gulung"] = "দাদার গুলুং";
bn["Poffertjes"] = "পোফার্টজেস";
bn["Kremes"] = "ক্রেমস";
bn["Yogurt Doi of Bogra"] = "বগুড়ার দই";
bn["Chanar Pulao of Jamalpur"] = "জামালপুরের ছানার পুলাও";
bn["Porabari ChomChom of Tangail"] = "টাঙ্গাইলের পোড়াবাড়ির চমচম";
bn["Chandramukhi of Brahmanbaria"] = "ব্রাহ্মণবাড়িয়ার চন্দ্রমুখী";
bn["Balish Misti of Netrokona"] = "নেত্রকোনার বালিশ মিস্টি";
bn["Rasamanjari of Gaibandha"] = "গাইবান্ধার রসমঞ্জরী";
bn["Rosomalai of Comilla"] = "কুমিল্লার রসমালাই";
bn["Kachagolla of Natore"] = "নাটোরের কাঁচাগোল্লা";
bn["Pera Sandesh of Naogaon"] = "নওগাঁর পেরা সন্দেশ";
bn["Sabitri 7 Roskodombo of Meherpur"] = "মেহেরপুরের সাবিত্রী ৭ রোসকোডম্বো";
bn["Dessert Description"] = "মিষ্টান্নের বিবরণ";
bn["Fast food"] = "ফাস্ট ফুড";
bn["Type Of Fast Food"] = "ফাস্ট ফুডের ধরণ";
bn["Select your fast food type"] = "আপনার ফাস্ট ফুডের ধরণ নির্বাচন করুন";
bn["Chicken Rolls"] = "চিকেন রোলস";
bn["Hot Dogs"] = "হট ডগস";
bn["Chicken Burger"] = "চিকেন বার্গার";
bn["Sandwich"] = "স্যান্ডউইচ";
bn["Waffle Fires"] = "ওয়াফেল ফ্রাই";
bn["French Fries"] = "ফ্রেঞ্চ ফ্রাই";
bn["Chicken Sandwich"] = "চিকেন স্যান্ডউইচ";
bn["Smash Burger"] = "স্ম্যাশ বার্গার";
bn["Shack Burger"] = "শ্যাক বার্গার";
bn["Vegetable Burger"] = "ভেজিটেবল বার্গার";
bn["Vegetable Rolls"] = "ভেজিটেবল রোলস";
bn["Chicken Rolls"] = "চিকেন রোলস";
bn["Beef Burger"] = "বিফ বার্গার";
bn["Beef Rolls"] = "বিফ রোলস";
bn["Egg Rolls"] = "ডিম রোলস";
bn["Chicken Tenders"] = "মুরগির ট্রেন্ডারস্";
bn["Biscuits"] = "বিস্কুট";
bn["Soft Tacos"] = "নরম টাকোস";
bn["Cajun Fries"] = "কাজুন ফ্রাই";
bn["Double Shack Burger"] = "ডাবল শ্যাক বার্গার";
bn["Baked Apple Pie"] = "বেকড আপেল পাই";
bn["Hash Browns"] = "হ্যাশ ব্রাউনস";
bn["Chicken Biscuit"] = "চিকেন বিস্কুট";
bn["Steak Burgers"] = "স্টেক বার্গার";
bn["Chicken Steak"] = "চিকেন স্টেক";
bn["Beef Steak"] = "বিফ স্টেক";
bn["Egg Mcmuffin"] = "ডিম ম্যাকমাফিন";
bn["Roast Beef Sandwich"] = "রোস্ট বিফ স্যান্ডউইচ";
bn["Chicken Fingers"] = "চিকেন ফিঙ্গারস্‌";
bn["Cheese Burger"] = "চিজ বার্গার";
bn["Chicken Quesadilla"] = "চিকেন কুয়েসাডিলা";
bn["Honey BBQ chicken Sandwich"] = "মধু বারবিকিউ চিকেন স্যান্ডউইচ";
bn["Cherry Limeade"] = "চেরি লাইমেড";
bn["Soft Cookies"] = "নরম কুকিজ";
bn["Glazed Doughnut"] = "চকচকে ডোনাট";
bn["Filet-O-Fish"] = "ফিলেট-ও-মাছ";
bn["Fried Fish"] = "ভাজা মাছ";
bn["Fried Rice"] = "ফ্রাইড রাইস";
bn["Fast Food Description"] = "ফাস্ট ফুডের বিবরণ";
bn["Healthy food"] = "স্বাস্থ্যকর খাবার";
bn["Type Of Healthy Food"] = "স্বাস্থ্যকর খাবারের ধরন";
bn["Select your healthy food type"] =
  "আপনার স্বাস্থ্যকর খাবারের ধরন নির্বাচন করুন";
bn["Water"] = "পানি";
bn["Dark Green Vegetables"] = "ডার্ক সবুজ শাকসবজি";
bn["Whole Grains"] = "শস্যদানা";
bn["Beans and Lentils"] = "মটরশুটি এবং মসুর ডাল";
bn["Fish"] = "মাছ";
bn["Berries"] = "বেরিস্";
bn["Winter Squash"] = "শীতকালীন স্কোয়াশ";
bn["Soy"] = "সয়া";
bn["Flaxseed, Nuts and Seeds"] = "তিশি, বাদাম এবং বীজ";
bn["Organic Yogurt"] = "অ়রগানিক দই";
bn["Healthy Food Description"] = "স্বাস্থ্যকর খাবারের বিবরণ";
bn["Indian Food"] = "ভারতীয় খাবার";
bn["Type Of Indian Food"] = "ভারতীয় খাবারের ধরণ";
bn["Select your Indian food type"] = "আপনার ভারতীয় খাবারের ধরণ নির্বাচন করুন";
bn["Masala dosa"] = "মসলা দোসা";
bn["Dal makhani"] = "ডাল মাখানি";
bn["Indian Chaat"] = "ভারতীয় চাট";
bn["Vada pav"] = "ভারা পাভ";
bn["Dhokla"] = "ধোকলা";
bn["Barfi"] = "বরফি";
bn["Hyderabadi biryani"] = "হায়দ্রাবাদি বিরিয়ানি";
bn["Stuffed parata"] = "স্টাফড পরোটা";
bn["Pani puri"] = "পানি পুরি";
bn["Idli"] = "ইডলি";
bn["Indian Food Description"] = "ভারতীয় খাবারের বিবরণ";
bn["Food Description"] = "খাবারের বিবরণ";
bn["Italian Food"] = "ইতালীয় খাবার";
bn["Type Of Italian Food"] = "ইতালীয় খাবারের ধরণ";
bn["Select your Italian food type"] = "আপনার ইতালীয় খাবারের ধরণ নির্বাচন করুন";
bn["Lasagne"] = "লাসাগনে";
bn["Italian pizza"] = "ইতালিয়ান পিজ্জা";
bn["Pasta"] = "পাস্তা";
bn["Spaghetti"] = "স্প্যাগেটি";
bn["Mojito"] = "মোজিটো";
bn["Polenta"] = "পোলেন্টা";
bn["Italian Food Description"] = "ইতালীয় খাবারের বিবরণ";
bn["Japanese Food"] = "জাপানিজ খাবার";
bn["Type Of Japanese Food"] = "জাপানিজ খাবারের ধরণ";
bn["Select your japanese food type"] =
  "আপনার জাপানিজ খাবারের ধরণ নির্বাচন করুন";
bn["Sushi"] = "সুশি";
bn["Sashimi"] = "সাশিমি";
bn["Tofu"] = "টোফু";
bn["Beef Ramen"] = "বিফ রামেন";
bn["Miso Soup"] = "মিসো স্যুপ";
bn["Cheese cake"] = "চিজ কেক";
bn["Chicken teriyaki"] = "চিকেন তেরিয়াকি";
bn["Shrimp tempura"] = "চিংড়ি টেম্পুরা";
bn["Japanese Food Description"] = "জাপানিজ খাবারের বিবরণ";
bn["Korean Food"] = "কোরিয়ান খাবার";
bn["Type Of Korean Food"] = "কোরিয়ান খাবারের ধরণ";
bn["Select your Korean food type"] = "আপনার কোরিয়ান খাবারের ধরণ নির্বাচন করুন";
bn["Ramen noodles"] = "রমেন নুডলস";
bn["Kimchi"] = "কিমচি";
bn["Bibimbap"] = "বিবিমবাপ";
bn["Beef bulgogi"] = "বিফ বুলগোগি";
bn["Beef brisket"] = "গরুর সিনার মাংস";
bn["Chiken bulgogi"] = "চিকেন বুলগোগি";
bn["Assorted sushi"] = "হরেক রকমের সুশি";
bn["Tempura prawn"] = "টেম্পুরা চিংড়ি";
bn["Miso soup"] = "মিসো স্যুপ";
bn["Iced tea"] = "বরফ চা";
bn["Korean Food Description"] = "কোরিয়ান খাবারের বিবরণ";
bn["Mexican Food"] = "মেক্সিকান খাবার";
bn["Type Of Mexican Food"] = "মেক্সিকান খাবারের ধরণ";
bn["Select your Mexican food type"] =
  "আপনার মেক্সিকান খাবারের ধরণ নির্বাচন করুন";
bn["Beef Nachos"] = "বিফ নাচোস";
bn["Beef Strip Steak"] = "বিফ স্ট্রিপ স্টেক";
bn["Chicken Burrito"] = "চিকেন বুরিটো";
bn["Salsa"] = "সালসা";
bn["Tacos Al Carbon"] = "টাকোস আল কার্বন";
bn["Deep-Fried Ice Cream"] = "ডিপ-ফ্রাইড আইসক্রিম";
bn["Peach Melba"] = "পীচ মেলবা";
bn["Taco Salad"] = "ট্যাকো সালাদ";
bn["Mexican Food Description"] = "মেক্সিকান খাবারের বিবরণ";
bn["Middle Eastern Food"] = "মধ্যপ্রাচ্যের খাবার";
bn["Type Of Middle Eastern Food"] = "মধ্যপ্রাচ্যের খাবারের ধরণ";
bn["Select your middle eastern food type"] =
  "আপনার মধ্যপ্রাচ্যের খাবারের ধরণ নির্বাচন করুন";
bn["Arabian Kabsa"] = "আরবীয় কাবসা";
bn["Arabian Pasta"] = "আরবীয় পাস্তা";
bn["Arabic Pita Bread"] = "আরবি পিটা রুটি";
bn["Hummus"] = "হামাস";
bn["Laham Meshwi"] = "লাহাম মেশ্বি";
bn["Chicken Al-faham"] = "চিকেন আল-ফাহাম";
bn["Ruz Bukhari Sada"] = "রুজ বুখারী সাদা";
bn["Middle Eastern Food Description"] = "মধ্যপ্রাচ্যের খাবারের বিবরণ";
bn["Pizza"] = "পিজ্জা";
bn["Select your pizza type"] = "আপনার পিজ্জার ধরণ নির্বাচন করুন";
bn["Beef Pizza"] = "বিফ পিজ্জা";
bn["Chicken Pizza"] = "চিকেন পিজ্জা";
bn["Vegetable Pizza"] = "ভেজিটেবল পিজ্জা";
bn["Fish Pizza"] = "ফিশ পিজ্জা";
bn["Pizza Description"] = "পিজ্জার বিবরণ";
bn["Seafood"] = "সামুদ্রিক খাবার";
bn["Thai"] = "থাই";
bn["Vegetarian"] = "ভেজিটেরিয়ান";
bn["Western"] = "ওয়েস্টার্ন";
bn["Restaurant & Restora"] = "রেস্ট্রুরেন্ট ও রেস্তোরাঁ";
bn["Cafe & Tea stall"] = "ক্যাফে ও চা স্টল";
bn["Meal Box"] = "মিল বক্স";
bn["Doctor & Doctors Chamber"] = "ডাক্তার ও ডাক্তারের চেম্বার";
bn["E-mail"] = "ই-মেইল";
bn["Current Position"] = "বর্তমান অবস্থা";
bn["Occupation"] = "পেশা";
bn["Gender"] = "লিঙ্গ";
bn["Male"] = "পুরুষ";
bn["Female"] = "মহিলা";
bn["Experience"] = "অভিজ্ঞতা";
bn["Social Status"] = "সামাজিক মর্যাদা";
bn["Contribution For Country, People & Society"] =
  "দেশের মানুষ ও সমাজের জন্য অবদান";
bn["Advocate & Advocate Chamber"] = "অ্যাডভোকেট ও অ্যাডভোকেটের চেম্বার";
bn["Honorable Person"] = "সম্মানিত ব্যক্তি";
bn["Scholar Person"] = "পণ্ডিত ব্যক্তি";
bn["Actors, Model & Media Personality"] =
  "অভিনেতা, মডেল এবং মিডিয়ার ব্যক্তিত্ব";
bn["Singer, Dancer & Musician"] = "গায়ক, নৃত্যশিল্পী এবং সঙ্গীতশিল্পী";
bn["Social Worker"] = "সমাজকর্মী";
bn["Architect Designer"] = "আর্কিটেক্ট ডিজাইনার";
bn["Writer"] = "লেখক";
bn["Fashion Designer"] = "ফ্যাশন ডিজাইনার";
bn["Documentary & Video Maker"] = "ডকুমেন্টারি ও ভিডিও মেকার";
bn["Accounts & Auditor"] = "হিসাবরক্ষক ও নিরীক্ষক";
bn["Economist"] = "অর্থনীতিবিদ";
bn["Intellectual"] = "বুদ্ধিজীবী";
bn["Engineer"] = "প্রকৌশলী";
bn["Education"] = "শিক্ষা";
bn["Companies Product Branding"] = "কোম্পানির পণ্যের ব্র্যান্ডিং";
bn["Servicing & Repair"] = "সার্ভিসিং ও মেরামত";
bn["Service/Repair Type"] = "সার্ভিস/ রিপেয়ার ধরণ";
bn["Select your service type"] = "আপনার সার্ভিস/রিপেয়ার ধরণ নির্বাচন করুন";
bn["Mobile Servicing"] = "মোবাইল সার্ভিসিং";
bn["Computer Servicing"] = "কম্পিউটার সার্ভিসিং";
bn["TV Repair"] = "টিভি রিপেয়ার";
bn["Fridge Repair"] = "ফ্রিজ রিপেয়ার";
bn["AC Repair"] = "এসি রিপেয়ার";
bn["Fan Repair"] = "ফ্যান রিপেয়ার";
bn["Air Cooler Repair"] = "এয়ার কোলার রিপেয়ার";
bn["Washing Machine Repair"] = "ওয়াশিং মেশিন রিপেয়ার";
bn["Electric Oven Repair"] = "ইলেকট্রিক ওভেন রিপেয়ার";
bn["IT Services"] = "আইটি সার্ভিস";
bn["IT Services Type"] = "আইটি সার্ভিস ধরণ";
bn["Select your it service type"] = "আপনার আইটি সার্ভিস ধরণ নির্বাচন করুন";
bn["Internet Provider"] = "ইন্টারনেট প্রোভাইডার";
bn["Website / Domain Hosting"] = "ওয়েভসাইট / ডোমেইন হোস্টিং";
bn["Web Design"] = "ওয়েভ ডিজাইন";
bn["Web Development"] = "ওয়েভ ডেভেলপমেন্ট";
bn["Networking"] = "নেটওয়ার্কিং";
bn["Software/Apps"] = "সফটওয়্যার/ অ্যাপস্‌";
bn["Media & Event Management Services"] =
  "মিডিয়া এবং ইভেন্ট ম্যানেজমেন্ট সার্ভিসেস";
bn["Select your service type"] = "আপনার সার্ভিস ধরণ নির্বাচন করুন";
bn["Wedding Party"] = "বিবাহের পার্টি";
bn["Marriage Anniversary"] = "বিবাহ বার্ষিকী";
bn["Birthday Party"] = "জন্মদিনের পার্টি";
bn["Picnic"] = "পিকনিক";
bn["Company Anniversary"] = "কোম্পানির বার্ষিকী";
bn["Seminars"] = "সেমিনার";
bn["Conferences"] = "কনফারেন্সেস";
bn["Social & Cultural Events"] = "সামাজিক ও সাংস্কৃতিক অনুষ্ঠান";
bn["Public Campaign"] = "পাবলিক ক্যাম্পেইন";
bn["Promotional Events"] = "প্রমোশনাল ইভেন্টস";
bn["Tours & Travels"] = "ট্যুর ও ট্রাভেলস";
bn["Professional Services"] = "প্রফেশনাল সার্ভিস";
bn["Professional Sevice Type"] = "প্রফেশনাল সার্ভিস ধরণ";
bn["Fitness & Beauty Services"] = "ফিটনেস এবং সৌন্দর্য্যের পরিষেবা";
bn["Services Type"] = "সার্ভিসের ধরণ";
bn["Select your service type"] = "আপনার সার্ভিস ধরণ নির্বাচন করুন";
bn["Beauty Parlor"] = "বিউটি পার্লার";
bn["Grooming"] = "গ্রুমিং";
bn["Salon"] = "সেলুন";
bn["Yoga & Gym"] = "যোগব্যায়াম এবং জিম";
bn["Spa"] = "স্পা";
bn["Domestic & Daycare Services"] = "ডোমেস্টিক ও ডে কেয়ার সার্ভিস";
bn["Select your service type"] = "আপনার সার্ভিস ধরণ নির্বাচন করুন";
bn["Maid Service"] = "মেইড সার্ভিস";
bn["Care Giving Service"] = "কেয়ার গিভিং সার্ভিস";
bn["Daycare Service"] = "ডে কেয়ার সার্ভিস";
bn["Gardening Service"] = "গার্ডেনিং সার্ভিস";
bn["Driver on Demand"] = "ড্রাইভার অন ডিমান্ড";
bn["Matrimonial & Wedding Matter"] = "বৈবাহিক এবং বিবাহের বিষয়";
bn["Electrician"] = "ইলেকট্রিশিয়ান";
bn["Hospitality Service"] = "আতিথেয়তা সেবা";
bn["Tickets"] = "টিকিট";
bn["Tickets Type"] = "টিকিটের ধরণ";
bn["Select your tickets type"] = "আপনার টিকিটের ধরণ নির্বাচন করুন";
bn["International"] = "আন্তর্জাতিক";
bn["Domestic"] = "ডমেস্টিক";
bn["Tickets Description"] = "টিকিটের বিবরণ";
bn["Visa"] = "ভিসা";
bn["Type Of Visa"] = "ভিসার ধরণ";
bn["Select your visa type"] = "আপনার ভিসার ধরণ নির্বাচন করুন";
bn["Student Visa"] = "শিক্ষার্থী ভিসা";
bn["Business Visa"] = "ব্যবসায়িক ভিসা";
bn["Work Permit Visa"] = "ওয়ার্ক পারমিট ভিসা";
bn["Tourist Visa"] = "পর্যটন ভিসা";
bn["All Kinds of Visa"] = "সব ধরনের ভিসা";
bn["Visa Description"] = "ভিসার বিবরণ";
bn["Travel Agency"] = "ট্রাভেল এজেন্সি";
bn["Company/ Agency Name"] = "প্রতিষ্ঠান / এজেন্সি";
bn["Tourism"] = "পর্যটন";
bn["Type of Services"] = "সার্ভিসের ধরণ";
bn["Select your services type"] = "আপনার সার্ভিসের ধরণ নির্বাচন করুন";
bn["Hotel Booking Manage"] = "হোটেল বুকিং ম্যানেজ";
bn["Transport Manage"] = "পরিবহন ব্যবস্থাপনা";
bn["Tourist Guide"] = "ভ্রমন নির্দেশনাকারী";
bn["Breakfast, Lunch & Dinner Facilities Manage"] =
  "সকাল, দুপুর এবং রাতের খাবারের সুবিধাগুলির ব্যবস্থা";
bn["Services Description"] = "সার্ভিসের বিবরণ";
bn["Dokan"] = "দোকান";
bn["Dokan Name"] = "দোকানের নাম";
bn["Proprietor Name"] = "মালিকের নাম";
bn["Dokan  Description"] = "দোকানের বিবরণ";
bn["Dokan Location"] = "দোকানের ঠিকানা";
bn["Enterprise"] = "এন্টারপ্রাইজ";
bn["Enterprise Name"] = "এন্টারপ্রাইজের নাম";
bn["Enterprise Description"] = "এন্টারপ্রাইজের বিবরণ";
bn["Enterprise Location"] = "এন্টারপ্রাইজের ঠিকানা";
bn["Traders"] = "ব্যবসায়ীর";
bn["Traders Name"] = "ব্যবসায়ীর নাম";
bn["Traders Description"] = "ব্যবসার বিবরণ";
bn["Traders Location"] = "ব্যবসার ঠিকানা";
bn["Store"] = "স্টোর";
bn["Store Name"] = "স্টোরের নাম";
bn["Store Description"] = "স্টোরের বিবরণ";
bn["Store Location"] = "স্টোরের ঠিকানা";
bn["Business Type"] = "বিসনেস ধরণ";
bn["Business Description"] = "বিসনেসের বিবরণ";
bn["Resort"] = "রিসোর্ট";
bn["Resort Name"] = "রিসোর্টের নাম";
bn["Resort Location"] = "রিসোর্টের ঠিকানা";
bn["Room Type"] = "রুমের ধরণঃ";
bn["Select your room type"] = "আপনার রুমের ধরণ নির্বাচন করুন";
bn["Single Room"] = "সিঙ্গেল রুম";
bn["Double Room"] = "ডাবল রুম";
bn["Couple Room"] = "কাপল রুম";
bn["Resort Description"] = "রিসোর্টের বিবরণঃ";
bn["Hotel"] = "হোটেল";
bn["Hotel Name"] = "হোটেলের নাম";
bn["Hotel Location"] = "হোটেল ঠিকানা";
bn["Hotel Type"] = "হোটেলের ধরণঃ";
bn["Select your hotel type"] = "আপনার হোটেল ধরণ নির্বাচন করুন";
bn["2 Star"] = "২ স্টার";
bn["3 Star"] = "৩ স্টার";
bn["4 Star"] = "৪ স্টার";
bn["5 Star"] = "৫ স্টার";
bn["Hotel Description"] = "হোটেলের বিবরণঃ";
bn["Community & Party Centre"] = "কমিউনিটি এবং পার্টি সেন্টার";
bn["Community & Party Center Name"] = "কমিউনিটি এবং পার্টি সেন্টারের নামঃ";
bn["Location"] = "ঠিকানা";
bn["Apartment Rentals"] = "অ্যাপার্টমেন্ট ভাড়া";
bn["Bedrooms"] = "বেডরুম";
bn["Balcony"] = "ব্যালকনি";
bn["Select your utility bill mode"] = "আপনার ইউটিলিটি বিল মোড নির্বাচন করুন";
bn["Apartment description"] = "অ্যাপার্টমেন্টের বিবরণ";
bn["Rental Price"] = "ভাড়ার  মূল্য";
bn["Apartment Size in square feet"] = "অ্যাপার্টমেন্টের আকার বর্গফুটে";
bn["Service Charge Mode"] = "সার্ভিস চার্জ মোড";
bn["Select your service charge mode"] = "আপনার সার্ভিস চার্জ মোড নির্বাচন করুন";
bn["Commercial Property Rentals"] = "বাণিজ্যিক সম্পত্তি ভাড়া";
bn["Space Condition"] = "স্থানের অবস্থা";
bn["Select your space condition"] = "আপনার স্থানের অবস্থা  নির্বাচন করুন";
bn["Open Space"] = "খোলা স্থান";
bn["Room Condition"] = "রুমের অবস্থা";
bn["Commercial Space Type"] = "বাণিজ্যিক স্থানের ধরন";
bn["Select your commercial space type"] =
  "আপনার বাণিজ্যিক স্থানের ধরন নির্বাচন করুন";
bn["Multifamily"] = "বহু পরিবার";
bn["Office"] = "অফিস";
bn["Industrial"] = "শিল্প";
bn["Retail Stores"] = "রিটেইল স্টোর";
bn["Hospitality"] = "আতিথেয়তা";
bn["Mixed Use"] = "মিক্সড ইউজ";
bn["Land"] = "জমি";
bn["Special Purpose"] = "বিশেষ কারণ";
bn["Malls"] = "মল";
bn["Medical Centre"] = "মেডিকেল সেন্টার (স্বাস্থ্যকেন্দ্র)";
bn["Farm House"] = "ফার্ম হাউস";
bn["Warehouses"] = "গুদামঘর";
bn["Garages"] = "গ্যারেজ";
bn["Store House"] = "স্টোর হাউস";
bn["Utility Bill Mode"] = "ইউটিলিটি বিল মোড";
bn["Select your utility bill mode"] = "আপনার ইউটিলিটি বিল মোড নির্বাচন করুন";
bn["Property Description"] = "সম্পত্তির বিবরণ";
bn["Rental Price"] = "ভাড়ার  মূল্য";
bn["Commercial Space Size in square feet"] = "বাণিজ্যিক স্থানের আকার বর্গফুটেঃ";
bn["Service Charge Mode"] = "সার্ভিস চার্জ মোড";
bn["Select your service charge mode"] = "আপনার সার্ভিস চার্জ মোড নির্বাচন করুন";
bn["Shops To-Let"] = "দোকান টু-লেট";
bn["Shop Size in square feet"] = "শপের আকার বর্গফুটেঃ";
bn["Shop Rental Price"] = "শপের ভাড়ার  মূল্য";
bn["House To-Let"] = "হাউস টু-লেট";
bn["Balcony"] = "ব্যালকনি";
bn["House description"] = "হাউসের বিবরণ";
bn["House Size in square feet"] = "হাউসের আকার বর্গফুটে";
bn["Garages To-Let"] = "গ্যারেজ টু-লেট";
bn["Type of Garages"] = "গ্যারেজের ধরণ";
bn["Select your garages type"] = "আপনার গ্যারেজের ধরণ নির্বাচন করুন";
bn["Car"] = "কার";
bn["Motorcycle"] = "মটরসাইকেল";
bn["Van"] = "ভ্যান";
bn["Cover Van"] = "কভার ভ্যান";
bn["Bicycle"] = "বাইসাইকেল";
bn["Bus"] = "বাস";
bn["Trucks"] = "ট্রাক";
bn["Rickshaws"] = "রিকক্সা";
bn["CNG"] = "সিএনজি";
bn["Garages Description"] = "গ্যারেজের বর্ণনা";
bn["Garages Rental Price"] = "গ্যারেজ ভাড়ার  মূল্য";
bn["Sub-Let"] = "সাব-লেট";
bn["Number Of Room"] = "রুমের সংখ্যা";
bn["Others Facilities"] = "অন্যান্য সুবিধা";
bn["Mess To-Let"] = "মেস টু-লেট";
bn["Number of seats"] = "সিটের সংখ্যা";
bn["Floor"] = "ফ্লোর";
bn["Select your utility bill mode"] = "আপনার ইউটিলিটি বিল মোড নির্বাচন করুন";
bn["Mess description"] = "মেসের বিবরণ";
bn["Select your space condition"] = "আপনার স্থানের অবস্থা  নির্বাচন করুন";
bn["Open Space"] = "খোলা স্থান";
bn["Service Charge Mode"] = "সার্ভিস চার্জ মোড";
bn["Select your service charge mode"] = "আপনার সার্ভিস চার্জ মোড নির্বাচন করুন";
bn["Including"] = "ইনক্লোডিং";
bn["Excluding"] = "এক্সক্লোডিং";
bn["House Rental Price"] = "হাউসের ভাড়ার  মূল্য";

// new added
bn["Micromax Informatics ZTE"] = "মাইক্রোম্যক্স ইনফরমেটিক্স জেট টি ই";
bn["Enlarger"] = "এনলারজার";
bn["Mobile Screen"] = "মোবাইল স্ক্রিন";
bn["Laptop Description"] = "ল্যাপটপ বিবরণ";
bn["Description"] = "বিবরণ";
bn["Other Brand"] = "অন্যান্য ব্র্যান্ড";
bn["Shalwar"] = "সালওয়ার";
bn["Kameez & Kurtis"] = "কামিজ এবং কুর্তি";
bn["Surgical  Mask"] = "সারজিক্যাল মাস্ক";
bn["Description Service Fee (Instead of Price)"] =
  "সার্ভিস ফি বিবরণ (মূল্যের পরিবর্তে)";
bn["Course Fee (Instead of Price)"] = "কোর্স ফি (মূল্যের পরিবর্তে)";
bn["Rasmalai of Comilla"] = "কুমিল্লার রসমালাই";
bn["Service Charge Instead of Price"] = "সার্ভিস ফি (মূল্যের পরিবর্তে)";
bn["Separate Room"] = "পৃথক রুম";

export default bn;
