import React, { useEffect } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import moment from 'moment';
import { getAdDetailsUrlByBreadcrumbPath } from '../../helpers/GenerateBreadcrumbPath'
import {
  capitalizeFirst,
  convertTitleToSlug,
  languageCheck,
  titlecConvertToSlug,
} from "../../helpers/Helpers";
import axios from "axios";

const AllAd = ({ breadcrumb, allData }) => {

  useEffect(() => {
    console.log(allData);
    const getData = async () => {
      try {
        const response = await axios.get("adpost/manage-category");
        const responseData = response.data;
        if (responseData) {
          responseData.forEach((category) => {
            if (breadcrumb == category?.category_name) {
              const metaTitle = document.querySelector('meta[name="title"]');
              if (metaTitle) {
                metaTitle.setAttribute("content", `<h1>${category?.category_meta_title}</h1>`);
              }
              document.title = category?.category_meta_title;
              const metaDescription = document.querySelector('meta[name="description"]');
              if (metaDescription) {
                metaDescription.setAttribute("content", `<h2>${category?.category_meta_description.substring(0, 200)}</h2>`);
              }
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [breadcrumb]);

  return (
    <>
      <div className="single_ads_card ads_list d-sm-flex mt-15">
        <Link rel="canonical"
          className="like"
          to={
            getAdDetailsUrlByBreadcrumbPath(allData)
          }
        // to={
        //   "/ad/details/" +
        //   // convertTitleToSlug(allData?.title)
        //   allData?.slug
        // }
        >
          <div className="ad_card_thambail_big">
            {allData?.image_1 ? (
              <div
                style={{
                  display: "flex",
                  height: "155px",
                  width: "140px",
                  backgroundColor: "rgb(128 128 128 / 54%)",
                }}
              >
                <img
                  style={{
                    margin: "auto",
                    width: "auto",
                    height: "100%",
                    overflow: "hidden",
                  }}
                  src={allData?.image_1}
                  className="ads_img"
                  alt={allData?.title}
                />
              </div>
            ) : (
              <img
                style={{
                  width: "auto",
                  height: "100%",
                  margin: "auto",
                  overflow: "hidden",
                }}
                src={allData?.resize_image && allData.resize_image[0]?.cardImage}
                className="ads_img"
                alt={allData?.title}
              />
            )}
          </div>
        </Link>

        <div
          className="ads_card_content media-body"
          style={{ fontWeight: "800", fontSize: "16px" }}
        >
          <Link rel="canonical"
            className="like"
            to={
              getAdDetailsUrlByBreadcrumbPath(allData)

            }
          // to={
          //   "/ad/details/" +
          //   // convertTitleToSlug(allData?.title)
          //   allData?.slug
          //   // +
          //   // "/" +
          //   // allData?.id +
          //   // "/"
          // }
          >
            <div className="meta d-flex justify-content-between">
              <p>{capitalizeFirst(allData?.title)}</p>
            </div>
            <p style={{ fontSize: "14px" }}>
              {" "}
              <i className="far fa-map-marker-alt"></i>
              {allData?.address},
              {languageCheck() === "bn" ? "বাংলাদেশ" : "Bangladesh"}{" "}
            </p>
            {/* <p style={{ fontSize: "14px" }}>
              <i className="fas fa-calendar"></i>
              <Moment format="LL">{allData?.created_at}</Moment>
            </p> */}
            <div className="ads_price_date d-flex justify-content-between">
              <span
                style={{ fontWeight: "800", fontSize: "15px" }}
                className="price"
              >
                ৳ {allData?.price}
              </span>
            </div>
          </Link>
          <p style={{ fontSize: "14px", display: 'flex', justifyContent: 'end', color: '#149777' }}>
            {moment(allData?.created_at).fromNow()}
          </p>
        </div>
      </div>
    </>
  );
};

export default AllAd;
