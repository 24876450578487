import React, { useEffect,useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';


const CanonicalUpdater = () => {
    const location = useLocation();
    const [canonicalUrl, setCanonicalUrl] = useState('https://publicmarket.com.bd')
    useEffect(() => {
        const canonicalUrl = `https://publicmarket.com.bd${location.pathname}`;
        const linkElement = document.querySelector("link[rel='canonical']");
        setCanonicalUrl(canonicalUrl)
        if (linkElement) {
            linkElement.setAttribute("href", canonicalUrl);
        } else {
            const newLinkElement = document.createElement('link');
            newLinkElement.setAttribute('rel', 'canonical');
            newLinkElement.setAttribute('href', canonicalUrl);
            document.head.appendChild(newLinkElement);
        }
    }, [location.pathname]);

    return (<>
        <Helmet>
            <link rel="canonical" href={`${canonicalUrl}`} />
        </Helmet>

    </>);
};

export default CanonicalUpdater;