import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import {
  capitalizeFirst,
  convertTitleToSlug,
  languageCheck,
  titlecConvertToSlug,
} from "../../helpers/Helpers";

import { getAdDetailsUrlByBreadcrumbPath } from '../../helpers/GenerateBreadcrumbPath'

const PromotionAd = ({ allData }) => {
  return (
    <>
      <div
        className="single_ads_card ads_list d-sm-flex mt-15"
        style={{
          border: "0.3px solid #ff000075",
          backgroundColor: "#ffffffc7",
        }}
      >
        {/* <Link rel="canonical" className="like" to={"/ad/details/"+convertTitleToSlug(allData?.title)}>  */}
        <Link rel="canonical" className="like" to={"/ad/details/" + allData?.slug}>
          <div className="ad_card_thambail_big">
            {allData.image_1 ? (
              <div
                style={{
                  display: "flex",
                  height: "155px",
                  width: "140px",
                  backgroundColor: "rgb(128 128 128 / 54%)",
                }}
              >
                <img
                  style={{
                    margin: "auto",
                    width: "auto",
                    height: "100%",
                    overflow: "hidden",
                  }}
                  src={allData?.image_1}
                  className="ads_img"
                  alt={allData?.title}
                />
              </div>
            ) : (
              <img
                style={{
                  width: "auto",
                  height: "100%",
                  margin: "auto",
                  overflow: "hidden",
                }}
                src={allData?.resize_image[0]?.cardImage}
                className="ads_img"
                alt={allData?.title}
              />
            )}
          </div>
        </Link>

        <div
          className="ads_card_content media-body"
          style={{ fontWeight: "800", fontSize: "16px" }}
        >
          <Link rel="canonical" className="like" to={
            // "/ad/details/" + allData?.slug
            getAdDetailsUrlByBreadcrumbPath(allData)
          }>
            <div className="meta d-flex justify-content-between">
              <p>{capitalizeFirst(allData?.title)}</p>
            </div>
            <p style={{ fontSize: "14px" }}>
              {" "}
              <i className="far fa-map-marker-alt"></i>
              {allData?.address},
              {languageCheck() === "bn" ? "বাংলাদেশ" : "Bangladesh"}{" "}
            </p>
            <p style={{ fontSize: "14px" }}>
              <i className="fas fa-calendar"></i>
              <Moment format="LL">{allData?.created_at}</Moment>
            </p>
            <div className="ads_price_date d-flex justify-content-between">
              <span
                style={{ fontWeight: "800", fontSize: "15px" }}
                className="price"
              >
                ৳ {allData?.price}
              </span>
              <img
                style={{ marginLeft: "225px" }}
                width="40px"
                src={allData?.promotion_logo}
                alt={allData?.title}
              />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PromotionAd;
