const Key = {
  Keep_it_short: "Keep it short",
  Fill_in_the_details: "Fill in the details",
  About_the_TVC: "About the TVC",
  Ad_Title: "Title",
  Ad_Title_Example: "Example: Driver for hire in Dhaka",
  Company_Name: "Company Name",
  Description: "Description",
  Description_Job: "Job Description",
  Describe_the_TVC_in_detail: "Describe the TVC in detail",
  Video: "Video",
  Submit: "Submit",
  Edit: "Edit",
  You_must_fill_out_this_field: "You must fill out this field",
  The_information_entered_is_too_short: "The information entered is too short",
  The_information_entered_is_too_long: "The information entered is too long",
  All_Categories: "All Categories",
  work_experience_long: "No more than 50.",
  work_experience_Short: "Must be atleast 0",
  employer_website: "Please enter a valid URL",
  Must_Be_Use_Number: "Only Number are Allowed",
  salary_check_from_to:
    "Provide the minimum value on the left input and the maximum value on the right",
  salary_check_from_to_long: "salary limit over",
  salary_check_from_to_short: "salary limit to short",
  // auth leng
  old_password_leng: "Current Password",
  confirm_password_leng: "Confirm New Password",
  new_password_leng: "New Password",
  link: "Link",
};

export default Key;
