import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import facebook from "../../asset/frontend/assets/images/social_media_icon/facebook.png";
import instagram from "../../asset/frontend/assets/images/social_media_icon/instagram.png";
import linkedin from "../../asset/frontend/assets/images/social_media_icon/linkedin.png";
import pinterest from "../../asset/frontend/assets/images/social_media_icon/pinterest.png";
import twiter from "../../asset/frontend/assets/images/social_media_icon/twiter.png";
import whatsapp from "../../asset/frontend/assets/images/social_media_icon/whatsapp.png";
import youtube from "../../asset/frontend/assets/images/social_media_icon/youtube.png";
import sslBanner from "../../asset/frontend/assets/images/ssl-banner2.jpg";
import appStore from "../../asset/frontend/assets/images/app-store1.png";
import appStore2 from "../../asset/frontend/assets/images/app-store2.png";
// import basis from "../../asset/frontend/assets/images/StaticImage/BASIS.png";
import basis from "../../asset/frontend/assets/images/StaticImage/basis.svg";
import bkash from "../../asset/frontend/assets/images/StaticImage/bkash_accept.png";
import nagad from "../../asset/frontend/assets/images/StaticImage/nagad_accept.png";
import dbid from "../../asset/frontend/assets/images/logo/logo-bid.png";
import { allLocalStorageRemove, languageCheck } from "../../helpers/Helpers";
import moment from "moment";

// import eCabLogo from '';

const FooterHomeTop = ({ title, description }) => {
  const [rotate, setRotate] = useState(false);
  const sideBoxManage = (e) => {
    setRotate(!rotate);
  };
  const [showMore, setShowMore] = useState(false);
  const [substring, setSubString] = useState("");
  const token = localStorage.getItem("token");

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    allLocalStorageRemove();
  }, []);

  const getSubString = () => {
    const substring = description.substring(0, 300); // Extract first 300 characters
    const lastDotIndex = substring.lastIndexOf("."); // Find the index of the last dot
    const result = substring.substring(0, lastDotIndex + 1); // Extract substring till the last dot (including dot)
    return result ?? "";
  };

  return (
    <div
      style={{
        padding: "30px",
        background: "#d9d9d9",
        minHeight: "100px",
      }}
    >
      <div
        style={{
          color: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <h2 style={{ textAlign: "center" }}>PublicMarket.com.bd | One-Stop Marketplace for Buying and Selling</h2>
          </div>
          {!showMore && (
            <p>
              <span
                style={{
                  fontSize: "11pt",
                }}
              >
                Publicmarket.com.bd is a comprehensive online marketplace in
                Bangladesh, revolutionizing the way people buy and sell products
                and services. From electronics and fashion to real estate and
                job listings, our platform offers a wide variety of both new and
                used items, making shopping just a click away. Designed to meet
                the diverse needs of our customers, Publicmarket.com.bd features
                a vast array of categories ranging from personal goods to
                business services.
              </span>
            </p>
          )}
          {showMore && (
            <>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  Publicmarket.com.bd is a comprehensive online marketplace in
                  Bangladesh, revolutionizing the way people buy and sell
                  products and services. From electronics and fashion to real
                  estate and job listings, our platform offers a wide variety of
                  both new and used items, making shopping just a click away.
                  Designed to meet the diverse needs of our customers,
                  Publicmarket.com.bd features a vast array of categories
                  ranging from personal goods to business services.
                </span>
              </p>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  With a focus on reliability and efficiency, our goal is to
                  establish Publicmarket.com.bd as the premier destination for
                  online shopping in Bangladesh. More than just a marketplace,
                  it acts as a community builder that brings people together
                  through commerce. Our website provides an extensive selection
                  of items, coupled with easy navigation and detailed product
                  listings. Shoppers can filter search results to find precisely
                  what they need at their preferred location.
                </span>
              </p>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  We support a wide range of payment options to ensure
                  accessibility and convenience for all users to buy sell in
                  Bangladesh. The platform&apos;s dedication to a secure and
                  robust marketplace is evident through its detailed product
                  descriptions, secure transaction processes, and responsive
                  customer support.
                </span>
              </p>
              <h2>
                <span
                  style={{
                    fontSize: "16pt",
                  }}
                >
                  Why Choose PublicMarket.com.bd:
                </span>
              </h2>
              <p
                style={{
                  marginLeft: "2%",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  Reasons to choose us for online buy and sell bd:
                </span>
              </p>
              <ul style={{ marginLeft: "3%" }}>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Wide Selection: Access an expansive range of new and used
                      products and services, including real estate and jobs.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      User-Friendly: Experience easy navigation and an intuitive
                      interface for hassle-free transactions.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Secure Transactions: Benefit from strong security measures
                      designed to ensure safe and reliable dealings.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Wide Accessibility: The platform supports various payment
                      methods, catering to a broad audience.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Increased Visibility: Sellers can reach a wider audience,
                      expanding their customer base.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Reliable Customer Support: Our dedicated support team is
                      always ready to assist with any inquiries or issues.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Community Engagement: Connect with buyers and sellers
                      across different regions, fostering a vibrant marketplace
                      community.
                    </span>
                  </p>
                </li>
              </ul>
              <h2>
                <span
                  style={{
                    fontSize: "16pt",
                  }}
                >
                  Benefits of Engaging with PublicMarket.com.bd:
                </span>
              </h2>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  At PublicMarket.com.bd, we make buying, selling, and
                  discovering products and services straightforward, offering
                  advantages beyond traditional classified sites in bd
                  marketplace:
                </span>
              </p>
              <ul style={{ marginLeft: "3%" }}>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Fast &amp; Convenient Experience: Our user-friendly
                      platform facilitates quick and reliable online
                      transactions. Shop or sell on the go and find what you
                      need with just a few clicks.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Premium Ad Posting: Users can post ads at a low cost
                      across many categories, enhancing accessibility and
                      convenience.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    listStyleType: "dise",
                    fontSize: "11pt",
                  }}
                >
                  <p>
                    <span
                      style={{
                        fontSize: "11pt",
                      }}
                    >
                      Safe &amp; Secure Transactions: We prioritize user safety
                      with a list of verified members and authorized dealers,
                      ensuring a secure shopping experience for everyone.
                    </span>
                  </p>
                </li>
              </ul>
              <p>
                <span
                  style={{
                    fontSize: "11pt",
                  }}
                >
                  Whether you are looking to engage with the dynamic BD market
                  or simply explore online buying and selling opportunities,
                  Publicmarket.com.bd is your gateway to a plethora of products
                  and services, ensuring a seamless and secure shopping
                  experience. With a significant number of unique monthly
                  visitors, thousands of eager buyers, and numerous active
                  sellers, PublicMarket.com.bd is rapidly becoming a leading
                  online marketplace in Bangladesh.
                </span>
              </p>
            </>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <button
              onClick={toggleShowMore}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {showMore &&
                (languageCheck() === "bn" ? "কম দেখান" : "Show less")}
              {!showMore && (
                <>{languageCheck() === "bn" ? "আরও দেখান" : "Show More"}</>
              )}
              <span
                style={{
                  marginLeft: "4px",
                  marginTop: "4px",
                }}
              >
                {showMore ? (
                  <i className="fal fa-angle-up footericon fa-2x"></i>
                ) : (
                  <i className="fal fa-angle-down footericon fa-2x"></i>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterHomeTop;
