import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { getAdDetailsUrlByBreadcrumbPath } from '../../../helpers/GenerateBreadcrumbPath';
import pmImage from "../../../asset/frontend/assets/images/logo/small-logo.png"
import { capitalizeFirst, convertTitleToSlug, languageCheck, titlecConvertToSlug } from '../../../helpers/Helpers';

const AdsCard = ({ allData }) => {


    return (
        <>
            <div className="col-lg-6 col-md-6 col-sm-12 ad__banner__card" style={{ display: "flex", justifyContent: "center" }} >
                {/* <Link rel="canonical" to={"/ad/details/"+titlecConvertToSlug(allData?.title)+"/"+allData?.id+"/"}
                state={{type: 'ad_category', id: allData?.ad_category }}
                >
                <div className="single_ads_card mt-30" >
                        <div className="ads_card_image d-flex justify-content-center custom_ad_card_for_home_page">
                            <img src={allData.image_1} className="ads_card_image_big"  alt="ads"/>
                                <span style={{position: 'absolute',
                                        height: '2rem',
                                        width: '5.5rem',
                                        top: '15px',
                                        left: '15px', 
                                        borderRadius: '7px'}}>
                        <img className="public-market-tag img-fluid" width="50px" height="50px" src={pmImage} alt="" /></span>
                       </div>
                    
                    <div className="ads_card_content">
                        <div className="meta d-flex justify-content-between">
                            <p> {capitalizeFirst(allData.title)}</p>  
                        </div>
                        <p><i className="far fa-map-marker-alt"></i>{allData.address.slice(0,12)}, 
                        {languageCheck() === 'bn' ?  "বাংলাদেশ" :   "Bangladesh"} </p>
                        <p>
                            <i className="fas fa-calendar"></i><Moment format="LL" >{allData?.created_at}</Moment>
                        </p>
                        <div className="ads_price_date d-flex justify-content-between">
                            <span className="price">{allData.price}৳</span>
                        </div>
                    </div>
                </div>
                </Link> */}
                <Link rel="canonical"
                    style={{ width: '100%' }}
                    className="like"
                    to={
                        getAdDetailsUrlByBreadcrumbPath(allData)
                    }
                // to={
                //     "/ad/details/" +
                //     // convertTitleToSlug(allData?.title)
                //     allData?.slug
                //     //  +
                //     // "/" +
                //     // allData?.id +
                //     // "/"
                // }
                >
                    <div className="single_ads_card ads_list d-sm-flex mt-15" style={{ height: '145px' }} >
                        <div className="ad_card_thambail_big">
                            {allData?.image_1 ? (
                                <div
                                    style={{
                                        display: "flex",
                                        height: "155px",
                                        width: "140px",
                                        backgroundColor: "rgb(128 128 128 / 54%)",
                                    }}
                                >
                                    <img
                                        style={{
                                            margin: "auto",
                                            width: "auto",
                                            height: "100%",
                                            overflow: "hidden",
                                        }}
                                        src={allData?.image_1}
                                        className="ads_img"
                                        alt={allData?.title}
                                    />
                                </div>
                            ) : (
                                <img
                                    style={{
                                        width: "auto",
                                        height: "100%",
                                        margin: "auto",
                                        overflow: "hidden",
                                    }}
                                    src={allData?.resize_image[0]?.cardImage}
                                    className="ads_img"
                                    alt={allData?.title}
                                />
                            )}
                        </div>
                        <div
                            className="ads_card_content media-body"
                            style={{ fontWeight: "800", fontSize: "16px" }}
                        >

                            <div className="meta d-flex justify-content-between">
                                <p style={{ fontFamily: 'roboto, sans-serif' }}>{capitalizeFirst(allData?.title)}</p>
                            </div>
                            <p style={{ fontSize: "14px" }}>
                                {" "}
                                <i className="far fa-map-marker-alt"></i>
                                <small style={{ fontFamily: 'roboto, sans-serif' }}>{allData?.address}, {languageCheck() === "bn" ? "বাংলাদেশ" : "Bangladesh"}{" "}</small>

                            </p>
                            <div className="ads_price_date d-flex justify-content-between">
                                <p
                                    style={{ fontWeight: '800!important', fontSize: "15px", fontFamily: 'roboto, sans-serif' }}
                                    className="price"
                                >
                                    ৳ {allData?.price}
                                </p>
                            </div>
                            <p style={{ fontSize: "14px", display: 'flex', justifyContent: 'end', fontFamily: 'roboto, sans-serif' }}>
                                <small>{moment(allData?.created_at).fromNow()}</small>
                            </p>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};

export default AdsCard;