import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import FooterTop from "../components/Footer/FooterTop";
import AllAd from "../components/Ad/AllAd";
import PromotionAd from "../components/Ad/PromotionAd";
import AdFilter from "../components/Ad/AdFilter";
import PaginateCustom from "../components/Paginate/PaginateCustom";
import { getPaginationAction } from "../redux/action/paginationAction";
import BannerAdShowLeft from "../components/BannerAdComponets/BannerAdShowLeft";
import Skeleton from "react-loading-skeleton";
import {
  debounce,
  getAdCategoryItems,
  getAdSubCategoryItems,
  getDistrictItems,
  getDivisionItems,
  getQueryParam,
  isInvalid,
  replaceWithSpace,
} from "../Utilities/Utilities";
import AdCategoryList from "../components/AdCategoryList";
import BannerAdShowTop from "../components/BannerAdComponets/BannerAdShowTop";
import BannerAdShowRight from "../components/BannerAdComponets/BannerAdShowRight";
import BannerAdShowBottom from "../components/BannerAdComponets/BannerAdShowBottom";
import {
  convertTitleToSlug,
  languageCheck,
  titlecConvertToSlug,
  useWindowSize,
} from "../helpers/Helpers";
import Breadcrumb from "../components/CommonComponents/Breadcrumb/Breadcrumb";

import {
  getDistrictRequest,
  getDivisionRequest,
} from "../redux/action/coreAction";
import {
  getAdCategoryRequest,
  getAdSubCategoryRequest,
} from "../redux/action/userProfileAction/profileAdAction";
import axios, { Axios } from "axios";
import CarouselSlider from "../components/Slider/CarouselSlider";

import s1 from "../asset/slider.jpg";
import s2 from "../asset/slider2.jpg";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import highlisht from "../asset/h.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  getremoveSpace,
  getAdDetailsUrlByBreadcrumbPath,
} from "../helpers/GenerateBreadcrumbPath";
import FooterHomeTop from "../components/Footer/FooterHomeTop";
import FooterCategoryForEm from "../components/Footer/FooterCategoryForEm";

const AllAdPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getBreadcrumbs, setBreadcrumbs] = useState([]);
  const [getAllData, setAllData] = useState("");
  const [getFilter, setFilter] = useState({});
  const filterRef = useRef();
  const refSearch = useRef();
  const [getSearch, setSearch] = useState("");
  const breadcrumbOrders = [
    "division",
    "district",
    "ad_category",
    "ad_sub_category",
    "search",
  ];
  const keyPathMap = {
    division: "divisions",
    district: "districts",
    ad_category: "categories",
    ad_sub_category: "sub-categories",
  };
  const getLocationData = useLocation();
  const slides = [
    { url: s1, title: "beach", link: "www.google.com" },
    { url: s2, title: "boat", link: "www.google.com" },
  ];

  const [slidesData, setSlidesData] = useState([]);
  const [getAdCategoryDefaultValue, setAdCategoryDefaultValue] = useState("");
  const [getDivisionDefaultValue, setDivisionDefaultValue] = useState("");
  const [getDistrictDefaultValue, setDistrictDefaultValue] = useState("");
  const [getAdSubCategoryDefaultValue, setAdSubCategoryDefaultValue] =
    useState("");
  const [adCategory, setAdCategory] = useState(getLocationData?.state?.id);

  const [canNavigate, setNavigate] = useState(true);
  const [oldFilter, setOldFilter] = useState({});
  const [firstCall, setFirstCall] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const handleCaroselImageClick = (url) => {
    navigate(url);
  };
  //
  const fetchSlidesData = async () => {
    try {
      const response = await axios.get(
        "https://publicmarket.com.bd/v2/en/api/adpost/promoted-highlights-ads/"
      );

      const data = response?.data?.results;
      setSlidesData(response?.data?.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Promotion Data
  const [getPromotionAd, setPromotionAd] = useState([]);
  const getPromotionCategoryRequest = async () => {
    const category = getAdCategoryDefaultValue ? getAdCategoryDefaultValue : "";

    try {
      const response = (
        await axios.get(
          `adpost/promoted-ads/?category=${category}&all_promoted_ads=`
        )
      ).data;
      setPromotionAd(response?.results);
      return response;
    } catch (error) {
      console.log("API ERROR");
    }
  };

  const onSearch = (e) => {
    const obj = { ...getFilter };
    obj.search = {
      value: e.target.value,
      title: e.target.value,
    };
    setFilter(obj);
  };

  const searchDebounce = debounce(onSearch);

  const onSearchTextChange = (e) => {
    setSearch(e.target.value);
    searchDebounce(e);
  };

  const getPaginateData = (paginateData, getNewOffset) => {
    setAllData(paginateData?.results);
  };

  const paginateInfo = {
    path: "adpost/",
    offset: 0,
    limit: 10,
  };

  const segmentValue = (value) => {
    value = decodeURIComponent(value);
    return replaceWithSpace(value);
  };

  const setValuesOnUriChanges = (uri, filter) => {
    const segments = uri?.split("/").filter((x) => x?.trim());
    const len = segments.length;
    const _filter = {};
    setAdCategoryDefaultValue("");
    setAdSubCategoryDefaultValue("");
    setDivisionDefaultValue("");
    setDistrictDefaultValue("");
    setSearch("");
    if (len >= 2 && segments[1] !== "divisions") {
      // ad category
      const division = getDivisionItems()?.find(
        (x) =>
          getremoveSpace(x.name.toLowerCase()) === getremoveSpace(segmentValue(segments[1]).toLowerCase())
      );
      // const division = getDivisionItems()?.find(
      //   (x) => titlecConvertToSlug(x.name) === segmentValue(segments[1])
      // );
      if (division) {
        _filter.division = {
          value: division.id,
          title: division.name,
        };
        setDivisionDefaultValue(division.id);
      }
    }
    if (len >= 3 && segments[2] !== "districts") {
      console.log("segments 2: ", segments[2]);
      const district = getDistrictItems()?.find(
        (x) =>
          getremoveSpace(x.name.toLowerCase()) === getremoveSpace(segmentValue(segments[2]).toLowerCase())

      );
      console.log("district", district);
      // const district = getDistrictItems()?.find(
      //   (x) => titlecConvertToSlug(x.name) === segmentValue(segments[2])
      // );
      if (district) {
        _filter.district = {
          value: district.id,
          title: district.name,
        };
      }
      setDistrictDefaultValue(district.id);
    }
    if (len >= 4 && segments[3] !== "categories") {
      const adCategory = getAdCategoryItems()?.find(
        (x) =>
          getremoveSpace(x.name.toLowerCase()) === getremoveSpace(segmentValue(segments[3]).toLowerCase())
      );
      // const adCategory = getAdCategoryItems()?.find(
      //   (x) => titlecConvertToSlug(x.name) === segmentValue(segments[3])
      // );
      if (adCategory) {
        _filter.ad_category = {
          value: adCategory.id,
          title: adCategory.name,
        };
        setAdCategoryDefaultValue(adCategory.id);
      }
    }
    if (len >= 5 && segments[4] !== "sub-categories") {
      const adSubCategory = getAdSubCategoryItems()?.find(
        (x) =>
          getremoveSpace(x.name.toLowerCase()) === getremoveSpace(segmentValue(segments[4]).toLowerCase())
      );
      // const adSubCategory = getAdSubCategoryItems()?.find(
      //   (x) => titlecConvertToSlug(x.name) === segmentValue(segments[4])
      // );
      if (adSubCategory) {
        _filter.ad_sub_category = {
          value: adSubCategory.id,
          title: adSubCategory.name,
        };
        setAdSubCategoryDefaultValue(adSubCategory.id);
      }
    }

    const search = replaceWithSpace(
      getQueryParam(getLocationData.search, "search")
    );
    if (search) {
      _filter.search = {
        value: search,
        title: search,
      };
      setSearch(search);
    }
    if (filter) {
      setFilter(_filter);
    }
  };

  const navigateToBreadcrumbs = (breadcrumbs) => {
    if (breadcrumbs?.length) {
      const paths = [];
      for (let i = 0; i < breadcrumbOrders.length; i++) {
        const x = breadcrumbOrders[i];
        if (x !== "search") {
          const item = breadcrumbs.find((y) => y.key === x);
          if (!item) {
            paths.push(keyPathMap[x]);
          } else {
            const path = titlecConvertToSlug(item.title);
            if (path) {
              paths.push(path);
            }
          }
        }
      }
      const path = paths.join("/");
      if (path) {
        const origin = getremoveSpace(path.toLowerCase());
        const searchValue = breadcrumbs.find((x) => x.key === "search");
        let fullPath = `/allads/${origin}`;
        if (searchValue?.title) {
          fullPath += `?search=${getremoveSpace(
            searchValue.title.toLowerCase()
          )}`;
        }
        let currentPath = getremoveSpace(
          getLocationData?.pathname.toLowerCase()
        );
        if (getLocationData?.search) {
          currentPath += getremoveSpace(getLocationData?.search.toLowerCase());
        }
        if (currentPath !== fullPath) {
          //const filterPath = getremoveSpace(fullPath.toLowerCase());
          if (canNavigate) {
            navigate(fullPath);
          }
        } else {
          // console.log("same uri, no need to navigate!");
        }
        setNavigate(true);
      }
    }
  };

  const onPopState = (e) => {
    setNavigate(false);
  };

  const [pageMetaTitleData, setPageMetaTitleData] = useState(
    "publicmarket.com.bd"
  );
  const [pageFooteraescriptionData, setPageFooteraescriptionData] = useState(
    "publicmarket.com.bd"
  );
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("All ad categories");

  const setMetaInfo = async (breadcrumb) => {
    let metaTitleData = "publicmarket.com.bd";
    let metaDescriptionData = "publicmarket.com.bd";

    setBreadcrumbTitle(breadcrumb);

    try {
      const response = (await axios.get("adpost/manage-category")).data;
      if (response) {
        response.map((category) => {
          if (breadcrumb == category?.category_name) {
            setPageMetaTitleData(category?.category_meta_title);
            setPageFooteraescriptionData(category?.footer_details);

            const metaTitle = document.querySelector('meta[name="title"]');
            if (metaTitle) {
              metaTitle.setAttribute(
                "content",
                "<h1>" + category?.category_meta_title + "</h1>"
              );
            }

            document.title = category?.category_meta_title;
            const metaDescription = document.querySelector(
              'meta[name="description"]'
            );
            if (metaDescription) {
              metaDescription.setAttribute(
                "content",
                category?.category_meta_description
              );
            }
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const generateBreadcrumbs = () => {
    // generate breadcrumbs
    let breadcrumbs = [];
    breadcrumbOrders.forEach((x) => {
      const filterValue = getFilter[x];
      if (filterValue?.value) {
        breadcrumbs.push({
          key: x,
          title: filterValue.title,
        });

        if (filterValue.title != "") {
          setMetaInfo(filterValue.title);
        }
      }
    });
    if (breadcrumbs.length <= 0) {
      setMetaInfo("All Ads Categorys");
    }
    setBreadcrumbs(breadcrumbs);
    navigateToBreadcrumbs(breadcrumbs);
  };

  useEffect(() => {
    const divisions = getDivisionItems();
    const districts = getDistrictItems();
    const categories = getAdCategoryItems();
    const subCategories = getAdSubCategoryItems();
    fetchSlidesData();

    if (
      loading &&
      (isInvalid(divisions) ||
        isInvalid(districts) ||
        isInvalid(categories) ||
        isInvalid(subCategories))
    ) {
      Promise.all([
        getDivisionRequest(),
        getDistrictRequest(),
        getAdCategoryRequest(),
        getAdSubCategoryRequest(),
      ]).then(() => {
        setLoading(false);
        setValuesOnUriChanges(getLocationData.pathname, true);
      });
    } else {
      if (loading) {
        setLoading(false);
      }
      setValuesOnUriChanges(getLocationData.pathname, true);
    }
  }, [getLocationData]);

  useEffect(() => {
    setFirstCall(true);
    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const oldFilterStr = JSON.stringify(oldFilter);
      const newFilterStr = JSON.stringify(getFilter);
      const filterChecking = oldFilterStr !== newFilterStr;
      if (filterChecking) {
        setOldFilter(getFilter);
        const filter = {};
        const keys = Object.keys(getFilter);
        breadcrumbOrders.forEach((x) => {
          const key = keys.find((y) => y === x);
          if (key) {
            const value = getFilter[key];
            if (value.constructor.name === "Object") {
              filter[key] = value.value;
            } else {
              filter[key] = value;
            }
          }
        });
        setPageInfo(filter);
        dispatch(
          getPaginationAction(
            paginateInfo.path,
            paginateInfo.limit,
            paginateInfo.offset,
            filter
          )
        );
        generateBreadcrumbs();
        setFirstCall(false);
      } else if (firstCall) {
        setFirstCall(false);
        dispatch(
          getPaginationAction(
            paginateInfo.path,
            paginateInfo.limit,
            paginateInfo.offset,
            {}
          )
        );
      }
    }, 0);
    getPromotionCategoryRequest();
  }, [getFilter]);

  const onFilterChange = (key, value) => {
    const obj = { ...getFilter };
    obj[key] = value;
    paginateInfo.offset = 0;
    if (key === "division") {
      delete obj.district;
    }
    if (key === "ad_category") {
      delete obj.ad_sub_category;
    }
    setFilter(obj);
  };

  const handleBreadcrumbClick = (value) => {
    const slices = getBreadcrumbs.slice(0, value.index + 1);
    const filter = { ...getFilter };
    ["ad_category", "ad_sub_category", "division", "district"].forEach((x) => {
      if (!slices.filter((y) => y.key === x).length) {
        delete filter[x];
      }
    });
    setFilter(filter);
  };

  const filterAndSearchReset = () => {
    setBreadcrumbs([]);
    setFilter({});
    filterRef?.current?.reset();
    refSearch.current.value = "";
    setAdCategoryDefaultValue("");
    navigate("/allads/divisions/districts/categories/sub-categories");
    setAdSubCategoryDefaultValue("");
    setAdCategory("");
  };

  // search hide show condition
  const [toggleCategory, setToggleCategory] = useState(false);
  const showHideAllAdCategory = () => {
    setToggleCategory((x) => !x);
  };
  const toggleCategoryInit = useWindowSize().width < 770 ? toggleCategory : "";

  useEffect(() => {
    fetchSlidesData();
  }, []);

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const [totalCount, setTotalCount] = useState(null);

  const handleCountChange = (count) => {
    setTotalCount(count);
  };
  return (
    <>
      <Header />
      {loading ? (
        <>
          {/* <div className="container mt-80 pt-50">Loading...</div> */}
          <Skeleton
            height={200}
            count={1}
            containerClassName={"loadingSkeletonWith"}
          />
        </>
      ) : (
        <>
          <div className="ad-container container mt-80 pt-50">
            <BannerAdShowTop widthSize={"100%"} heightSize={"auto"} />
          </div>
          <section className="product_page pt-70 pb-60" id="job">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="top-content">
                    <div className="product_sidebar">
                      <div className="d-flex justify-content-between">
                        <div className="sidebar_title d-flex justify-content-between">
                          <p className="title">
                            {languageCheck() === "bn"
                              ? "ফিল্টার করুন"
                              : "Filter By"}
                          </p>
                          <button
                            onClick={filterAndSearchReset}
                            className="px-2 ml-3 rounded bgRed text-white border-0"
                          >
                            {languageCheck() === "bn" ? "রিসেট" : "Reset"}
                          </button>
                        </div>
                        <div className="search__category__btn mt-2 category_list_btn">
                          <button
                            type="button"
                            onClick={showHideAllAdCategory}
                            className="category__click__btn"
                          >
                            <i
                              className={
                                !toggleCategory
                                  ? "fa fa-minus fa_custom_card"
                                  : "fa fa-plus fa_custom_card"
                              }
                            ></i>
                          </button>
                        </div>
                      </div>

                      <AdFilter
                        ref={filterRef}
                        data={getFilter}
                        onChange={onFilterChange}
                        getLocationData={getLocationData}
                        toggleCategoryInit={toggleCategoryInit}
                        setToggleCategory={setToggleCategory}
                        getAdCategoryDefaultValue={getAdCategoryDefaultValue}
                        setAdCategoryDefaultValue={setAdCategoryDefaultValue}
                        getFilterRoute={getBreadcrumbs}
                        getAdSubCategoryDefaultValue={
                          getAdSubCategoryDefaultValue
                        }
                        setAdSubCategoryDefaultValue={
                          setAdSubCategoryDefaultValue
                        }
                        setDivisionDefaultValue={setDivisionDefaultValue}
                        getDivisionDefaultValue={getDivisionDefaultValue}
                        getDistrictDefaultValue={getDistrictDefaultValue}
                        setDistrictDefaultValue={setDistrictDefaultValue}
                        setAdCategory={setAdCategory}
                        adCategory={adCategory}
                      />
                      <div className="input-group form-group searchFieldCustomDesign">
                        <span className="input-group-append">
                          <div className="input-group-text bg-transparent">
                            <i className="fa fa-search"></i>
                          </div>
                        </span>
                        <input
                          value={getSearch}
                          ref={refSearch}
                          type="text"
                          onChange={onSearchTextChange}
                          className="form-control"
                          placeholder={
                            languageCheck() === "bn"
                              ? "শিরোনাম দ্বারা অনুসন্ধান করুন"
                              : "Search by title"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* breadcrumb__area */}
                <nav className="breadcrumb__area ">
                  <ul style={{ display: "flex", flexWrap: "wrap" }}>
                    <li>
                      <Link rel="canonical" to="/">
                        {languageCheck() === "bn" ? "হোম" : "home"}
                      </Link>
                    </li>
                    <li>
                      <Link rel="canonical" to="/allads/divisions/districts/categories/sub-categories">
                        <span>{">"}</span>{" "}
                        {languageCheck() === "bn"
                          ? "সমস্ত বিজ্ঞাপন"
                          : "all ads"}
                      </Link>
                    </li>
                    <Breadcrumb
                      items={getBreadcrumbs}
                      onClick={handleBreadcrumbClick}
                    />
                  </ul>
                </nav>
                <div className="col-lg-3">
                  <div className="sidebar_price_range mt-15">
                    <div className="pt-3">
                      <AdCategoryList
                        onChange={onFilterChange}
                        getFilterRoute={getBreadcrumbs}
                        getLocationData={getLocationData}
                        getAdCategoryDefaultValue={getAdCategoryDefaultValue}
                        setAdCategoryDefaultValue={setAdCategoryDefaultValue}
                        getAdSubCategoryDefaultValue={
                          getAdSubCategoryDefaultValue
                        }
                        setAdSubCategoryDefaultValue={
                          setAdSubCategoryDefaultValue
                        }
                      />
                    </div>

                    <div className="small-ad banner__ad__left">
                      <BannerAdShowLeft
                        widthSize={"286px"}
                        heightSize={"285px"}
                      />
                    </div>
                  </div>
                </div>
                {/* all ad loop */}
                <div className="col-lg-7">
                  {/* <div className="row">
                  <div className="col-lg-12">
                          <h1>mobile phone</h1>
                          </div>
                  </div> */}
                  {breadcrumbTitle && (
                    <div className="py-3">
                      <h1
                        className="h4"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "roboto, sans-serif",
                        }}
                      >
                        {breadcrumbTitle} item prices in Bangladesh
                      </h1>
                      {totalCount > 0 && (
                        <p style={{ fontFamily: "roboto, sans-serif" }}>
                          Showing 1 - {totalCount < 10 ? totalCount : "10"} of{" "}
                          {totalCount} ads
                        </p>
                      )}
                    </div>
                  )}

                  <div className="tab-content pt-3 " id="myTabContent">
                    <div
                      className="tab-pane fade show active tab_pane_card"
                      id="list"
                      role="tabpanel"
                      aria-labelledby="list-tab"
                    >
                      <div className="product_list">
                        {/* <CarouselSlider slides={slidesData} /> */}
                        {/* {slidesData.length > 0 && (
                          <div>
                            <div className="slider-container">
                              <div className="slider-icon">
                                <img src={highlisht} alt="Icon" />
                              </div>
                              <AutoplaySlider
                                play={true}
                                interval={3000}
                                bullets={false}
                                infinite={true}
                                className="aws-btn-two"
                              >
                                {slidesData.map((slide, index) => (
                                  <div className="image-fit-class" key={index}>
                                    <Link rel="canonical"
                                      to={
                                        "/ad/details/" +
                                        titlecConvertToSlug(slide?.title) +
                                        "/" +
                                        slide?.id +
                                        "/"
                                      }
                                    >
                                      <img
                                        src={slide.image_1}
                                        alt={`Slide ${index}`}
                                      />
                                    </Link>
                                    <div className="custom-overlay">
                                      <h4>{slide.title}</h4>
                                      <p>৳ {slide.price}</p>
                                    </div>
                                  </div>
                                ))}
                              </AutoplaySlider>
                            </div>
                          </div>
                        )} */}
                        {slidesData.length > 0 && (
                          <div>
                            <div className="slider-container">
                              <div className="slider-icon">
                                <img
                                  src={slidesData[0]?.promotion_logo}
                                  alt="Icon"
                                />
                              </div>

                              {/* {Object.keys(slidesData[0])
                                .filter((key) => key.startsWith('image_') && slidesData[0][key] !== null)
                                .length === 1 ? (
                                  <div className="carousel-div" style={{background: 'black'}}>
                                    <img style={{height:'350px', width: 'auto'}} alt={`Slide`} src={slidesData[0]['image_1']} />
                                    <div className="legend carousel-overlay" style={{ background: 'white', opacity: 1 }}>
                                      <h4 style={{ color: 'white' }}>{slidesData[0].title}</h4>
                                      <h5>৳ {slidesData[0].price}</h5>
                                    </div>
                                  </div>
                                ) : (
                                  <Carousel
                                    onClickItem={() =>
                                      handleCaroselImageClick(
                                        `/ad/details/${titlecConvertToSlug(slidesData[0]?.title)}/${slidesData[0]?.id}/`
                                      )
                                    }
                                    autoPlay
                                    showArrows={true}
                                    infiniteLoop
                                    showThumbs
                                    swipeable
                                    useKeyboardArrows
                                    showIndicators={false}
                                    showStatus={false}
                                    interval={3000}
                                    onClick={() => console.log('c2')}
                                  >
                                    {Object.keys(slidesData[0])
                                      .filter((key) => key.startsWith('image_') && slidesData[0][key] !== null)
                                      .map((key) => (
                                        <div key={key} className="carousel-div">
                                          <img alt={`Slide ${key}`} src={slidesData[0][key]} />
                                          <div className="legend carousel-overlay" style={{ background: 'white', opacity: 1 }}>
                                            <h4 style={{ color: 'white' }}>{slidesData[0].title}</h4>
                                            <h5>৳ {slidesData[0].price}</h5>
                                          </div>
                                        </div>
                                      ))}
                                  </Carousel>
                                )} */}

                              <Carousel
                                autoPlay
                                showArrows={true}
                                infiniteLoop
                                showThumbs
                                swipeable
                                useKeyboardArrows
                                showIndicators={false}
                                showStatus={false}
                                interval={3000}
                              >
                                {slidesData.map((slide, index) => {
                                  // Extracting the image name from the URL
                                  const imageName = slide.image_1
                                    .split("/")
                                    .pop();
                                  return (
                                    <a
                                      key={index}
                                      className="carousel-div"
                                      // href={`/ad/details/${convertTitleToSlug(slide?.title)}`}
                                      // href={`/ad/details/${slide?.slug}`}

                                      href={getAdDetailsUrlByBreadcrumbPath(
                                        slide
                                      )}
                                    >
                                      <img
                                        alt={slide.title + "_1"}
                                        src={slide.image_1}
                                      />
                                      <div
                                        className="legend carousel-overlay"
                                        style={{
                                          background: "white",
                                          opacity: 1,
                                        }}
                                      >
                                        <p
                                          className="h4"
                                          style={{ color: "white" }}
                                        >
                                          {slide.title}
                                        </p>
                                        <p className="h5">৳ {slide.price}</p>
                                      </div>
                                    </a>
                                  );
                                })}
                              </Carousel>
                            </div>
                          </div>
                        )}
                        {getPromotionAd &&
                          getPromotionAd?.map((allData, i) => (
                            <PromotionAd key={i} allData={allData} />
                          ))}
                      </div>
                      <div className="product_list">
                        {getAllData &&
                          getAllData?.map((allData, i) => (
                            <AllAd
                              key={i}
                              breadcrumb={getBreadcrumbs[0]?.title}
                              allData={allData}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 mt-15">
                  <div className="sidebar-ad banner__ad__right">
                    <BannerAdShowRight
                      widthSize={"240px"}
                      heightSize={"448.32px"}
                    />
                  </div>
                </div>

                <div className="col-lg-12 pt-40">
                  <div className="bottom-ad">
                    <BannerAdShowBottom
                      widthSize={"100%"}
                      heightSize={"auto"}
                    />
                  </div>
                </div>

                <div className="col-lg-12 pt-10">
                  <div className="pagination_wrapper mt-50">
                    <ul className="pagination justify-content-center">
                      <PaginateCustom
                        initialDispatch={false}
                        paginateInfo={paginateInfo}
                        onPaginateData={getPaginateData}
                        pageInfo={pageInfo}
                        onCountChange={handleCountChange}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {pageFooteraescriptionData.length > 20 ? (
            <FooterTop
              title={pageMetaTitleData}
              description={pageFooteraescriptionData}
            />
          ) : (
            <FooterCategoryForEm
              title={pageMetaTitleData}
              description={pageFooteraescriptionData}
            />
          )}
          <Footer mtValue={0} />
        </>
      )}
    </>
  );
};

export default AllAdPage;
