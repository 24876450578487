import React, { useRef, useEffect } from 'react';
import { capitalizeFirst, convertToSlug, titlecConvertToSlug } from "../../../helpers/Helpers"
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { getremoveSpace } from '../../../helpers/GenerateBreadcrumbPath'
const TvcCard = ({ allData }) => {
  // video show
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [allData?.video]);


  return (
    <>
      <div className="col-lg-4 col-md-6">
        <div className="single_blog mt-30">
          <div className="blog_image" style={{ display: "flex", justifyContent: "center" }}>
            <video controls ref={videoRef} title="Our video player"
              width="360" height="240" className="tvc__video"   >
              <source src={allData?.video} type="video/mp4" />
            </video>
          </div>
          <div className="blog_content">
            <p className="title"><Link rel="canonical" to={"/alltvcs/details/" + getremoveSpace(allData?.title?.toLowerCase()) + "/" + getremoveSpace(allData?.slug?.toLowerCase()) + '/'} className="tvc_count" rel1="8">{capitalizeFirst(allData?.title)}</Link>
            </p>
            <ul className="meta d-flex justify-content-between">
              <li><i className="fal fa-clock"></i> <Moment format="LL" >{allData?.created_at}</Moment>
              </li>
              <li>
                <p className="tvc-view-count"><span className="line"></span> <i className="fas fa-eye show"></i> {allData?.views}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TvcCard;