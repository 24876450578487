import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Link, useNavigate, useParams } from "react-router-dom";
import profile from '../../asset/frontend/assets/images/default.png'

import sslBanner from "../../asset/frontend/assets/images/ssl-banner2.jpg"
import { getPaymentAction, postPaymentAction } from "../../redux/action/userProfileAction/paymentAction/paymentAction";
import { languageCheck } from "../../helpers/Helpers";
import AddPromotion from "../../components/Ad/AddPromotion";
import { errorMessage, successMessage, warningMsg } from "../../Hooks/MessageHandling";
import axios from "axios";

import bkash from "../../asset/frontend/assets/images/StaticImage/payment/bkash-rounded.webp";
import nagad from "../../asset/frontend/assets/images/StaticImage/payment/nagad-rounded.webp";
import ssl from "../../asset/frontend/assets/images/StaticImage/payment/ssl-rounded.png";
import sslsquare from "../../asset/frontend/assets/images/StaticImage/payment/ssl-square.png";

const Payment = () => {

  const paymentId = useParams();
  const dispatch = useDispatch();
  const [newPrice, setNewPrice] = useState(null)
  const [selectedPayment, setSelectedPayment] = useState('bkash');
  const [adType, setAdType] = useState('normal_ad');


  let navigate = useNavigate();
  const { getPayments } = useSelector(state => state.getPaymentsReducer);
  // console.log(getPayments);
  const { loading } = useSelector(state => state.postPaymentsReducer);

  const getPrice = () => {
    let previous_due = 0;
    let total_to_pay = 0;
    if (!getPayments?.is_paid) {
      previous_due = getPayments?.total / 1.00;
      total_to_pay += previous_due;
    }

    if (newPrice?.price > 0) {
      total_to_pay += newPrice.price;
    }

    return total_to_pay;

  }


  useEffect(() => {
    const ad_selectedType = localStorage.getItem('payment_type');
    if (ad_selectedType && ad_selectedType == 'boost_ad') {
      setAdType('boost_ad');
    } else {
      setAdType('normal_ad');
    }
    dispatch(getPaymentAction(paymentId?.id));
    setNewPrice(null);

  }, [paymentId?.id])

  const handlePaymentRequest = (order_id = null) => {
    const formdata = new FormData();
    formdata.append("id", order_id ? order_id : getPayments?.id);
    formdata.append("payment_method", selectedPayment);
    localStorage.removeItem('payment_type');
    localStorage.setItem('order_id', order_id ? order_id : getPayments?.id);
    dispatch(postPaymentAction(formdata, navigate));
  };

  const handlePayment = async () => {
    try {
      const token = localStorage.getItem("token");

      console.log(newPrice?.price, adType)

      if ((adType && adType == 'boost_ad') || getPayments?.order_type == 'AD') {
        if (newPrice?.price && newPrice?.price > 0) {
          // console.log('apiPayload', newPrice);
          const response = await axios.post(
            'adpost/promote-ad/',
            {
              ad_id: getPayments?.ad.id,
              promotion_plan_id: newPrice.id,
            },
            { headers: { "Authorization": "token " + token } }
          );

          if (response.status === 200) {
            successMessage('Promotion request sent successfully!');
            handlePaymentRequest(response.data.order_id);
          }
        } else if (!newPrice?.price && adType && adType != 'boost_ad') {
          handlePaymentRequest();
        }
        else {
          errorMessage('Please select a promotion type!');
          return;
        }
      } else {
        handlePaymentRequest();
      }
    } catch (err) {
      handlePaymentError(err);
    }
  };

  const handlePaymentError = (err) => {
    console.log(err)
    if (err?.request?.status === 400 || err?.request?.status === 404 || err?.request?.status === 403) {
      warningMsg(err?.response?.data?.message);
    } else if (err?.request?.status === 500) {
      errorMessage('Something Went Wrong With Server');
    }
  };

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };




  const videoRef = useRef();
  useEffect(() => {
    videoRef.current?.load();
  }, [getPayments?.tvc?.video]);

  const handleShareData = (updatedPrice) => {
    if (updatedPrice?.id) {
      setNewPrice(updatedPrice);
    } else {
      setNewPrice(null);
    }
  };

  return (
    <>
      <Header />
      <section className="dashboard_page pt-70 pb-120 ">
        <div className="container">
          <div className="row mt-80 mb-5">
            <div className="col-md-10  m-auto shadow-none p-3  bg-white rounded">
              <div className="row">
                <div className="col-md-7">
                  <div className="paymemt__title">
                    <span>  {languageCheck() === 'bn' ? "আপনার প্রয়োগকৃত মূল্য" : "Your applied pricing"}</span>

                    {/* {languageCheck() === 'bn' ? 
                                <>
                                <p className=''>{getPayments?.order_type} Promotions প্রয়োগ করে 20 গুণ বেশি প্রতিক্রিয়া পান ৷ </p>  
                                <p className='mt-2 mb-2'>এক বা একাধিক বিকল্প নির্বাচন করুন (Optional)</p>
                                </> :
                                <>
                                <p className=''>Get up to 20 times more responses by applying {getPayments?.order_type} Promotions.</p>  
                                <p className='mt-2 mb-2'>Select one or more options (Optional).</p> 
                                </>} */}
                  </div>



                  <div className="row shadow-none  pt-3 pb-3 mb-5 mt-2 bg-light rounded" style={{ padding: "0", margin: '0' }}>
                    <div className="col-6  pt-1 pb-1">
                      <div className="card__header">
                      </div>

                      <div className="payment__text">
                        {!getPayments?.is_paid &&
                          <p className='payment__text__more' style={{ fontWeight: "800" }} >Pay {getPayments?.total ?? 0 / 1.00} Tk. ({getPayments?.pricing_for_days} Days)</p>
                        }
                        <p>{getPayments?.pricing_in_words_desc}</p>
                      </div>

                    </div>
                    {!getPayments?.is_paid &&
                      <div className="col-6 text-right  pt-1 pb-1">
                        {languageCheck() === 'bn' ?
                          <><span> টাকা থেকে {" "}{getPayments?.total ? (getPayments?.total / 1.00) : 0}</span> </> :
                          <><span>From Tk {" "}{getPayments?.total ? (getPayments?.total / 1.00) : 0}</span></>}
                      </div>
                    }
                  </div>

                </div>



                <div className="col-md-5">

                  <div className="ad_review">
                    <div className="check_icon">
                      <i className="fa fa fa-check"></i>
                    </div>

                    <p>
                      {languageCheck() === 'bn' ?
                        <>আপনার {getPayments?.order_type} পর্যালোচনা করা হচ্ছে!</> :
                        <>Your {getPayments?.order_type}  is under review!</>}</p>

                    {adType && (adType === 'boost_ad' || (getPayments && getPayments.order_type === 'AD')) ? (
                      <AddPromotion ad_id={getPayments?.ad?.id} shareDataCallback={handleShareData} />
                    ) : (
                      <></>
                    )}



                    {languageCheck() === 'bn' ?
                      <>
                        <span className='mt-2'> আপনার {getPayments?.order_type} প্রকাশের জন্য অর্থপ্রদানের প্রয়োজন হলে আমরা শীঘ্রই আপনার সাথে যোগাযোগ করব। </span>
                      </> :
                      <>
                        <span className='mt-2'> We will contact you shortly if payment is required to publish your {getPayments?.order_type}.</span>
                      </>}

                  </div>


                  {getPayments ? <>
                    {getPayments?.tvc === null ? "" :
                      <div className="ad__card_title d-flex shadow-sm p-2  mt-3 bg-white rounded">
                        <div className="card__image_payment">
                          <video width="150px" height="80px" className='mr-2' ref={videoRef} controls >
                            <source src={getPayments?.tvc?.video} type="video/mp4" />
                          </video>
                        </div>
                        <div className="card__title_payment mt-1">
                          <h5>{getPayments?.tvc?.title}</h5>
                          <p>{getPayments?.tvc?.company_name}</p>
                          <p style={{ fontSize: '14px' }}>{getPayments?.district},{getPayments?.address}</p>
                        </div>
                      </div>}

                    {getPayments?.banner === null ? "" : <div className="ad__card_title d-flex shadow-sm p-2  mt-3 bg-white rounded">
                      <div className="card__image_payment">
                        {getPayments?.banner?.image ? <img height="65px" className='mr-4'
                          width="90px" src={getPayments?.banner?.image} alt="profile" /> :
                          <img height="65px" className='mr-4'
                            width="90px" src={profile} alt="profile" />}
                      </div>
                      <div className="card__title_payment mt-1">
                        <h5>{getPayments?.banner?.title}</h5>
                        <p>{getPayments?.banner?.company_name}</p>
                        <p style={{ fontSize: '14px' }}>{getPayments?.district},{getPayments?.address}</p>
                      </div>
                    </div>}

                    {getPayments?.job === null ? "" : <div className="ad__card_title d-flex shadow-sm p-2  mt-3 bg-white rounded">
                      <div className="card__image_payment">
                        {getPayments?.job?.company_logo ? <img height="65px" className='mr-4'
                          width="90px" src={getPayments?.job?.company_logo} alt="profile" /> :
                          <img height="65px" className='mr-4'
                            width="90px" src={profile} alt="profile" />}
                      </div>
                      <div className="card__title_payment mt-1">
                        <h5>{getPayments?.job?.job_title}</h5>
                        <p>{getPayments?.job?.company_name}</p>
                        <p style={{ fontSize: '14px' }}>{getPayments?.district},{getPayments?.address}</p>
                      </div>
                    </div>}

                    {getPayments?.ad === null ? "" : <div className="ad__card_title d-flex shadow-sm p-2  mt-3 bg-white rounded">
                      <div className="card__image_payment">
                        {

                          getPayments?.ad?.image_1 ? <img height="65px" className='mr-4'
                            width="90px" src={getPayments?.ad?.image_1} alt="profile" /> :

                            getPayments.ad.resize_image ? <img height="65px" className='mr-4'
                              width="90xpx" src={getPayments?.ad?.resize_image[0].featuredImage} alt="profile" /> :
                              <img height="65px" className='mr-4'
                                width="90px" src={profile} alt="profile" />


                        }
                      </div>
                      <div className="card__title_payment mt-1">
                        <h5>{getPayments?.ad?.title}</h5>
                        <p style={{ fontSize: '14px' }}>{getPayments?.district},{getPayments?.ad?.address}</p>
                        <span>Tk {getPayments?.ad?.price ? (getPayments?.ad?.price / 1.00) : 0}</span>
                      </div>
                    </div>}
                  </> : ''}




                  <div className="payment__summary shadow-none p-3 mt-3 bg-light rounded">
                    <p>
                      {languageCheck() === 'bn' ? "পারিশ্রমিক সারসংক্ষেপ" : "Payment summary"}
                    </p>
                    {
                      !getPayments?.is_paid &&
                      <div className="urgent__payment d-flex">
                        <span>Pay {getPayments?.total ?? 0 / 1.00} Tk. ({getPayments?.pricing_for_days} Days)</span>
                        <span>{languageCheck() === 'bn' ? "টাকা" : "Tk"}{" "}{getPayments?.total ?? 0 / 1.00}</span>
                      </div>
                    }

                    {
                      newPrice?.price ?

                        <div className="urgent__payment d-flex">
                          <span>{newPrice?.title}</span>
                          <span>{languageCheck() === 'bn' ? "টাকা" : "Tk"}{" "}{newPrice?.price ?? 0 / 1.00}</span>
                        </div>
                        :
                        <></>
                    }

                    <hr />
                    <div className="total__payment d-flex">
                      <p>{languageCheck() === 'bn' ? "সর্বমোট পরিমাণ" : "Total amount"}</p>
                      <p>
                        {languageCheck() === 'bn' ? "টাকা" : "Tk"}{" "}
                        {getPrice()}
                      </p>
                    </div>

                  </div>

                  <div className="select__payment__method">
                    <h5 className='mt-3'>
                      {languageCheck() === 'bn' ? "একটি পেমেন্ট পদ্ধতি নির্বাচন করুন" : "Select a payment method"}
                    </h5>

                    {/* <div className="payment__select__radio mt-2 d-flex">
                                <input type="radio" checked={true} className='mr-2 mt-1'/>
                                 <div className="payment__online">
                                  <p>{getPayments?.payment_method}</p>
                                </div> 
                              </div>  */}

                    <div>


                      <div className="payment__select__radio mt-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="radio"
                          checked={selectedPayment === 'bkash'}
                          className='mr-2 mt-1'
                          value="bkash"
                          onChange={handlePaymentChange}
                        />
                        <div className="payment__online" style={{ display: 'flex', alignItems: 'center' }}>
                          <img width={40} src={bkash} /><h5 className="pl-3">Bkash</h5>
                        </div>
                      </div>
                      <div className="payment__select__radio mt-2 d-flex" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="radio"
                          checked={selectedPayment === 'nagad'}
                          className='mr-2 mt-1'
                          value="nagad"
                          onChange={handlePaymentChange}
                        />
                        <div className="payment__online" style={{ display: 'flex', alignItems: 'center' }}>
                          <img style={{ borderRadius: '100%' }} width={40} src={nagad} /><h5 className="pl-3">Nagad</h5>
                        </div>
                      </div>

                      <div className="payment__select__radio mt-2 d-flex" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="radio"
                          checked={selectedPayment === 'sslcommerz'}
                          className='mr-2 mt-1'
                          value="sslcommerz"
                          onChange={handlePaymentChange}

                        />
                        <div className="payment__online" style={{ display: 'flex', alignItems: 'center' }}>
                          <img style={{ borderRadius: '100%' }} width={40} src={sslsquare} /><h5 className="pl-3">SSLCommerz</h5>
                        </div>
                      </div>

                    </div>

                  </div>


                  {/* <button onClick={payment} className='main-btn mt-3 mb-5 w-100'>Continue</button> */}


                  <button
                    onClick={loading || getPrice() <= 0 ? '' : handlePayment}
                    disabled={loading || getPrice() <= 0}
                    type="button"
                    className={[
                      loading ? "loading__button mt-20 disabled w-100" : "main-btn mt-20 w-100",
                      getPrice() > 0 ? "" : "loading__button disabled"
                    ].join(" ")}
                    style={{ pointerEvents: loading || getPrice() <= 0 ? "not-allowed" : "auto" }}
                  >
                    {loading ? (
                      <>
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </>
                    ) : languageCheck() === "bn" ? (
                      "চালিয়ে যান"
                    ) : (
                      "Continue"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Payment;