import {
  getAdCategoryItems,
  getAdSubCategoryItems,
  getDistrictItems,
  getDivisionItems,
} from "../Utilities/Utilities";

import {
  getDistrictRequest,
  getDivisionRequest,
} from "../redux/action/coreAction";
import {
  getAdCategoryRequest,
  getAdSubCategoryRequest,
} from "../redux/action/userProfileAction/profileAdAction";

export const getAdDetailsUrlByBreadcrumbPath = (item) => {
  const divs = getDivisionItems();

  if (divs && divs.lenght === 0) {
    //getDivisionRequest()
    const path = `/allads/division/district/category/subcategory/${item.sulg}`;
    return path;
  }
  const dis = getDivisionItems();

  if (dis && dis.lenght === 0) {
    const path = `/allads/division/district/category/subcategory/${item.sulg}`;
    return path;
  }

  const cat = getAdCategoryItems();

  if (cat && cat.lenght === 0) {
    const path = `/allads/division/district/category/subcategory/${item.sulg}`;
    return path;
  }
  const scat = getAdSubCategoryItems();

  if (scat && scat.lenght === 0) {
    const path = `/allads/division/district/category/subcategory/${item.sulg}`;
    return path;
  }

  const division =
    item.division !== undefined
      ? getDivisionItems()?.find((x) => x.id === item.division)
      : undefined;
  const district =
    item.district !== undefined
      ? getDistrictItems()?.find((x) => x.id === item.district)
      : undefined;
  const category =
    item.ad_category !== undefined
      ? getAdCategoryItems()?.find((x) => x.id === item.ad_category)
      : undefined;
  const sub_category =
    item.ad_sub_category !== undefined
      ? getAdSubCategoryItems()?.find((x) => x.id === item.ad_sub_category)
      : undefined;

  const slug = item.slug;

  const divisionName =
    division !== undefined ? division.name.toLowerCase() : "division";
  const districtName =
    district !== undefined ? district.name.toLowerCase() : "district";
  const categoryName =
    category !== undefined ? category.name.toLowerCase() : "category";
  const subCategoryName =
    sub_category !== undefined
      ? sub_category.name.toLowerCase()
      : "subcategory";

  const path = `/allads/${getremoveSpace(divisionName)}/${getremoveSpace(
    districtName
  )}/${getremoveSpace(categoryName)}/${getremoveSpace(
    subCategoryName
  )}/${slug}`;

  // const path = `/allads/${getremoveSpace(division !== undefined ? division.name.toLowerCase(): "division") }/${getremoveSpace(district !== undefined ? district.name.toLowerCase() : "district") }/${
  //   getremoveSpace(category !== undefined ? category.name.toLowerCase() : "category")
  // }/${getremoveSpace(sub_category !== undefined ? sub_category.name.toLowerCase() : "subcategory" )}/${sulg}`;

  return decodeURIComponent(path);
};

export const getremoveSpace = (value) => {
  if (value === undefined) {
    return value
  }
  let stringWithoutSpacesAndAmpersand = value
    .replace(/ {2}/g, "-")
    .replace(/ /g, "-");
  //let removeDobuleChar = stringWithoutSpacesAndAmpersand.split('/--/');
  //let removeSpecialChar = stringWithoutSpacesAndAmpersand.toLowerCase();
  let removeSpecialChar = stringWithoutSpacesAndAmpersand
    .split(/[ &,]/)
    .join("");
  return removeSpecialChar.replace(/--/g, "-");
};

