import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { languageCheck, titlecConvertToSlug } from "../../../helpers/Helpers";
import axios from "axios";

import {

  categoryAndSubCategoryWiseAdCount,

} from "../../../Utilities/Utilities";

import { getremoveSpace } from '../../../helpers/GenerateBreadcrumbPath';

const AdsCategoryCard = ({ allData, allAdCountCategory }) => {

  const [allAds, setAllAds] = useState();

  const adCategoryCount =
    allAdCountCategory &&
    allAdCountCategory?.filter((allAd) => allAd?.ad_category === allData?.id);

  useEffect(() => {
    allAdApi();
  }, []);


  const allAdApi = async () => {
    try {
      const response = (await axios.get("adpost/?limit=500000"))?.data?.results;
      setAllAds(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="category-column">
        <div className="single_category text-center mt-30">
          <Link rel="canonical"
            state={{ type: "ad_category", id: allData?.id }}
            to={{
              pathname:
                "/allads/divisions/districts/" +
                getremoveSpace(titlecConvertToSlug(allData?.name).toLowerCase()) +
                "/sub-categories",
            }}
          >
            <div className="icon">
              <img
                src={allData?.image}
                alt={allData?.title}
                style={{ width: "50px", height: "50px" }}
              />
            </div>
            <div className="content">
              <p className="title" style={{ fontSize: "17px" }}>
                {allData?.name}
              </p>
              <p>
                {languageCheck() === "bn" ? "মোট বিজ্ঞাপন:" : "Total Ads: "}{" "}
                {/* {adCategoryCount?.length} */}
                {categoryAndSubCategoryWiseAdCount(
                  allAds,
                  allData?.id,
                  "category-ads-count"
                )}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AdsCategoryCard;
