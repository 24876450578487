import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { languageCheck, titlecConvertToSlug } from "../../../helpers/Helpers";
import axios from "axios";
import { getremoveSpace } from '../../../helpers/GenerateBreadcrumbPath'
const DistrictCard = ({ allData, allAdCountCategory, division, division_id }) => {
  const adCategoryCountLocation =
    allAdCountCategory &&
    allAdCountCategory?.filter((allAd) => allAd?.district === allData?.id);
  const [count, setCount] = useState(0);

  useEffect(() => {
    districtItems();

    return () => { };
  }, []);

  const districtItems = async () => {
    try {
      const response = (
        await axios.get(
          `https://publicmarket.com.bd/v2/en/api/adpost/?division=${division_id}&district=${allData.id}&limit=2`
        )
      ).data;
      console.log("district count", response);
      setCount(response.count);
    } catch (error) {
      console.log("API ERROR");
    }
  };

  return (
    <>
      <div className="district-column">
        <div className="single_district text-center mt-30">
          <div className="icon">
            <img src={allData?.image} alt="Locations" />{" "}
          </div>
          <div className="content">
            <h4 className="district-title">{allData?.name}</h4>
            <h6 className="district-p">
              {languageCheck() === "bn" ? "মোট বিজ্ঞাপন:" : "Total Ads:"}
              {count}
            </h6>
          </div>
          <Link rel="canonical"
            className="view"
            state={{
              type: "district",
              id: allData?.id,
              optionalId: allData?.division,
            }}
            to={
              "/allads/" +
              getremoveSpace(division.toLowerCase()) +
              "/" +
              getremoveSpace(allData?.name.toLowerCase()) +
              "/categories/sub-categories"
            }
          ></Link>
        </div>
      </div>
    </>
  );
};

export default DistrictCard;
