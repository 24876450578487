// export const getPaymentsReducer =(state={ },action)=>{
       
//     switch(action.type){
//       case 'GET_PAYMENT_REQUEST' : return {
//          loading : true,
//          ...state 
//        }
//       case 'GET_PAYMENT_SUCCESS' : return { 
//           loading : false,
//           getPayments : action.payload
//       } 
//       case 'GET_PAYMENT_ERROR' : return {
//           loading : false,
//           error : action.payload
//       }
//       default : return state
//     } 
// } 

// export const postPaymentsReducer =(state={ },action)=>{
       
//   switch(action.type){
//     case 'POST_PAYMENT_REQUEST' : return {
//        loading : true, 
//      }
//     case 'POST_PAYMENT_SUCCESS' : return { 
//         loading : false,
//         success: true
//     } 
//     case 'POST_PAYMENT_ERROR' : return {
//         loading : false,
//         error : action.payload
//     }
//     default : return state
//   }

// } 

export const getPaymentsReducer = (state = { loading: false, getPayments: null, error: null }, action) => {
  switch (action.type) {
    case 'GET_PAYMENT_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'GET_PAYMENT_SUCCESS':
      return {
        ...state,
        loading: false,
        getPayments: action.payload,
        error: null,
      };
    case 'GET_PAYMENT_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const postPaymentsReducer = (state = { loading: false, success: false, error: null }, action) => {
  switch (action.type) {
    case 'POST_PAYMENT_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'POST_PAYMENT_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
    case 'POST_PAYMENT_ERROR':
      return {
        ...state,
        loading: false,
        success: false, // Reset success to false on error
        error: action.payload,
      };
    default:
      return state;
  }
};