import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import { getAdDetailsUrlByBreadcrumbPath, getremoveSpace } from '../../../helpers/GenerateBreadcrumbPath'

import pmImage from "../../../asset/frontend/assets/images/logo/small-logo.png"
import { capitalizeFirst, convertTitleToSlug, languageCheck, titlecConvertToSlug } from '../../../helpers/Helpers';

const AdsNewCard = ({ allData }) => {


    return (
        <>
            <div className="col-lg-3 col-md-4 col-sm-6 ad__banner__card" style={{ display: "flex", justifyContent: "center" }} >
                <Link rel="canonical" to={
                    //"/ad/details/"+allData?.slug
                    getAdDetailsUrlByBreadcrumbPath(allData)
                }
                    state={{ type: 'ad_category', id: allData?.ad_category }}
                >
                    <div className="single_ads_card mt-30" >
                        <div className="ad_card_thambail ads_card_image d-flex justify-content-center ">
                            {allData.image_1 ? <img src={allData.image_1} className="ads_card_image_thambail contain_img" alt={allData.title} /> : <img src={allData.resize_image[0].thumbnail} className="ads_card_image_thambail contain_img" alt={allData.title} />}
                            <span style={{
                                position: 'absolute',
                                height: '2rem',
                                width: '5.5rem',
                                top: '15px',
                                left: '15px',
                                borderRadius: '7px'
                            }}>
                                <img className="public-market-tag img-fluid" width="50px" height="50px" src={pmImage} alt={allData.title} /></span>
                        </div>

                        <div className="ads_card_content">
                            <div className="meta d-flex justify-content-between">
                                <p> {capitalizeFirst(allData.title)}</p>
                                {/* <i className="fal fa-heart"></i>  */}
                            </div>
                            {/* <h4 className="title"><a href="#">{allData?.uncommon_fields_json?.brand_name}</a> */}
                            {/* </h4> */}
                            <p><i className="far fa-map-marker-alt"></i>{allData.address.slice(0, 12)},
                                {languageCheck() === 'bn' ? "বাংলাদেশ" : "Bangladesh"} </p>
                            {/* <p><i className="fas fa-eye"></i> 124 </p> */}
                            <p>
                                <i className="fas fa-calendar"></i><Moment format="LL" >{allData?.created_at}</Moment>
                            </p>
                            <div className="ads_price_date d-flex justify-content-between">
                                <span className="price">{allData.price}৳</span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};

export default AdsNewCard;