import { languageCheck } from "../helpers/Helpers";

const version = "v2";

const envMap = {
  production: process.env.REACT_APP_PRO_MODE,
  development: process.env.REACT_APP_DEV_MODE,
};
// console.log(window.location.hostname)
const lang = languageCheck() || "en";

export const baseUrl = (env) => {
  console.log("v0.1.19|Promoted Slider");
  const host = "https://publicmarket.com.bd/v2/";
  const url = `${host}/${lang}/api/`;
  return url;
};
// export const baseUrl = (env) => {
//   console.log("v0.1.19|Promoted Slider");
//   const host = envMap[env];
//   const url = `${host}/${version}/${lang}/api/`;
//   return url;
// };
